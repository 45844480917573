import React ,{Fragment,useEffect,useContext}from 'react';
import {useLocation,useHistory} from "react-router-dom";
import {Link} from "react-router-dom";
import './NotificationCenter.scss';
import Grid from '@material-ui/core/Grid';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import balance_icon from './balance_icon.svg';
import clc_points_icon from './clc_points_icon.svg';
import cpv_points_icon from "./cpv_points_icon.svg";
import network_icon from "./network_icon.svg";
import {DashboardCard,DashboardCardCircle,DashboardCardLogo,DashboardCardText} from "../StyledComponents/styledcomponents";


    
const NotificationCenter = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User} = Appstate
  const {CurrencyFormat} = Functions
   const {themecolor1,themecolor2,AccountType} = Appsettings

  //const classes = useStyles();


  const History = useHistory()

const [DashboardInfor, setDashboardInfor] = React.useState({
  title:'',
  description:'',
  icon:'',
});



useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  





return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])












return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="transaction-wrapper">


    
<Grid container spacing={1}>

<Grid item xs={12} sm={3}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={balance_icon} alt='account balance' className='dashboard-image'/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>PAYMENT REQUEST</b></li>
        <li style={{color:'#fff',display:'block'}}><span> 0</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{History.push({pathname:`/control/${AccountType}/notifications/payments`})}}>VIEW</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={3}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={cpv_points_icon} alt='cpv account' className='dashboard-image'/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>LOAN REQUEST</b></li>
        <li style={{color:'#fff',display:'block'}}><span>0 </span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary'>VIEW</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={3}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={clc_points_icon} alt='clc account' className='dashboard-image'/> </DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>MAIL BOX</b></li>
        <li style={{color:'#fff',display:'block'}}><span>0</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary'>VIEW</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>



<Grid item xs={12} sm={3}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={clc_points_icon} alt='clc account' className='dashboard-image'/> </DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>USER REGISTRATION</b></li>
        <li style={{color:'#fff',display:'block'}}><span>0</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary'>VIEW</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>









</Grid>

</div>
</Fragment>
 
    
);


// NotificationCenter.propTypes = {}
        
// NotificationCenter.defaultProps ={}


}
export {NotificationCenter};

