import React ,{Fragment,useEffect,useContext}from 'react';
import {useLocation,useHistory,Link} from "react-router-dom";
import './SearchUserField.scss';
import Grid from '@material-ui/core/Grid';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import {UserInstanceCard} from "../../Subcomponents/UserInstanceCard";
import {Search,Cancel} from '@material-ui/icons';
import {ServerFirstIndexedb_SearchUser} from "../../HttpcallUserApp";

    
const SearchUserField = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User,App} = Appstate
  const {CurrencyFormat} = Functions
  const {themecolor1,themecolor2,AccountType} = Appsettings
  
  const {server_ip,authentication_token} = App

  const History = useHistory()

  const [UserSearchResult, setUserSearchResult] = React.useState([
    
    //   {
    //   current_grade_points:3,
    //   first_name: "hunderson",
    //   id: "A16398-FMFP",
    //   last_name: "michael",
    //   middle_name: "challes",
    //   profile: "profile_avatar.png",
    //   rank: "distributor",
    //   total_community: 12
    //  }
    


  ]);

  const [ServerResponse, setServerResponse] = React.useState(null);

  const [DisplayResult, setDisplayResult] = React.useState(null);

  const [SearchQueery, setSearchQueery] = React.useState('');



useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  





return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[UserSearchResult])





const OnchangeSearchQuery =(event)=>{

const {value} = event.target
setSearchQueery(value)


}



const SearchFunction =()=>{
if(SearchQueery.length > 2){
  setDisplayResult(true)
  setServerResponse(null)
  setUserSearchResult([])
  ServerFirstIndexedb_SearchUser(server_ip,SearchQueery,authentication_token).then(function(JsonResponse){
  const {status} = JsonResponse

    setServerResponse(status)

    if(status===200){
     const {Jsondata} = JsonResponse

     setUserSearchResult(Jsondata)
          
   
    } 
   
   })
}  

}


const NavigateProfilePreview = (GenerationID)=>{

  setDisplayResult(false)

  History.push({pathname:`/control/${AccountType}/users/profile`,state:{GenerationID:GenerationID}})


  
}
  



  const SearchResulSkeletonMap = [0,1,2,3,4,5].map(function(object,index){
    return(
       <React.Fragment key={index}>
           <div className='generation-card'>
            <Grid container spacing={1}>
  
            <Grid item xs={12} sm={3} className='generation-wrapper'>
                <div className='generation-skeleton-circle'>
                <Skeleton variant='circle' animation='pulse' style={{width:80,height:80}} /> 
                </div>
             </Grid>
  
            <Grid item xs={12} sm={3} className='generation-wrapper'>
            <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
            </Grid>
  
            <Grid item xs={12} sm={3} className='generation-wrapper'>
            <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
            </Grid>
   
            <Grid item xs={12} sm={3} className='generation-wrapper'>
            <Skeleton variant='text' animation="wave" style={{width:"10%",height:30}} /> 
            </Grid>
           </Grid>
          </div>
          <br />
       </React.Fragment>
       )
  })  
  
  




const SearchResultMap = UserSearchResult.map(function(object,index){
  return(
      <React.Fragment key={index}>
           <Grid container spacing={1}>
           <Grid item xs={12}>
            
            <UserInstanceCard profile={object.profile} user_id={object.id} first_name={object.first_name} middle_name={object.middle_name} last_name={object.last_name}
              total_community={object.total_community} themecolor1={themecolor1}
 
              NavigateProfilePreview = {NavigateProfilePreview} current_grade_points={object.current_grade_points}
            />
              
              </Grid>
         </Grid>
         <br />
         
      </React.Fragment>
      )
 })  
 


  

return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="search-user-field">


    

<div className="info-box">
<Grid container spacing={1}>

<Grid item xs={12}>
  <div className='search-field-input'>
  <div className="input-group input-group-md">
  <input onChange={OnchangeSearchQuery} type="text" className="form-control" placeholder='input user ID or names' />
  <span className="input-group-append">
    <Button onClick={SearchFunction} variant='contained' startIcon={<Search />} className="btn btn-info btn-flat"></Button>
  </span>
</div>
</div>
</Grid>


{DisplayResult===true?
<Grid item xs={12}>
  <div className='search-result-close-wrapper'><Link to="#" onClick={()=>{setDisplayResult(false)}}> <Cancel style={{fontSize:25,color:'gray'}} /> </Link></div>
<div className='search-result-displayer'>

    {SearchResultMap}

    {UserSearchResult.length===0 && ServerResponse===null?SearchResulSkeletonMap:null}

     {UserSearchResult.length===0 && ServerResponse===200?

       <div className='user-not-found'><h5>USER NOT FOUND ! </h5></div>
     :null}


 </div>
 <div className='search-result-close-wrapper'><Link to="#" onClick={()=>{setDisplayResult(false)}}><Cancel style={{fontSize:25,color:'gray'}} /></Link></div>
</Grid>
:null}






</Grid>
</div>



</div>
</Fragment>
 
    
);


// SearchUserField.propTypes = {}
        
// SearchUserField.defaultProps ={}


}
export {SearchUserField};

