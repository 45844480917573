import React ,{Fragment,useEffect}from 'react';
import {Route,Switch,useParams} from 'react-router-dom';
import {Dashboard} from './components/Dashboard/Dashboard';
import {Generations} from "./components/Generations/Generations";
import {Transaction} from "./components/Myaccount/Transaction";
import {LoanApplication} from "./components/Myaccount/LoanApplication";
import {InboxNotification} from "./components/InboxNotification/InboxNotification";
import {InboxMessageView} from "./components/InboxMessageView/InboxMessageView";
import {Profile} from './components/Profile/Profile';
import {UserProfilePreview} from "./components/UserProfileView/UserProfilePreview";
import {NotificationCenter} from "./components/NotificationCenter/NotificationCenter";
import {PaymentRequest} from "./components/PaymentRequest/PaymentRequest";
import {VoucherGenerate} from "./components/VoucherGenerate/VoucherGenerate";
import {SettingCenter} from "./components/SettingCenter/SettingCenter";
import {AddSupperUserAccount} from "./components/AddSupperUserAccount/AddSupperUserAccount";
import {AddProductSells} from "./components/AddProductSells/AddProductSells";
import {AddProductStock} from "./components/AddProductStock/AddProductStock";

const  NavigationUseraccount = (props) => {





// const {AccountType} = UrlParams

const UrlParams = useParams()
var AccountType = UrlParams.AccountType==='admin' || UrlParams.AccountType==='accountant' || UrlParams.AccountType==='storekeeper'?UrlParams.AccountType:null

useEffect(()=>{//COMPONENT DID MOUNT






    

 
    


},[])














return (  //=----RETURN MAIN HTML NAVIGATION
<Fragment>
<Switch>

<Route exact={true}  path={`/control/${AccountType}`} render={(props)=>(<Dashboard />)} /> 

<Route exact={true} path={`/control/${AccountType}/dashboard`} render={(props)=>(<Dashboard />)} /> 

<Route exact={true} path={`/control/${AccountType}/users`} render={(props)=>(<Generations />)} /> 
<Route exact={true} path={`/control/${AccountType}/users/profile`} render={(props)=>(<UserProfilePreview />)} /> 




<Route exact={true} path={`/control/${AccountType}/notifications`} render={(props)=>(<NotificationCenter />)} /> 
<Route exact={true} path={`/control/${AccountType}/notifications/payments`} render={(props)=>(<PaymentRequest />)} />
 



<Route exact={true} path={`/control/${AccountType}/settings`} render={(props)=>(<SettingCenter />)} /> 
<Route exact={true} path={`/control/${AccountType}/settings/profile`} render={(props)=>(<Profile />)} /> 
<Route exact={true} path={`/control/${AccountType}/settings/vouchers`} render={(props)=>(<VoucherGenerate />)} /> 
<Route exact={true} path={`/control/${AccountType}/settings/add_supperuser_account`} render={(props)=>(<AddSupperUserAccount />)} /> 
<Route exact={true} path={`/control/${AccountType}/settings/add_product_stock`} render={(props)=>(<AddProductStock />)} /> 
<Route exact={true} path={`/control/${AccountType}/settings/add_product_sells`} render={(props)=>(<AddProductSells />)} /> 







<Route exact={true} path='/account/transaction' render={(props)=>(<Transaction />)} /> 

<Route exact={true} path='/account/loanapplication' render={(props)=>(<LoanApplication />)} /> 

<Route exact={true} path='/account/inbox' render={(props)=>(<InboxNotification />)} /> 

<Route exact={true} path='/account/inbox/:InboxType' render={(props)=>(<InboxNotification />)} /> 

<Route exact={true} path='/account/inbox/reads/:MessageType' render={(props)=>(<InboxMessageView />)} /> 





<Route render={(props)=>(<h1>PAGE NOT FOUND !!</h1> )}  /> 

</Switch>

</Fragment>
);
}
export {NavigationUseraccount};

