import React ,{Fragment,useEffect,useContext}from 'react';
import {Link} from "react-router-dom";
import './Profile.scss';
//import { makeStyles } from '@material-ui/core/styles';
import {UseraccountContextApi} from "../../UseraccountContextApi";
//import Box from '@material-ui/core/Box';
import {Grid,Divider,Typography,Button,ButtonGroup} from '@material-ui/core';   
import {TanzaniaRegionsList,TanzaniaDistrictList} from "../../../../TanzaniaRegionList";
//import {LoadingSpinner} from "../../Subcomponents/Subcomponents"
import {AddAPhoto,CheckCircle,CancelPresentation,MoreVert} from '@material-ui/icons';
import {ServerFirstIndexedb_FetchUserProfile,HttpServer_UpdateProfileFormData,HttpServer_UpdateProfilePhoto} from "../../HttpcallUserApp";
import TextField from '@material-ui/core/TextField';
import { UserRegistrationForm } from '../UserRegistrationForm/UserRegistrationForm';
import {ImageSizeValidator} from "../../Subcomponents/Subcomponents";
import {ClipLoader,ScaleLoader} from "react-spinners";
import $ from 'jquery';
import Skeleton from '@material-ui/lab/Skeleton';
import {NotificationActivate} from "../NotificationApplication/NotificationApplication";


// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
// }));
  


    
const Profile = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User,App} = Appstate

   const {ProfilePhotoUpdateFuction,RankBadgeIdentifier,UpdateProfileCompletedStatus} = Functions
   
   const {themecolor1,themecolor2} = Appsettings

   const {server_ip,authentication_token} = App

   const {generation_id,profile_photo,rank,profile_completed} = User

   //const classes = useStyles();


const [UserProfileDataForm, setUserProfileDataForm] = React.useState({
  bank_account_fullname: "jACKLINE PUTRI",
  bank_name: "MBBC",
  bank_number: "0397895749574",
  coverphoto: "",
  district_name: "",
  first_name: "WILLIAMJS",
  inheritance_bank_account_fullname: "",
  inheritance_bank_number: "0395839574957",
  inheritance_bank_name: "MBSDG",
  inheritance_first_name: "JACKLINSS",
  inheritance_last_name: "HUSSAIN",
  inheritance_middle_name: "JJPOST",
  inheritance_phone_number: "03857395",
  inheritance_relationship: "HHDKHD",
  last_name: "JESKAS",
  middle_name: "PETTER",
  phone_number1: "00684967849674",
  phone_number2: "8405847594030",
  primary_email: "williamjs@gmail.com",
  profile_photo: "",
  region_name: "",
  upliner_profile:{region_name:"",district_name:"",phone_number1:"",first_name:"",last_name: "",middle_name: "",profile_photo: "",rank: ""},
  ward_name: "town"
 }
  );  










const [FormValidationError, setFormValidationError] = React.useState({
    FirstNameError:false,
    LastNameError:false,
    MiddleNameError:false,
    PhoneNumber1Error:false,
    PhoneNumber2Error:false,
    RegionNameError:false,
    DistrictNameError:false,
    WardNameError:false,
    PrimaryEmailError:false,
    BankNameError:false,
    BankAccountNameError:false,
    BankAccountFullNameError:false,
    BankAccountNumberError:false,
    InheritanceFirstnameError:false,
    InheritanceLastNameError:false,
    InheritanceMiddlenameError:false,
    InheritanceBanknameError:false,
    InheritanceBanckAccountFullnameError:false,
    InheritanceBankNumberError:false,
    InheritanceRelationshipError:false,
    InheritancePhoneNumberError:false,
    }
);

const [ProfileUpdateLoading, setProfileUpdateLoading] = React.useState(false);

const [ProfilePhotoUpdateLoading, setProfilePhotoUpdateLoading] = React.useState(false);



const [ServerResponse, setServerResponse] = React.useState(null);
 
// const [ProfileSavedLoading, setProfileSavedLoading] = React.useState(false);




useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  


ServerFirstIndexedb_FetchUserProfile(server_ip,authentication_token).then(function(JsonResponse){
   const {status} = JsonResponse

   setServerResponse(status)

   console.log(JsonResponse)

  if(status===200){
     
    const {Jsondata} = JsonResponse

    setUserProfileDataForm(Jsondata)


  }


}).catch(function(ErrorResponse){

  setServerResponse(ErrorResponse)
})



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])



const OnchangeFirstName =(event)=>{

  const {value} = event.target
  setUserProfileDataForm({...UserProfileDataForm,first_name:value})

  if(value.length > 2){

    setFormValidationError({...FormValidationError,FirstNameError:false})

  }
}


const OnchangeLastName =(event)=>{

const {value} = event.target  
setUserProfileDataForm({...UserProfileDataForm,last_name:value})

if(value.length > 2){

setFormValidationError({...FormValidationError,LastNameError:false})

}
}


const OnchangeMiddleName =(event)=>{

  const {value} = event.target
setUserProfileDataForm({...UserProfileDataForm,middle_name:value})

if(value.length > 2){

setFormValidationError({...FormValidationError,MiddleNameError:false})

}
}

const OnchangePhoneNumber1 =(event)=>{

  const {value} = event.target
  setUserProfileDataForm({...UserProfileDataForm,phone_number1:value})

  if(value.length >= 10){
    setFormValidationError({...FormValidationError,PhoneNumber1Error:false})

  }
}

const OnchangePhoneNumber2 =(event)=>{

  const {value} = event.target
  setUserProfileDataForm({...UserProfileDataForm,phone_number2:value})

}



const OnchangeRegionName =(event)=>{

const {value} = event.target
setUserProfileDataForm({...UserProfileDataForm,region_name:value})

if(value !=="0"){
  setFormValidationError({...FormValidationError,RegionNameError:false})

}
}

const OnchangeDistrictName =(event)=>{

  const {value} = event.target
 setUserProfileDataForm({...UserProfileDataForm,district_name:value})

 if(value !=="0"){

  setFormValidationError({...FormValidationError,DistrictNameError:false})

}
}

const OnchangeWardName =(event)=>{

  const {value} = event.target
setUserProfileDataForm({...UserProfileDataForm,ward_name:value})

if(value.length >2){
  setFormValidationError({...FormValidationError,WardNameError:false})

}
}


const OnchangePrimaryEmail =(event)=>{

  const {value} = event.target
  setUserProfileDataForm({...UserProfileDataForm,primary_email:value})

if(value.length > 5){
  
  setFormValidationError({...FormValidationError,PrimaryEmailError:false})

}


} 





const OnchangeInheritanceFirstName =(event)=>{

const {value} = event.target

setUserProfileDataForm({...UserProfileDataForm,inheritance_first_name:value})

if(value.length > 3){

  setFormValidationError({...FormValidationError,InheritanceFirstnameError:false})

}
}


const OnchangeInheritanceMiddleName =(event)=>{

const {value} = event.target

setUserProfileDataForm({...UserProfileDataForm,inheritance_middle_name:value})

if(value.length > 3){

  setFormValidationError({...FormValidationError,InheritanceMiddlenameError:false})

}
} 

const OnchangeInheritanceLastName =(event)=>{

  const {value} = event.target
  
  setUserProfileDataForm({...UserProfileDataForm,inheritance_last_name:value})
  
  if(value.length > 3){
  
    setFormValidationError({...FormValidationError,InheritanceLastNameError:false})
  
  }
} 


const OnchangeInheritanceRelationShip =(event)=>{

  const {value} = event.target
  
  setUserProfileDataForm({...UserProfileDataForm,inheritance_relationship:value})
  
  if(value.length > 3){
  
    setFormValidationError({...FormValidationError,InheritanceRelationshipError:false})
  
  }
} 


const OnchangeBankName =(event)=>{

  const {value} = event.target
  
  setUserProfileDataForm({...UserProfileDataForm,bank_name:value})
  
  if(value.length > 2){
  
    setFormValidationError({...FormValidationError,BankNameError:false})
  
  }
}

const OnchangeBankNumber =(event)=>{

  const {value} = event.target
  
  setUserProfileDataForm({...UserProfileDataForm,bank_number:value})
  
  if(value.length > 10){
  
    setFormValidationError({...FormValidationError,BankAccountNumberError:false})
  
  }
}

const OnchangeBankAccountFullName =(event)=>{

  const {value} = event.target
  
  setUserProfileDataForm({...UserProfileDataForm,bank_account_fullname:value})
  
  if(value.length > 10){
  
    setFormValidationError({...FormValidationError,BankAccountFullNameError:false})
  
  }
}






const OnchangeInheritanceBankName =(event)=>{

  const {value} = event.target
  
  setUserProfileDataForm({...UserProfileDataForm,inheritance_bank_name:value})
  
  if(value.length > 2){
  
    setFormValidationError({...FormValidationError,InheritanceBanknameError:false})
  
  }
} 


const OnchangeInheritanceBankAccountFullName =(event)=>{

  const {value} = event.target

  console.log(value)
  


  setUserProfileDataForm({...UserProfileDataForm,inheritance_bank_account_fullname:value})
  
  if(value.length > 3){
  
    setFormValidationError({...FormValidationError,InheritanceBanckAccountFullnameError:false})
  
  }
} 

const OnchangeInheritanceBankNumber =(event)=>{

  const {value} = event.target
  
  setUserProfileDataForm({...UserProfileDataForm,inheritance_bank_number:value})

  if(value.length >= 10){
  
    setFormValidationError({...FormValidationError,InheritanceBankNumberError:false})
  
  }
} 

const OnchangeInheritancePhoneNumber =(event)=>{

  const {value} = event.target
  
  setUserProfileDataForm({...UserProfileDataForm,inheritance_phone_number:value})
  
  if(value.length >=10){
  
    setFormValidationError({...FormValidationError,InheritancePhoneNumberError:false})
  
  }
} 






const FormValidation = () =>{
  return new Promise(function(resolve,reject){

   if(UserProfileDataForm.primary_email.length < 5){
   
    setFormValidationError({...FormValidationError,PrimaryEmailError:true})
    return reject('primary email invalid')

   }else if(UserProfileDataForm.first_name.length < 4){
   
    setFormValidationError({...FormValidationError,FirstNameError:true})

    return reject('invalid first name')

   }else if(UserProfileDataForm.middle_name.length < 5){
   
    setFormValidationError({...FormValidationError,MiddleNameError:true})

    return reject('input middle name')

   }else if(UserProfileDataForm.last_name.length < 5){
   
    setFormValidationError({...FormValidationError,LastNameError:true})

    return reject('input middle name')

   }
   else if(UserProfileDataForm.primary_email.length < 5){
   
    setFormValidationError({...FormValidationError,PrimaryEmailError:true})
    return reject('primary email invalid')

   }
   else if(UserProfileDataForm.phone_number1 < 9){

    setFormValidationError({...FormValidationError,PhoneNumber1Error:true})

    return reject('phone number 1 required')

   }else if(UserProfileDataForm.bank_name.length < 2){

    setFormValidationError({...FormValidationError,BankNameError:true})
    return reject('please input bank name')

   }
   else if(UserProfileDataForm.bank_account_fullname.length < 5){

    setFormValidationError({...FormValidationError,BankAccountFullNameError:true})
    return reject('please input valid full name')

   }else if(UserProfileDataForm.bank_number.length < 10){

    setFormValidationError({...FormValidationError,BankAccountNumberError:true})
   }
   else if(UserProfileDataForm.region_name==='0' || UserProfileDataForm.region_name.length===0){

    setFormValidationError({...FormValidationError,RegionNameError:true})
    return reject('select region')

   }else if(UserProfileDataForm.district_name ==='0' || UserProfileDataForm.district_name.length===0){

    setFormValidationError({...FormValidationError,DistrictNameError:true})
    return reject('select district name')

   }else if(UserProfileDataForm.ward_name.length < 2){

    setFormValidationError({...FormValidationError,WardNameError:true})

    return reject('Input ward name')

   }else if(UserProfileDataForm.inheritance_first_name.length < 2){

    setFormValidationError({...FormValidationError,InheritanceFirstnameError:true})

    return reject('Input inheritance first name')

   }else if(UserProfileDataForm.inheritance_middle_name.length < 2){

    setFormValidationError({...FormValidationError,InheritanceMiddlenameError:true})

    return reject('Input inheritance middle name')

   }
   else if(UserProfileDataForm.inheritance_last_name.length < 2){

    setFormValidationError({...FormValidationError,InheritanceLastNameError:true})

    return reject('Input inheritance last name')

   }else if(UserProfileDataForm.inheritance_phone_number < 10){

    setFormValidationError({...FormValidationError,InheritancePhoneNumberError:true})

    return reject('Input inheritance phone number')

   }else if(UserProfileDataForm.inheritance_relationship.length < 2){

    setFormValidationError({...FormValidationError,InheritanceRelationshipError:true})

    return reject('Input inheritance relationship')

   }else if(UserProfileDataForm.inheritance_bank_name.length < 2){

    setFormValidationError({...FormValidationError,InheritanceBanknameError:true})

    return reject('Input inheritance bank name')

   }else if(UserProfileDataForm.inheritance_bank_account_fullname.length < 5){

    setFormValidationError({...FormValidationError,InheritanceBanckAccountFullnameError:true})

   



   return reject('Input inheritance bank full name')

  }
   else if(UserProfileDataForm.inheritance_bank_number.length < 10){

    setFormValidationError({...FormValidationError,InheritanceBankNumberError:true})


   return reject('Input inheritance bank number')

  }
  else{

    return resolve('successfuly validation')
   }


  })
}






const OnsubmitProfileUpdate = ()=>{

//---validate form first
FormValidation().then(function(JsonResponse){

   setProfileUpdateLoading(true) //turn on loading
   var FormData = UserProfileDataForm
   HttpServer_UpdateProfileFormData(server_ip,FormData,profile_completed,authentication_token).then(function(JsonResponse){
    const {status} = JsonResponse

    setProfileUpdateLoading(false) //turn on loading

   if(status===200){
    const {Jsondata} = JsonResponse
    const {message} = Jsondata

    UpdateProfileCompletedStatus()//activate completed profile status

    NotificationActivate('profile updated successfully !','success',true,true)

    // setServerStatusResponse(status)
    // setServerMessage(message)

    

   }else{

    NotificationActivate(`(server error) : something went wrong ${status}`,'warning',true,true)
   
    // const {message} = Jsondata
    // setServerStatusResponse(status)
    // setServerMessage(message)
    


}

}).catch(function(ErrorResponse){

    //  setRegistrationLoading(false)
    setProfileUpdateLoading(false) //turn on loading
    NotificationActivate(`(application error) : something went wrong `,'information',true,true)
})

}).catch(function(ValidationErrorMesage){

  

})


}





const ProfilePhotoReader = new FileReader()

    
    
const BinaryImageReader = (Image)=>{
    return new Promise(function(resolve,reject){

      ProfilePhotoReader.readAsDataURL(Image)

      var imageReaderTimer = setInterval(function(){
        
      if(JSON.stringify(ProfilePhotoReader.result).length > 100){

     
        clearInterval(imageReaderTimer) 
    
      return resolve(ProfilePhotoReader.result)
      }  
          
      },100)
    
    
    
    })  
    
}
    
const OnchangeProfilePhotoUpload= (event)=>{
      try {
        var ImageFile = event.target.files[0]
        var Type = ImageFile.type
        var size = ImageFile.size
        const {validSize,validType} =  ImageSizeValidator(size,Type)
    
        if(validSize===true && validType===true){
    
            BinaryImageReader(ImageFile).then(function(BinaryImage){

              setProfilePhotoUpdateLoading(true) //turn on loading
              //HTTP UPDATE PROFILE PHOTO
              HttpServer_UpdateProfilePhoto(server_ip,{profile_photo:BinaryImage},authentication_token).then(function(JsonResponse){
              setProfilePhotoUpdateLoading(false) //turn off loading
              const {status} =JsonResponse
              
              //SET UPDATED PROFILE
              if(status===200){

                ProfilePhotoUpdateFuction(BinaryImage)

              }



            }).catch(function(ErrorResponse){

               setProfilePhotoUpdateLoading(false) //turn off loading

            })

           

           })
    
        }else{
    
          alert("INVALID IMAGE SIZE OR TYPE")
    
    
        }
    
        
      }catch (error) { //IMAGE VALIDATION ERROR
        
        console.log(error)
        //setSpinnerUploadCoverPhoto(false)
      }
    }
   


const ProfileUpdateTriggerFunction = () => {
  

  //setCurrentClickedImage(imageid)

  const Button = $("#profile-photo-input")
  Button[0].click()
        
          
};
  



const ProfileSkeletonLoading =()=>{
return(
    <Grid item xs={12} sm={6}>
  <div className="card" style={{borderTop:`4px solid ${themecolor1}`}}>
    <div className="card-body">

       <div className='skeleton-loading-profile-wrapper'>

          <div className='skeleton-profile-circle'> <Skeleton variant='circle' animation='pulse' style={{width:140,height:140}} />  </div>
          <br />
          <br />
         </div>
         <br />

        <div className='profile-photo-divider-skeleton'>
        <Skeleton variant='text' animation='pulse' style={{width:'70%',height:40}} /> 
        </div>
        <br/>
          <div className='profile-photo-divider-skeleton'>
          <Skeleton variant='text' animation='pulse' style={{width:'70%',height:40}} /> 
          </div>
          <br />
        <div className='skeleton-form-field'>
        <Skeleton variant='rect' animation='pulse' style={{width:'100%',height:60}} /> 
        </div>
        <br/>
        <div className='skeleton-form-field'>
        <Skeleton variant='rect' animation='pulse' style={{width:'100%',height:60}} /> 
        </div>
        <br/>
        <div className='skeleton-form-field'>
        <Skeleton variant='rect' animation='pulse' style={{width:'100%',height:60}} /> 
        </div>
        <br/>
        <div className='skeleton-form-field'>
        <Skeleton variant='rect' animation='pulse' style={{width:'100%',height:60}} /> 
        </div>
        <br/>
        <div className='skeleton-form-field'>
        <Skeleton variant='rect' animation='pulse' style={{width:'100%',height:60}} /> 
        </div>
        <br/>



    </div>
   </div>
  </Grid>
)
}
  



// const ACTIVATENOTIFICATION = ()=>{


//   NotificationActivate('DISCONNECTED','warning',true,false)
// }



return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="profile-wrapper">
{/* <button onClick={ACTIVATENOTIFICATION}>ACTIVATE NOTIFICATION</button> */}
<Grid container spacing={1}>
{profile_completed===0?
<Grid item xs={12}>
<div className="callout callout-danger">
    <h5>INCOMPLETE PROFILE!</h5>

  <p>Please complete setting up your profile to continue !</p>
  </div>

</Grid>
:null}



{ServerResponse !==null?
<Grid item xs={12} sm={6}>
  <div className="card" style={{borderTop:`4px solid ${themecolor1}`}}>
    <div className="card-body">
      <div className='profile-photo-container'>
        <div class='profile-photo-circle' style={{backgroundImage:`url(${profile_photo})`,border:`2px solid ${themecolor1}`}}>
      
          <div className='update-profile-loader-wrapper'><ClipLoader color={themecolor1} size={50} loading={ProfilePhotoUpdateLoading} /></div>   
          <div className='update-profil-wrapper' style={{backgroundColor:`${themecolor1}`}}>
          <Link to='#' onClick={ProfileUpdateTriggerFunction}>
             <AddAPhoto fontSize='large' style={{color:`#fff`}}/>
          </Link>
          </div>
       </div>
      
      </div>
            
            <br />
            <br />
         <div className='profile-text-wrapper'>
         <div className='profile-photo-divider'></div>
         <span>{`${UserProfileDataForm.first_name} ${UserProfileDataForm.last_name} ${UserProfileDataForm.middle_name}`} </span>
   
         <div className='profile-photo-divider'></div>
         <span>SPONSOR ID : {generation_id}</span>
        </div>  
    
      

        <Grid container spacing={1}>

        <Grid item xs={12}>
        <div className="profile-rank-displayer" style={{border:`2px solid ${themecolor1}`,backgroundColor:`${themecolor1}`}}>
        <Grid container spacing={1}> 

        <Grid item xs={6}>
        <div className='profile-rank-title' style={{backgroundColor:`${themecolor2}`}}><span>RANK :  {rank.toLocaleUpperCase()}</span></div> 
        </Grid>

        <Grid item xs={6} className='profile-rank-circle-wrapper'><div className='profile-rank-circle'><img src={RankBadgeIdentifier(rank)} /> </div> </Grid>
        
        </Grid>
            <Link to='#'  className='profile-sidebar-button'> <MoreVert fontSize='large'/></Link>
          </div>
        </Grid>


        <Grid item xs={12}>
        <div className="profile-rank-displayer" style={{border:`2px solid ${themecolor1}`,backgroundColor:`${themecolor1}`}}>
        <Grid container spacing={1}> 

        <Grid item xs={6}>
        <div className='profile-rank-title' style={{backgroundColor:`${themecolor2}`}}><span>MY SPONSOR</span></div> 
        </Grid>

        <Grid item xs={6} className='profile-rank-circle-wrapper'><div className='profile-rank-circle'><img src={UserProfileDataForm.upliner_profile.profile_photo} alt='profile #'/> </div> </Grid>
         
        </Grid>
             <Link to='#'  data-bs-toggle="modal" data-bs-target="#cpv-points-modal" className='profile-sidebar-button'> <MoreVert fontSize='large'/></Link>
          </div>
        </Grid>


       
         
      
             
        </Grid>

        <Typography variant='caption'>---------PERSONAL INFORMATION---------</Typography>
          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Primary Email"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangePrimaryEmail} value={UserProfileDataForm.primary_email}
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">

                 {UserProfileDataForm.primary_email.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.PrimaryEmailError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
               
              </div>
              </Grid>
              <Grid item xs={10}>
                {FormValidationError.PrimaryEmailError===true?
                <div className="validation-error-message">
                <span>Username or email too short </span>
                </div>
                :null}
              </Grid>
           </Grid>
           <Divider />
           <br/>

   
          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
               <TextField
              id="filled-required"
              label="First Name"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeFirstName} value={UserProfileDataForm.first_name}
               disabled={true} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {UserProfileDataForm.first_name.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.FirstNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null} 
      
              </div>
              </Grid>

              <Grid item xs={10}>
                {FormValidationError.FirstNameError===true?
                <div className="validation-error-message">
                <span>Firstname too short </span>
                </div>
                :null}
             </Grid>


           </Grid>
           <Divider />
           <br/>

           
          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Middle Name"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeMiddleName} value={UserProfileDataForm.middle_name}
               disabled={true} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {UserProfileDataForm.middle_name.length > 3?<CheckCircle fontSize="default" className="validation-success"/>:null}
                  {FormValidationError.MiddleNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null} 
              </div>
              </Grid>
              <Grid item xs={10}>
                {FormValidationError.MiddleNameError===true?
                <div className="validation-error-message">
                <span>middle name too short </span>
                </div>
                :null}
             </Grid>
           </Grid>
            <Divider />
            <br/>

            <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Last Name"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeLastName} value={UserProfileDataForm.last_name}
               disabled={true} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                {UserProfileDataForm.last_name.length> 3?<CheckCircle fontSize="default" className="validation-success"/>:null}
                {FormValidationError.LastNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>

              <Grid item xs={10}>
                {FormValidationError.LastNameError===true?
                <div className="validation-error-message">
                <span>lastname too short </span>
                </div>
                :null}
             </Grid>


           </Grid>
           <Divider />
            <br/>

     

           <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Phone number 1"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangePhoneNumber1} value={UserProfileDataForm.phone_number1}
              // disabled={ProfileSaveLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {UserProfileDataForm.phone_number1.length >= 10?<CheckCircle fontSize="default" className="validation-success"/>:null}
                  {FormValidationError.PhoneNumber1Error ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
                   
              </div>
              </Grid>
              <Grid item xs={10}>
                {FormValidationError.PhoneNumber1Error===true?
                <div className="validation-error-message">
                <span>input valid phone number 1</span>
                </div>
                :null}
             </Grid>
           </Grid>
            <Divider />
             <br/>


            <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Phone number 2(optional)"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangePhoneNumber2} value={UserProfileDataForm.phone_number2}
              // disabled={ProfileSaveLoading===true?true:false} 
              />
            </Grid>

              <Grid item xs={1}>
              <div className="registration-validation">
                  {UserProfileDataForm.phone_number2.length >= 10?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 </div>
              </Grid>
              
           </Grid>
             <Divider />
               <br/>
               <Divider />
             <Typography variant='caption'>---------PERSONAL BANKING DETAILS---------</Typography>
               <Grid container spacing={0}> 
                <Grid item xs={11} sm={10}>
                 <TextField
                  label="Bank name"
                  variant="filled"
                  fullWidth={true}
                   InputLabelProps={{
                   shrink: true,
                    }}
                   size='small'
                   onChange={OnchangeBankName} value={UserProfileDataForm.bank_name}
                   disabled={profile_completed===0?false:true}
                  />
               </Grid>
               <Grid item xs={1}>
                 <div className="registration-validation">
                 {UserProfileDataForm.bank_name.length >= 3 ?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.BankNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
                </div>
              </Grid> 
              <Grid item xs={10}>
                {FormValidationError.BankNameError===true?
                <div className="validation-error-message">
                <span>input valid bank name</span>
                </div>
                :null}
             </Grid>
               </Grid>
              <Divider />
               <br/>



                <Grid container spacing={0}> 
                   <Grid item xs={11} sm={10}>
                    <TextField
                    label="Bank account full name"
                    variant="filled"
                    fullWidth={true}
                     InputLabelProps={{
                     shrink: true,
                      }}
                       size='small'
                       onChange={OnchangeBankAccountFullName} value={UserProfileDataForm.bank_account_fullname}
                       disabled={profile_completed===0?false:true}
                      />
                     </Grid>

                 <Grid item xs={1}>
                  <div className="registration-validation">
                   {UserProfileDataForm.bank_account_fullname.length >= 3 ?<CheckCircle fontSize="default" className="validation-success"/>:null}
                   {FormValidationError.BankAccountFullNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
                   </div>
                   </Grid>

                <Grid item xs={10}>
                {FormValidationError.BankAccountFullNameErro===true?
                <div className="validation-error-message">
                <span>input valid bank acccount full name</span>
                </div>
                :null}
                </Grid>

                  </Grid>
                  <Divider />
                   <br/>


                 <Grid container spacing={0}> 
                   <Grid item xs={11} sm={10}>
                   <TextField
                    label="Bank number"
                      variant="filled"
                     fullWidth={true}
                     InputLabelProps={{
                        shrink: true,
                    }}
                    size='small'
                    onChange={OnchangeBankNumber} value={UserProfileDataForm.bank_number}
                    disabled={profile_completed===0?false:true}
                    />
                    </Grid>

                <Grid item xs={1}>
                 <div className="registration-validation">
                  {UserProfileDataForm.bank_number.length >= 10 && UserProfileDataForm.inheritance_bank_number.length <= 15?<CheckCircle fontSize="default" className="validation-success"/>:null}
                  {FormValidationError.BankAccountNumberError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
                </div>
                </Grid>

                <Grid item xs={10}>
                {FormValidationError.BankAccountNumberError===true?
                <div className="validation-error-message">
                <span>input valid bank number</span>
                </div>
                :null}
                </Grid>

              
                 </Grid>
                <Divider />
                 <br/> 


                 <Typography variant='caption'>---------LOCATION ADDRESS---------</Typography>
                <Grid container spacing={0}>
                 <Grid item xs={11} sm={10}>
                 <div className="form-group">
                    <label htmlFor="exampleFormControlSelect4">Select Region(City)</label>
                      <select onChange={OnchangeRegionName}  value={UserProfileDataForm.region_name} className="form-control" id="exampleFormControlSelect4">
     
                      <option value="0">select</option>
                     <TanzaniaRegionsList />
                     </select>
                  </div>

                </Grid>
              
               <Grid item xs={1}>
                 <div className="registration-validation">
                 {UserProfileDataForm.region_name !=="0" && UserProfileDataForm.region_name.length > 0?<CheckCircle fontSize="default" className="validation-success"/>:null}    
                {FormValidationError.RegionNameError===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
               
                 </div>
                 </Grid>
                 <Grid item xs={10}>
                {FormValidationError.RegionNameError===true?
                <div className="validation-error-message">
                <span>please select region </span>
                </div>
                :null}
             </Grid>
                </Grid>
                <Divider />
                 <br/>


                <Grid container spacing={0}>
                 <Grid item xs={11} sm={10}>
                  <div className="form-group">
                    <label htmlFor="FormControlSelectDistrict"> Select District</label>
                    <select  value={UserProfileDataForm.district_name} onChange={OnchangeDistrictName} className="form-control" id="FormControlSelectDistrict">

                    <option value="0"> select district</option>
                    <TanzaniaDistrictList region={UserProfileDataForm.region_name}/>
     
                     </select>
                    </div>

                </Grid>
              
               <Grid item xs={1}>
                 <div className="registration-validation">
                {UserProfileDataForm.district_name !=="0" && UserProfileDataForm.district_name.length > 0?<CheckCircle fontSize="default" className="validation-success"/>:null}     
                {FormValidationError.DistrictNameError===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}    
                  
                 </div>
                 </Grid>

                 <Grid item xs={10}>
                {FormValidationError.DistrictNameError===true?
                <div className="validation-error-message">
                <span>please select district </span>
                </div>
                :null}
               </Grid>


                </Grid>
                <Divider />
                 <br/>


               <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Ward"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeWardName} value={UserProfileDataForm.ward_name}
              />
            </Grid>

                <Grid item xs={1}>
              <div className="registration-validation">
                  {UserProfileDataForm.ward_name.length >  2?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.WardNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
              <Grid item xs={10}>
                {FormValidationError.WardNameError===true?
                <div className="validation-error-message">
                <span>please select ward (within your district) </span>
                </div>
                :null}
             </Grid>

             <Grid item xs={10}>
                {FormValidationError.WardNameError===true?
                <div className="validation-error-message">
                <span>fill in ward name </span>
                </div>
                :null}
               </Grid>


           </Grid>
                <Divider />
                  <br/>



                            <Typography variant='caption'> ------- INHERITANCE INFORMATION ------ </Typography>
                             <Divider />

                             <Grid container spacing={0}> 
                             <Grid item xs={11} sm={10}>
                                <TextField
                                 label="first name"
                                 variant="filled"
                                 fullWidth={true}
                                  InputLabelProps={{
                                   shrink: true,
                                    }}
                                   size='small'
                                   onChange={OnchangeInheritanceFirstName} value={UserProfileDataForm.inheritance_first_name}
                                   disabled={profile_completed===0?false:true}

                                   />
                                 </Grid>

                          <Grid item xs={1}>
                          <div className="registration-validation">
                            {UserProfileDataForm.inheritance_first_name.length > 3?<CheckCircle fontSize="default" className="validation-success"/>:null}
                            {FormValidationError.InheritanceFirstnameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
                              </div>
                               </Grid>

                              <Grid item xs={10}>
                               {FormValidationError.InheritanceFirstnameError===true?
                               <div className="validation-error-message">
                                <span>first name</span>
                               </div>
                                :null}
                              </Grid>

                                 </Grid>
                                  <Divider />
                                     <br/>

    
<Grid container spacing={0}> 
<Grid item xs={11} sm={10}>
<TextField
label="middle name"
variant="filled"
fullWidth={true}
InputLabelProps={{
  shrink: true,
}}
size='small'
onChange={OnchangeInheritanceMiddleName} value={UserProfileDataForm.inheritance_middle_name}
disabled={profile_completed===0?false:true}
/>
</Grid>

<Grid item xs={1}>
<div className="registration-validation">
    {UserProfileDataForm.inheritance_middle_name.length > 3?<CheckCircle fontSize="default" className="validation-success"/>:null}
   {FormValidationError.InheritanceMiddlenameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
</div>
</Grid>

<Grid item xs={10}>
{FormValidationError.InheritanceMiddlenameError===true?
<div className="validation-error-message">
<span>input valid middle name</span>
</div>
:null}
</Grid>
</Grid>
<Divider />
<br/>

<Grid container spacing={0}> 
<Grid item xs={11} sm={10}>
<TextField
label="last name"
variant="filled"
fullWidth={true}
InputLabelProps={{
  shrink: true,
}}
size='small'
onChange={OnchangeInheritanceLastName} value={UserProfileDataForm.inheritance_last_name}
disabled={profile_completed===0?false:true}
/>
</Grid>

<Grid item xs={1}>
<div className="registration-validation">
    {UserProfileDataForm.inheritance_last_name.length > 3?<CheckCircle fontSize="default" className="validation-success"/>:null}
   {FormValidationError.InheritanceLastNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
</div>
</Grid>

<Grid item xs={10}>
{FormValidationError.InheritanceLastNameError===true?
<div className="validation-error-message">
<span>input valid last name</span>
</div>
:null}
</Grid>


</Grid>
<Divider />
<br/>


<Grid container spacing={0}> 
<Grid item xs={11} sm={10}>
<TextField
label="phone number"
variant="filled"
fullWidth={true}
InputLabelProps={{
  shrink: true,
}}
size='small'
onChange={OnchangeInheritancePhoneNumber} value={UserProfileDataForm.inheritance_phone_number}
disabled={profile_completed===0?false:true}
/>
</Grid>

<Grid item xs={1}>
<div className="registration-validation">
    {UserProfileDataForm.inheritance_phone_number.length >= 10 && UserProfileDataForm.inheritance_phone_number.length <= 13?<CheckCircle fontSize="default" className="validation-success"/>:null}
   {FormValidationError.InheritancePhoneNumberError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
</div>
</Grid>

<Grid item xs={10}>
{FormValidationError.InheritancePhoneNumberError===true?
<div className="validation-error-message">
<span>input valid phone_number</span>
</div>
:null}
</Grid>
</Grid>
<Divider />
<br/>


<Grid container spacing={0}> 
<Grid item xs={11} sm={10}>
<TextField
label="relationship"
variant="filled"
fullWidth={true}
InputLabelProps={{
  shrink: true,
}}
size='small'
onChange={OnchangeInheritanceRelationShip} value={UserProfileDataForm.inheritance_relationship}
disabled={profile_completed===0?false:true}
/>
</Grid>

<Grid item xs={1}>
<div className="registration-validation">
    {UserProfileDataForm.inheritance_relationship.length >= 3 ?<CheckCircle fontSize="default" className="validation-success"/>:null}
   {FormValidationError.InheritanceRelationshipError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
</div>
</Grid>

<Grid item xs={10}>
{FormValidationError.InheritanceRelationshipError===true?
<div className="validation-error-message">
<span>invalid relationship</span>
</div>
:null}
</Grid>
</Grid>
<Divider />
<br/>


<Divider />
<Typography variant='caption'>---------INHERITANCE BANKING DETAILS---------</Typography>
<Grid container spacing={0}> 
<Grid item xs={11} sm={10}>
<TextField
label="Bank name"
variant="filled"
fullWidth={true}
InputLabelProps={{
  shrink: true,
}}
size='small'
onChange={OnchangeInheritanceBankName} value={UserProfileDataForm.inheritance_bank_name}
disabled={profile_completed===0?false:true}
/>
</Grid>

<Grid item xs={1}>
<div className="registration-validation">
    {UserProfileDataForm.inheritance_bank_name.length >= 2 ?<CheckCircle fontSize="default" className="validation-success"/>:null}
   {FormValidationError.InheritanceBanknameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
</div>
</Grid>

<Grid item xs={10}>
{FormValidationError.InheritanceBanknameError===true?
<div className="validation-error-message">
<span>invalid Bank name</span>
</div>
:null}
</Grid>


</Grid>
<Divider />
<br/>

<Grid container spacing={0}> 
<Grid item xs={11} sm={10}>
<TextField
  label="Bank account full name" 
  variant="filled"
  fullWidth={true}
   InputLabelProps={{
    shrink: true,
    }}
    size='small'
  onChange={OnchangeInheritanceBankAccountFullName} value={UserProfileDataForm.inheritance_bank_account_fullname}
  disabled={profile_completed===0?false:true}
  />
</Grid>

<Grid item xs={1}>
<div className="registration-validation">
    {UserProfileDataForm.inheritance_bank_account_fullname.length >= 3 ?<CheckCircle fontSize="default" className="validation-success"/>:null}
   {FormValidationError.InheritanceBanckAccountFullnameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
</div>
</Grid>

<Grid item xs={10}>
{FormValidationError.InheritanceBanckAccountFullnameError===true?
<div className="validation-error-message">
<span>invalid bank account full name</span>
</div>
:null}
</Grid>



</Grid>
<Divider />
<br/>


<Grid container spacing={0}> 
<Grid item xs={11} sm={10}>
<TextField
label="Bank number"
variant="filled"
fullWidth={true}
InputLabelProps={{
  shrink: true,
}}
size='small'
onChange={OnchangeInheritanceBankNumber} value={UserProfileDataForm.inheritance_bank_number}
disabled={profile_completed===0?false:true}
/>
</Grid>

<Grid item xs={1}>
<div className="registration-validation">
    {UserProfileDataForm.inheritance_bank_number.length >= 10?<CheckCircle fontSize="default" className="validation-success"/>:null}
    {FormValidationError.InheritanceBankNumberError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
</div>
</Grid>

<Grid item xs={10}>
{FormValidationError.InheritanceBankNumberError===true?
<div className="validation-error-message">
<span>Invalid Bank number</span>
</div>
:null}
</Grid>


</Grid>
<Divider />
<br/> 


       
      
                <input type="file"  id="profile-photo-input" onChange={OnchangeProfilePhotoUpload} style={{visibility:'hidden'}}/>

                <div className='update-profile-loader-wrapper'>
                <ScaleLoader speedMultiplier={2} loading={ProfileUpdateLoading} height={50} width={10} color={themecolor1} />
                </div>
                


           </div>


     
              <ButtonGroup fullWidth={true}  color='secondary' aria-label="small outlined button group">
                 <Button disabled={ProfileUpdateLoading===true?true:false} onClick={OnsubmitProfileUpdate} type="button"  data-bs-dismiss="modal"  color='primary'>Save Profile</Button>
               </ButtonGroup>
      </div>


</Grid>

:<ProfileSkeletonLoading />}







</Grid>
 
   




{/* ----------MODAL------------- */}
{/* <Button color='primary' onClick={()=>{ActivateModalInfor('network')}} data-bs-toggle="modal" data-bs-target="#cpv-points-modal">Moreinfo</Button>
   */}
<div>
  <div className="modal fade" id="cpv-points-modal" data-bs-backdrop='static' data-bs-keyboard="false" tabIndex={-1} aria-labelledby="loan-application-label" aria-hidden="true">
    <div className="modal-dialog" style={{border:`4px solid ${themecolor1}`}}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="loan-application-label">SPONSOR (UPLINER)</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className='sponsor-profile' style={{backgroundColor:`${themecolor1}`}}>
            <div className='sponsor-profile-circle'><img src={UserProfileDataForm.upliner_profile.profile_photo} alt='upliner profile'/> </div>
            <h4>{UserProfileDataForm.upliner_profile.rank} </h4>
          </div>
        
             <ul className='uppliner-list-detail'>

               <li><b>Name: </b> {UserProfileDataForm.upliner_profile.first_name} {UserProfileDataForm.upliner_profile.middle_name}  </li>
               <Divider />
               <li><b>Contact </b>{UserProfileDataForm.upliner_profile.phone_number1} </li>
               <li><b>Location </b>{UserProfileDataForm.upliner_profile.region_name}</li>
             </ul>

         </div>
       
         <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>
 



</div>
</Fragment>
 
    
);


// Profile.propTypes = {}
        
// Profile.defaultProps ={}


}
export {Profile};

