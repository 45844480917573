import React ,{Fragment,useEffect}from 'react';
import './UserRegistration.scss';
//import Grid from '@material-ui/core/Grid';
//import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
//import {Money,History,LocalAtm,AccountBalance} from '@material-ui/icons';
import Container from '@material-ui/core/Container';
import {UserRegistrationForm} from '../../AdminAccounts/components/UserRegistrationForm/UserRegistrationForm';

// import {MoreVert} from '@material-ui/icons';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
// }));



    
const UserRegistration = ({props}) => { //--------------MAIN FUNCTION----------------
 //const classes = useStyles();



//const [ErrorMessage, setErrorMessage] = React.useState(null);

const [DashboardInfor, setDashboardInfor] = React.useState({
  title:'',
  description:'',
  icon:'',
});

//const [ModalTitle, setModalTitle] = React.useState('');





useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  



//0683300300 msangwa herbal kona ya nyamhongoro



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])








return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="user-registration-wrapper">



<div className="container">
  <div className="row g-2">
    <div className="col-12">

      <Container maxWidth='sm'>

      <UserRegistrationForm />

      </Container>
      




    </div>
  </div>
</div>

















{/* ----------MODAL------------- */}
{/* <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
  <Button color='primary' onClick={()=>{ActivateModalInfor('balance')}} data-bs-toggle="modal" data-bs-target="#cpv-points-modal">Moreinfor</Button>
 </ButtonGroup>
        */}
<div>
  <div className="modal fade" id="cpv-points-modal" data-bs-backdrop='static' data-bs-keyboard="false" tabIndex={-1} aria-labelledby="loan-application-label" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="loan-application-label"></h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className='modal-icon-holder'>
            <div className='modal-circle-icon'><img src={DashboardInfor.icon} />  </div>
          </div>
        
       

         </div>
       
         <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>
 

 </div>
</Fragment>
 
    
);


// UserRegistration.propTypes = {}
        
// UserRegistration.defaultProps ={}


}
export {UserRegistration};

