
  
const HttpServer_UserRegistration=(ServerIp,Form,keys='3DDDFRTFDD644')=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  'Authorization': `{"auth_token":"${keys}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/user_registration`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200 || status===202){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
 
    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}


  
const HttpServer_AddSupperAccount =(ServerIp,Form,keys='3DDDFRTFDD644')=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${keys}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/add_supperaccount`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse = JSON.parse(TextResponse)
  if(status === 200){ //-----SUCCESS RESPONSE
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    
    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}






  
const HttpServer_FetchUserProfileInformation =(ServerIp,UserName,keys='3DDDFRTFDD644')=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  'Authorization': `{"auth_token":"${keys}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/user_profile_information/${UserName}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse = JSON.parse(TextResponse)
  if(status === 200){ //-----SUCCESS RESPONSE
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    
    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}




const HttpServer_UserLogin = (ServerIp,FormPayload,keys='3DDDFRTFDD644')=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${keys}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/admin_login`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(FormPayload),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:JSON.parse(TextResponse)})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}

















  
const HttpServer_UpdateProfileFormData=(ServerIp,FormData,profile_completed,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/update_profile_field/${authentication_token}/${profile_completed}`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(FormData),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    JsonResponse  = JSON.parse(TextResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}



const HttpServer_UpdateProfilePhoto=(ServerIp,ProfileImage,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/update_profile_photo/${authentication_token}`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(ProfileImage),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    JsonResponse  = JSON.parse(TextResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}














const ServerFirstIndexedb_FetchInboxMessages = (ServerIp,inbox_type,pagination_start=0,pagination_end=10,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/fetch_inbox_messages/${authentication_token}/${inbox_type}/${pagination_start}/${pagination_end}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

    status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}





const ServerFirstIndexedb_ActivateReadMessage =(ServerIp,message_id,message_type,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/activate_read_message/${authentication_token}/${message_type}/${message_id}`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify({}),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    JsonResponse  = JSON.parse(TextResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}




const ServerFirstIndexedb_TransactionWithdrawRequest =(ServerIp,FormData,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/transaction_withdraw_request/${authentication_token}`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(FormData),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    JsonResponse  = JSON.parse(TextResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}





const ServerFirstIndexedb_TransactionalAppMounting = (ServerIp,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/transaction_app_mounting/${authentication_token}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

    status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}










const ServerFirstIndexedb_ApplicationMounting = (ServerIp,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/application_mounting/${authentication_token}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}







const ServerFirstIndexedb_FetchGeneration = (ServerIp,authentication_token,generation='distributor',pagination_start,pagination_end)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/fetch_generation/${generation}/${pagination_start}/${pagination_end}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}



  
const ServerFirstIndexedb_FetchUserProfile = (ServerIp,GenerationID,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/fetch_user_profile/${GenerationID}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}



const ServerFirstIndexedb_SearchUser = (ServerIp,search_queery,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/search_user/${search_queery}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}






const ServerFirstIndexedb_SearchUSERID = (ServerIp,UserID,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/search_user_id/${UserID}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}

// const HtttSearchUserCommisionFunction = ({server_ip,authentication_token,product_id,user_id})=>{
//   return new Promise(function(resolve,reject){
//   var status = undefined 
//   var Headers =  {
//   'accept': 'application/json',
//   'Content-Type':'application/x-www-form-urlencoded',
//   // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
//   } 
  
//   fetch(`${server_ip}api/adminapp/${authentication_token}/search_user_id`,
//   {method:'POST', 
//   headers: Headers,
//   body:JSON.stringify({user_id:user_id,product_id:product_id}),
  
//   }).then(function(Response){ 

//   status = Response.status
  
//   return Response.text()
//   }).then(function(TextResponse){ 
//   var JsonResponse 
//   if(status === 200){ //-----SUCCESS RESPONSE
//     JsonResponse  = JSON.parse(TextResponse)
     
  
//     return  resolve({status:status,Jsondata:JsonResponse})
  
//    }else{

//     return  resolve({status:status,Jsondata:{}})
//    }
//   }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
//     console.log(error)

//     return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
// })  
// })
// }

const ISJson =(str)=>{
  try {
      JSON.parse(str);
  } catch (e){
  
      return false;
  }
  return true;
  }
  


const HtttSearchUserCommisionFunction=({server_ip,authentication_token,user_id,product_id})=>{
  return new Promise(function(resolve,reject){
     var status = 'offline'
      var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    
      } 
      return fetch(`${server_ip}api/adminapp/${authentication_token}/search_user_id`,
      {
      method:'POST', 
      credentials:'include',
      headers: Headers,
      body:JSON.stringify({user_id:user_id,product_id:product_id})
      }).then(function(Response){ 
       status = Response.status
      
      return Response.text()
      }).then(function(TextResponse){
       var ISJSON = ISJson(TextResponse)
       if(ISJSON){//CHECK IF ITS JSON
         var JsonResponse = JSON.parse(TextResponse)
         JsonResponse['server_status'] = status

  
  
          return resolve(JsonResponse)
  
        }else{
  
            return resolve({data:{},server_status:status,server_message:'server_error'})
  
        }
        }).catch(function(ErrorResponse){//--------------CATCH ALL THE ERROR
          const ErrorMessage = `${ErrorResponse}`.includes('Failed to fetch')===true?'offline':undefined
  
          console.log(ErrorResponse)
  
        return resolve({data:null,server_status:status,error_status:ErrorMessage,server_message:'connection_error'})
         
     })  
    
  })}


  
const HtttSearchUserRankInformation=({server_ip,authentication_token,user_id})=>{
  return new Promise(function(resolve,reject){
     var status = 'offline'
      var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    
      } 
      return fetch(`${server_ip}api/adminapp/${authentication_token}/search_user_rank`,
      {
      method:'POST', 
      credentials:'include',
      headers: Headers,
      body:JSON.stringify({user_id:user_id})
      }).then(function(Response){ 
       status = Response.status
      
      return Response.text()
      }).then(function(TextResponse){
       var ISJSON = ISJson(TextResponse)
       if(ISJSON){//CHECK IF ITS JSON
         var JsonResponse = JSON.parse(TextResponse)
         JsonResponse['server_status'] = status

  
  
          return resolve(JsonResponse)
  
        }else{
  
            return resolve({data:{},server_status:status,server_message:'server_error'})
  
        }
        }).catch(function(ErrorResponse){//--------------CATCH ALL THE ERROR
          const ErrorMessage = `${ErrorResponse}`.includes('Failed to fetch')===true?'offline':undefined
  
          console.log(ErrorResponse)
  
        return resolve({data:null,server_status:status,error_status:ErrorMessage,server_message:'connection_error'})
         
     })  
    
})}
  
  






const ServerFirstIndexedb_FetchPaymentRequest = (ServerIp,transaction_status,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/fetch_payment_request/${transaction_status}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}



const ServerFirstIndexedb_VoucherGenerate = (ServerIp,FormPayload,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/voucher_generate`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(FormPayload),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}







const ServerFirstIndexedb_ApprovePaymentRequest = (ServerIp,FormPayload,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/approve_payment_request`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(FormPayload),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}










const ServerFirstIndexedb_FetchVoucherList = (ServerIp,pagination_start,pagination_end,voucher_type,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/fetch_voucher_list/${pagination_start}/${pagination_end}/${voucher_type}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}



const ServerFirstIndexedb_FetchProductList = (ServerIp,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/fetch_product_list`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}








const ServerFirstIndexedb_AddProductPurchase =(ServerIp,authentication_token,ProductPackage)=>{
  return new Promise(function(resolve,reject){
  var status = 100 //APPLICATION ERROR STATUS
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/${authentication_token}/add_product_purchase`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(ProductPackage),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
           console.log(JsonResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    JsonResponse  = JSON.parse(TextResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(Response)

    return  reject({status:status})
})  
})
}








export{
HttpServer_UserRegistration,
HttpServer_AddSupperAccount,
HttpServer_FetchUserProfileInformation,
HttpServer_UserLogin,
ServerFirstIndexedb_ApplicationMounting,
ServerFirstIndexedb_FetchGeneration,
ServerFirstIndexedb_FetchUserProfile,
HttpServer_UpdateProfileFormData,
HttpServer_UpdateProfilePhoto,
ServerFirstIndexedb_FetchInboxMessages,
ServerFirstIndexedb_ActivateReadMessage,
ServerFirstIndexedb_TransactionWithdrawRequest,
ServerFirstIndexedb_TransactionalAppMounting,
ServerFirstIndexedb_SearchUser,
ServerFirstIndexedb_FetchPaymentRequest,
ServerFirstIndexedb_ApprovePaymentRequest,
ServerFirstIndexedb_VoucherGenerate,
ServerFirstIndexedb_FetchVoucherList,
ServerFirstIndexedb_SearchUSERID,
ServerFirstIndexedb_FetchProductList,
ServerFirstIndexedb_AddProductPurchase,
HtttSearchUserCommisionFunction,
HtttSearchUserRankInformation

}


