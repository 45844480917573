import React ,{Fragment,useEffect,useContext}from 'react';
import {useLocation,useHistory,useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import './UserProfilePreview.scss';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Skeleton from '@material-ui/lab/Skeleton';
import {Delete,Lock,LockOpen,ArrowBack} from '@material-ui/icons';
import {Divider,Typography} from '@material-ui/core';
import {ServerFirstIndexedb_FetchUserProfile} from "../../HttpcallUserApp";
import {NavigatioButtons} from "../NavigationButtons/NavigationButtons";
import {NotificationActivate} from "../NotificationApplication/NotificationApplication";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

    
const UserProfilePreview = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,App,User} = Appstate

  const {CurrencyFormat} = Functions

  const {server_ip,authentication_token} = App
  const {themecolor1,themecolor2,AccountType} = Appsettings

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [currentTab, setcurrentTab] = React.useState('distributor');

  const Location = useLocation()
  const History = useHistory()

const {generation_id,distributor_count,generation6_count,
  generation7_count,generation8_count,gold_count,rubby_count,silver_count,tanzanite_count} =User


const [ServerResponse, setServerResponse] = React.useState(null);



 const [PaginationInterval, setPaginationInterval] = React.useState(9);//python  slicing system 9 == > 10 from 0 - 9


 const [PaginationStart, setPaginationStart] = React.useState(0);


 const [PaginationEnd, setPaginationEnd] = React.useState(PaginationInterval);//python slicing system 9 means 10




const [UserGenerationList, setuserGenerationList] = React.useState({
    distributor:[
      {
      distributor_count: 0,
      district: "",
      first_name: "jackson",
      generation6_count: 0,
      generation7_count: 0,
      generation8_count: 0,
      gold_count: 0,
      id: "A41264-FMFP",
      last_name: "patrick",
      middle_name: "henly",
      phone_number1: "",
      profile: "reward-1.jpg",
      rank: "distributor",
      rank_points: 0,
      region: "",
      rubby_count: 0,
      silver_count: 0,
      tanzanite_count: 0,
      upliner_collection: [
        {
        first_name: "A",
        id: "A67336SPA-FMFP",
        last_name: "null",
        middle_name: "null",
        profile: "profile_avatar.png",
        rank: "distributo"
        }
      ]
      }
    ],
      silver:[],
      gold:[],
      rubby:[],
      tanzanite:[],
      generation6:[],
      generation7:[],
      generation8:[],

  });


  
const [Profile, setProfile] = React.useState(
    {
      balance:0,
      clc: 0,
      cpv: 0,
      distributor_count:0,
      first_name: "",
      bank_account_fullname: "",
      bank_name: "",
      bank_number: "",
      fixed_balance:0,
      generation6_count:0,
      generation7_count: 0,
      gold_count: 0,
      id: "",
      last_name: "",
      loan_debt: 0,
      loan_threashold: 0,
      middle_name: "",
      phone_number1:"",
      phone_number2:"",
      profile:"profile_avatar.png",
      region_name:"",
      district_name:"",
      ward_name: "",
      rank: "",
      rubby_count: 0,
      silver_count: 10,
      tanzanite_count: 0,
      total_community: 120,
      upliner_collection: [
        //  {
        //   current_grade_points: 1,
        //   first_name: "Erradius",
        //   id: "A67336SPA-FMFP",
        //   last_name: "Petter",
        //   middle_name: "Julius",
        //   profile: "profile_avatar.png",
        //   rank: "distributor",
        //   total_community: 101,
        //   }
        ]
        
  });




  const [UplinerCurrentProfile, setUplinerCurrentProfile] = React.useState(
  {current_grade_points: 1,
    first_name: "Erradius",
    id: "A67336SPA-FMFP",
    last_name: "Petter",
    middle_name: "Julius",
    profile: "profile_avatar.png",
    rank: "distributor",
    total_community: 101,
  });




const {state} = Location

const [GenerationID, setGenerationID] = React.useState(()=>{

  if(state !==undefined && state !==null){

    return state.GenerationID
  }else{
    return null
  }

});




useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  if(GenerationID !==null){
    ServerFirstIndexedb_FetchUserProfile(server_ip,GenerationID,authentication_token).then(function(JsonResponse){
      const {status} = JsonResponse
      setServerResponse(status)
    
      console.log(JsonResponse)
      if(status===200){
       
       const {Jsondata} = JsonResponse
    
       //setuserGenerationList({...UserGenerationList,[currentTab]:Jsondata})

       setProfile(Jsondata)
    
      }else if(status > 200){
    
  
         NotificationActivate(`(SERVER ERROR:${status}) could not retrieve profile!`,`information`,true,false)
        // message,type,sound=true,disappear=true,duration=4*1000
    
      }
    
      }).catch(function(ErrorResponse){
    
        NotificationActivate('(application error) ! could not retrieve profile !','information',true,true)
      })
 

  }else{//NAVIGATE BACK

    History.replace({pathname:`/control/${AccountType}/users`})  

  }
 



 


return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[User])



const NavigateProfilePreview = (Profile)=>{

History.push({pathname:`/control/${AccountType}/user_profile`,state:{Profile:Profile}})


}





  
const UplinerProfileDisplayerMap = [0].map(function(object,index){
 var TotalCommunity = (object.distributor_count+object.silver_count+object.gold_count+object.silver_count+object.rubby_count+object.tanzanite_count+object.generation7_count+object.generation7_count+object.generation7_count)
  return(
     <React.Fragment key={index}>
          <Grid container spacing={1}>
          <Grid item xs={12}>
                 <div class="callout callout-success">
                 <Grid container spacing={1}>
             <Grid item xs={12} sm={2} className='generation-wrapper'>
             <div  className='generation-profile-card'><img src={UplinerCurrentProfile.profile} alt='profile 1 avatar'/><div className='from-generation-badge' style={{backgroundColor:`${themecolor1}`}}><span>{UplinerCurrentProfile.current_grade_points}</span></div> </div>
            </Grid>
  
            <Grid item xs={12} sm={2} className='generation-wrapper'>
              <span className='profile-preview-text'>{`${UplinerCurrentProfile.first_name} ${UplinerCurrentProfile.middle_name} ${UplinerCurrentProfile.last_name}`}</span>
            </Grid>
  
            <Grid item xs={12} sm={2} className='generation-wrapper'>
              <span className='profile-preview-text'>community  <b style={{color:`${themecolor1}`}}> {UplinerCurrentProfile.total_community}</b></span>
            </Grid>
   
            <Grid item xs={12} sm={2} className='generation-wrapper'>
              <span className='profile-preview-text'>
                Rank: {UplinerCurrentProfile.rank}
              </span>
            </Grid>
            
            <Grid item xs={12} sm={2} className='generation-wrapper'>
              <span className='profile-preview-text'>
                ID: {UplinerCurrentProfile.id}
              </span>
            </Grid>
           </Grid>
                </div>
               </Grid>

        </Grid>
        <br />
        
     </React.Fragment>
     )
})  








const GenerationListSkeleton = [0,1,2,3,4,5,6,7,8,9].map(function(object,index){
  return(
     <React.Fragment key={index}>
         <div className='generation-card'>
          <Grid container spacing={1}>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
              <div className='generation-skeleton-circle'>
              <Skeleton variant='circle' animation='pulse' style={{width:80,height:80}} /> 
              </div>
           </Grid>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
          </Grid>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
          </Grid>
 
          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"10%",height:30}} /> 
          </Grid>
         </Grid>
        </div>
        <br />
     </React.Fragment>
     )
})  



const UplinerListProfileTabMap = Profile.upliner_collection.map(function(object,index){

  return(
       <Tab key={index} label={`${object.first_name} ${object.middle_name}`} icon={<div className='upliner-profile-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={object.profile} alt='distributor'/> </div> } {...a11yProps(index)} />
       )
}) 





//GENERATION TABLE CLICK CHANGES
const handleChange = (event, newValue)=>{
  setValue(newValue);
  setUplinerCurrentProfile(Profile.upliner_collection[newValue])

};







const TESTFETCH =()=>{
ServerFirstIndexedb_FetchUserProfile(server_ip,'A50061-FMFP',authentication_token).then(function(JsonResponse){
    const {status} = JsonResponse
    setServerResponse(status)
  
    console.log(JsonResponse)
    if(status===200){
     
     const {Jsondata} = JsonResponse
  
     //setuserGenerationList({...UserGenerationList,[currentTab]:Jsondata})
  
    }else if(status > 200){
  
  
       alert(`server error: ${status}`)
  
    }
  
    }).catch(function(ErrorResponse){
  
      alert('application error')
    })
  
}



const ProfileSkeletonLoading =()=>{
return (
  <React.Fragment>
     <Grid container spacing={1}>
     <Grid item xs={12}>
       <div className='skeleton-upliner-profile-wrapper'>
       <Grid container spacing={0}>

       <Grid item xs={3}><Skeleton variant='circle' animation='pulse' style={{width:65,height:65}} className='upliner-profile-circle'/>  </Grid>
       <Grid item xs={3}><Skeleton variant='circle' animation='pulse' style={{width:65,height:65}} className='upliner-profile-circle'/>  </Grid>

       <Grid item xs={3}><Skeleton variant='circle' animation='pulse' style={{width:65,height:65}} className='upliner-profile-circle'/>  </Grid>

       <Grid item xs={3}><Skeleton variant='circle' animation='pulse' style={{width:65,height:65}} className='upliner-profile-circle'/>  </Grid>
        
        </Grid> 
       </div>
       </Grid>



       <Grid item xs={12}>
         <div class="callout callout-success">
            <Grid container spacing={1}>
             <Grid xs={12} sm={3} className='generation-wrapper'>
                
              <Grid item xs={3}><Skeleton variant='circle' animation='pulse' style={{width:65,height:65}}/>  </Grid>
    
            </Grid>
  
            <Grid item xs={12} sm={3} className='upliner-profile-text'>
           
            <Grid item xs={3}><Skeleton variant='text' animation='pulse' style={{width:65,height:20}}/>  </Grid>
     
            </Grid>
  
            <Grid item xs={12} sm={3} className='upliner-profile-text'>
            <Grid item xs={3}><Skeleton variant='text' animation='pulse' style={{width:65,height:20}}/>  </Grid>
             </Grid>
   
            <Grid item xs={12} sm={3} className='upliner-profile-text'>
            <Grid item xs={3}><Skeleton variant='text' animation='pulse' style={{width:65,height:20}}/>  </Grid>
            </Grid>
            
            <Grid item xs={12} sm={3}className='upliner-profile-text'>
            <Grid item xs={3}><Skeleton variant='text' animation='pulse' style={{width:65,height:20}}/>  </Grid>
            </Grid>
           </Grid>
            </div>
       </Grid>


      
       <Grid item xs={12}>
           <div class="personal-profile-wrapper">

                <div className='personal-profile-circle'>
                <Skeleton variant='circle' animation='pulse' style={{width:120,height:120}}/> 

                </div>
        
            </div>
       </Grid>

       <Grid item xs={12}>
         <Skeleton variant='text' animation='pulse' style={{width:'100%',height:60}} />  
         <br />
         <Skeleton variant='text' animation='pulse' style={{width:'100%',height:60}} />  
         <br/>
         <Skeleton variant='text' animation='pulse' style={{width:'100%',height:60}} />  
       </Grid>



     </Grid>
    </React.Fragment>
)
}






return (   //--------RETURN HTML---------------------------
<Fragment>
  {ServerResponse===200?
  <div className="geneology-wrapper">
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <div className={classes.root}>
        <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          className='upliner-profile-tab-wrapper'
          
        > 
          {/* <Tab label={`D (${distributor_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={distributor_badge} alt='distributor'/> </div> } {...a11yProps(0)} />
          <Tab label={`SILVER (${silver_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={silver_badge} alt='silver'/> </div>} {...a11yProps(1)} />
          <Tab label={`GOLD (${gold_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={gold_badge} alt='sdistributor'/> </div>} {...a11yProps(2)} />
          <Tab label={`RUBY (${rubby_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={ruby_badge} alt='rubby'/> </div>} {...a11yProps(3)} />
          <Tab label={`TANZANITE (${tanzanite_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={tanzanite_badge} alt='tanzanite'/> </div>} {...a11yProps(4)} />
          <Tab label={`G 6 (${generation6_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={grade_5_badge} alt='grade 5'/> </div>} {...a11yProps(5)} />
          <Tab label={`G 7 (${generation7_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}> <img src={grade_6_badge} alt='g 6'/></div>} {...a11yProps(6)} />
          <Tab label={`G 8 (${generation8_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={grade_7_badge} alt='grade 7'/> </div>} {...a11yProps(7)} />
         */}

         {UplinerListProfileTabMap}
        
        </Tabs>
      </AppBar>

      {/*---- DISTRIBUTOR TAB DISPLAYER ----*/}
      <TabPanel value={value} index={0}>
        {/* <button onClick={TESTFETCH}>TEST FUNCTION</button> */}

         

        
        {UplinerProfileDisplayerMap}
      

  
      </TabPanel>

      {/*---- SILVER TAB DISPLAYER ----*/}
      <TabPanel value={value} index={1}>

      {UplinerProfileDisplayerMap}

      </TabPanel>

        {/*---- GOLD TAB DISPLAYER ----*/}
      <TabPanel value={value} index={2}>

         {UplinerProfileDisplayerMap}
 
      </TabPanel>

      {/*---- RUBBY TAB DISPLAYER ----*/}
      <TabPanel value={value} index={3}>
         
      {UplinerProfileDisplayerMap}

      </TabPanel>

      {/*---- TANZANITE TAB DISPLAYER ----*/}
      <TabPanel value={value} index={4}>
        
         {UplinerProfileDisplayerMap}
      

      </TabPanel>


      {/*---- GENERATION5 TAB DISPLAYER ----*/}
      <TabPanel value={value} index={5}>
        
         {UplinerProfileDisplayerMap}
    

      </TabPanel>
      <TabPanel value={value} index={6}>

         {UplinerProfileDisplayerMap}


      </TabPanel>
      <TabPanel value={value} index={7}>
           
        {UplinerProfileDisplayerMap}
   

      </TabPanel>
    </div>
     </Grid>


            {/* ----PERSONAL PROFILE CARD START------- */}
            <Grid item xs={12}>
       <div className="card card-widget widget-user" id='personal-profile-card'>
  {/* Add the bg color to the header using any of the bg-* classes */}
  <div className="widget-user-header" style={{backgroundColor:`${themecolor1}`}}>
    <h3 className="widget-user-username">{`${Profile.first_name} ${Profile.middle_name}${Profile.last_name}`} </h3>
    <h5 className="widget-user-desc">{Profile.rank.toLocaleUpperCase()}</h5>
  </div>
  <div className="widget-user-image">
    <img className="img-circle elevation-2" src={Profile.profile} alt="User Avatar" style={{width:115,height:115}}/>
            
          <span className='account-status-badge'>ID: {Profile.id}</span>
           <div className='badge bg-success account-status-badge'>active</div>

  </div>
  <br />
  <br />
  <div className="card-footer profile-card">
    <div className="row">
     <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">BALANCE</h5>
          <span className="description-text">{CurrencyFormat(Profile.balance)}</span>
        </div>
        {/* /.description-block */}
      </div>
      {/* /.col */}
      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">LOAN STATUS</h5>
          <span className="description-text"> {CurrencyFormat(Profile.loan_debt)} </span>
        </div>
        {/* /.description-block */}
      </div>
      {/* /.col */}
      <div className="col-sm-2">
        <div className="description-block border-right">
          <h5 className="description-header">CPV</h5>
          <span className="description-text">{Profile.cpv} </span>
        </div>
        {/* /.description-block */}
      </div>

      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">CLC</h5>
          <span className="description-text">{Profile.clc}</span>
        </div>
        {/* /.description-block */}
      </div>


      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">TOTAL COMMUNITY</h5>
          <span className="description-text">{Profile.total_community}</span>
        </div>
        {/* /.description-block */}
      </div>

      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">FIXED BALANCE</h5>
          <span className="description-text badge bg-success">{CurrencyFormat(Profile.fixed_balance)}</span>
        </div>
        {/* /.description-block */}
      </div>





      {/* /.col */}
    </div>
    {/* /.row */}


    <div className="row" id='profile-downliner-points-wrapper'>
      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">DISTRIBUTOR</h5>
          <span className="description-text">{Profile.distributor_count}</span>
        </div>
        {/* /.description-block */}
      </div>
      {/* /.col */}
      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">SILVER</h5>
          <span className="description-text"> {Profile.silver_count} </span>
        </div>
        {/* /.description-block */}
      </div>
      {/* /.col */}
      <div className="col-sm-2">
        <div className="description-block border-right">
          <h5 className="description-header">GOLD</h5>
          <span className="description-text">{Profile.gold_count}</span>
        </div>
        {/* /.description-block */}
      </div>

      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">TANZANITE</h5>
          <span className="description-text"> {Profile.tanzanite_count}</span>
        </div>
        {/* /.description-block */}
      </div>


      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">GENERATION6</h5>
          <span className="description-text">{Profile.generation6_count}</span>
        </div>
        {/* /.description-block */}
      </div>

      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">GENERATION7</h5>
          <span className="description-text">{Profile.generation7_count}</span>
        </div>
        {/* /.description-block */}
      </div>





      {/* /.col */}
    </div>
    {/* /.row */}


    
    <div className="row" id='profile-downliner-points-wrapper'>
      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">PHONE 1</h5>
          <span className="description-text">{Profile.phone_number1}</span>
        </div>
        {/* /.description-block */}
      </div>
      {/* /.col */}
      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">PHONE 2</h5>
          <span className="description-text"> {Profile.phone_number2} </span>
        </div>
        {/* /.description-block */}
      </div>
      {/* /.col */}
      <div className="col-sm-2">
        <div className="description-block border-right">
          <h5 className="description-header">REGION</h5>
          <span className="description-text">{Profile.region_name}</span>
        </div>
        {/* /.description-block */}
      </div>

      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">DISTRICT</h5>
          <span className="description-text">{Profile.district_name}</span>
        </div>
        {/* /.description-block */}
      </div>


      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">WARD</h5>
          <span className="description-text">{Profile.ward_name}</span>
        </div>
        {/* /.description-block */}
      </div>

      {/* <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">GENERATION7</h5>
          <span className="description-text">{Profile.generation7_count}</span>
        </div>
       </div> */}





      {/* /.col */}
    </div>
    {/* /.row */}

    <div className="row" id='profile-downliner-points-wrapper'>
      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">BANK NAME</h5>
          <span className="description-text">{Profile.bank_name}</span>
        </div>
        {/* /.description-block */}
      </div>
      {/* /.col */}
      <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">BANK  FULL NAME</h5>
          <span className="description-text"> {Profile.bank_account_fullname} </span>
        </div>
        {/* /.description-block */}
      </div>
      {/* /.col */}
      <div className="col-sm-2">
        <div className="description-block border-right">
          <h5 className="description-header">BANK NUMBER</h5>
          <span className="description-text">{Profile.bank_number}</span>
        </div>
        {/* /.description-block */}
      </div>

      {/* <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">DISTRICT</h5>
          <span className="description-text">{Profile.district_name}</span>
        </div>
      </div> */}


      {/* <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">WARD</h5>
          <span className="description-text">{Profile.ward_name}</span>
        </div>
      </div> */}

      {/* <div className="col-sm-2 border-right">
        <div className="description-block">
          <h5 className="description-header">GENERATION7</h5>
          <span className="description-text">{Profile.generation7_count}</span>
        </div>
       </div> */}





      {/* /.col */}
    </div>
    {/* /.row */}



    
      <Grid container spacing={1} className='profile-action-button-wrapper'> 
      <Grid item xs={12} sm={6} className='profile-action-button'>
      <ButtonGroup fullWidth={true} size='small' variant="contained" color="primary" aria-label="contained primary button group">
        <Button  onClick={TESTFETCH} endIcon={<Lock style={{color:'#fff',fontSize:25}} />}><span>LOCK ACCOUNT</span></Button>
        <Button  endIcon={<LockOpen style={{color:'#fff',fontSize:25}} />}><span>UNLOCK ACCOUNT</span></Button>
        <Button endIcon={<Delete style={{color:'red',fontSize:25}} />}><span>DELETE  ACCOUNT</span></Button>
      </ButtonGroup>
    </Grid>
  </Grid>



  </div>
</div>

           </Grid>
        </Grid>


        <NavigatioButtons />
    

</div>
  :<ProfileSkeletonLoading />}
</Fragment>
 
    
);


// UserProfilePreview.propTypes = {}
        
// UserProfilePreview.defaultProps ={}


}
export {UserProfilePreview};

