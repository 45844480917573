import React ,{Fragment,useEffect,useContext}from 'react';
import {useLocation,useHistory} from "react-router-dom";
import {Link,NavLink} from "react-router-dom";
import './SettingCenter.scss';
import Grid from '@material-ui/core/Grid';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {AddAlertRounded,SettingsApplications,Money,AddAlert,AccountCircle,GroupAdd,Settings,SettingsPower,
  Dashboard,PeopleAlt,AccountBalance,PersonAdd,Business,SimCard} from '@material-ui/icons';


    
const SettingCenter = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User} = Appstate
  const {CurrencyFormat} = Functions
   const {themecolor1,themecolor2,AccountType} = Appsettings

  //const classes = useStyles();


  const History = useHistory()

const [DashboardInfor, setDashboardInfor] = React.useState({
  title:'',
  description:'',
  icon:'',
});



useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  





return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])












return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="setting-center">

<div className="list-group">


  <Link to={`#`} className="list-group-item list-group-item-action" aria-current="true">
    <Business style={{color:`${themecolor1}`,fontSize:30}}/>  BUSINESS PROFILE
  </Link>

  <Link to={`/control/${AccountType}/settings/vouchers`} className="list-group-item list-group-item-action"  aria-current="true">
    <SimCard style={{color:`${themecolor1}`,fontSize:30}}/>  VOUCHER PIN GENERATION
  </Link>

  {/* <Link to={`/control/${AccountType}/settings/add_product_stock`} className="list-group-item list-group-item-action"  aria-current="true">
    <SimCard style={{color:`${themecolor1}`,fontSize:30}}/>  ADD PRODUCT STOCK
  </Link> */}

  <Link to={`/control/${AccountType}/settings/add_product_sells`} className="list-group-item list-group-item-action"  aria-current="true">
    <SimCard style={{color:`${themecolor1}`,fontSize:30}}/>  ADD PRODUCT SELLS
  </Link>

  <Link to={`/control/${AccountType}/settings/add_supperuser_account`} className="list-group-item list-group-item-action"  aria-current="true">
    <PersonAdd style={{color:`${themecolor1}`,fontSize:30}}/>  ADD SUPPER USER ACCOUNT
  </Link>



</div>


</div>
</Fragment>
 
    
);


// SettingCenter.propTypes = {}
        
// SettingCenter.defaultProps ={}


}
export {SettingCenter};

