import React ,{Fragment,useEffect,useContext}from 'react';
import {useParams,useHistory,Link} from "react-router-dom";
import './InboxNotification.scss';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import {InboxSideMenu} from "./InboxSideMenu";
import {MenuOpen} from '@material-ui/icons';
import {LocalAtm,Email,AddAlert} from '@material-ui/icons';
import {ServerFirstIndexedb_FetchInboxMessages} from "../../HttpcallUserApp";
import Skeleton from '@material-ui/lab/Skeleton';





const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  InboxSidebarMenu:{
    [theme.breakpoints.down('sm')]:{
      display:'none'
     },
     [theme.breakpoints.up('md')]:{
      display:'inline'
       
     } 
  },
}));



    
const InboxNotification = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,App} = Appstate
  const {InboxsidemenuTrigger} = Functions

  
  const {themecolor1,themecolor2} = Appsettings

  const {server_ip,authentication_token} = App

  const urlParameter = useParams()
 
  const InboxType = urlParameter.InboxType=='system_notification' || urlParameter.InboxType==='transactional_message' || urlParameter.InboxType==='personal_message'?urlParameter.InboxType:'system_notification'


  const classes = useStyles();

  const History = useHistory()

  
  const [ServerResponse, setServerResponse] = React.useState(null);


  const [NotificationTitle, setNotificationTitle] = React.useState('SYSTEM NOTIFICATIONS');


const [NotificationType, setNotificationType] = React.useState('system_notification');
//valid inbox parameter
// 1:system_notification
//2:transactional_message
//2:personal_message


// system_notification:[
//   {
//    date_created: "",
//    id: 139,
//    message: "",
//    new_message: 1,
//    sender: "Femsworldtz",
//    subject: "",
//    subtitle: "",
//    user_id: ""
//  },
// ],
// transactional_message:[{
//  amount: 10000,
//  date_created: "2021-06-08T12:26:47.844",
//  downliner_fullname: "Hanskannah joel",
//  downliner_id: "A68388-FMFP",
//  first_name: "Jackline",
//  from_generation: 2,
//  subtitle: "",
//  id: 157,
//  last_name: "Putri",
//  message: "confirmed 255T7F17C0336AFMFP you have received 10000.0 from your 2 generation member ,  your new balance is TSH . 280000.0",
//  middle_name: "sanjay",
//  new_message: 1,
//  sender: "Femsworldtz",
//  subtitle: "confirmed 255T7F17C0336AFMFP you have received 10000.0 from",
//  transaction_code: "255T7F17C0336AFMFP",
//  user_id: "Japhetmichael@gmail.com"
// }],



const [MessageInstance, setMessageInstance] = React.useState({
  system_notification:[],
  transactional_message:[],
  personal_message:[]
});


useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  
if(InboxType==='system_notification' || InboxType==='transactional_message' || InboxType==='personal_message'){

  if(NotificationType !==InboxType){
    setNotificationType(InboxType)
  }

  
  if(InboxType==='system_notification'){
    setNotificationTitle('SYSTEM NOTIFICATIONS')


  }
  if(InboxType==='transactional_message'){

    setNotificationTitle('TRANSACTIONAL MESSAGES')
  }
  if(InboxType==='personal_message'){

    setNotificationTitle('PERSONAL MESSAGES')
  }


  setServerResponse(null)//allow loading indication
   ServerFirstIndexedb_FetchInboxMessages(server_ip,InboxType,0,10,authentication_token).then(function(JsonResponse){
     const {status} = JsonResponse
     setServerResponse(status)
     
     if(status===200){
       const {Jsondata} = JsonResponse

      setMessageInstance({...MessageInstance,[InboxType]:Jsondata})



     }
   console.log(JsonResponse)


  }).catch(function(ErrorResponse){

    setServerResponse('offline')
  })





}



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo



//sauti solo am addicted to you ,lift me up 
   

}
},[InboxType])




const MessageReadNavigate = (MessageObject)=>{

  ///account/inbox/reads/:MessageType
    // console.log(MessageObject)

    History.replace({pathname:`/account/inbox/reads/${InboxType}`,state:{MessageInstance:MessageObject}})  
  
}




const InboxMessageDisplayerMap = MessageInstance[InboxType] .map(function(object,index){
return(
  <React.Fragment key={index}>
  <tr >
   <td>
    <div className="icheck-primary">
    <input type="checkbox" defaultValue id={`check${index}`} />
    <label htmlFor={`check${index}`}  />
    </div>
  </td>
  <td>
    {object.new_message===1?
    <div className='new-message-badge' style={{backgroundColor:`${themecolor2}`}}><span>New</span></div>
    :null}
   </td>
   <td className="mailbox-star"><Link to="#"><i className="fas fa-star text-warning" /></Link></td>
   <td onClick={()=>{MessageReadNavigate(object)}} className="mailbox-name"><Link to="#">{object.sender} </Link> </td>
   <td onClick={()=>{MessageReadNavigate(object)}} className="mailbox-subject"><b>{object.subtitle.slice(0,40)}</b> - {object.message.slice(0,20)}...
   </td>
   <td className="mailbox-attachment" />
    {/* <td className="mailbox-date">5 mins ago</td> */}
</tr> 
</React.Fragment>
)
})  





const SkeletonInboxMessageDisplayerMap = [0,1,2,3,4,5].map(function(object,index){
  return(
    <React.Fragment key={index}>
    <tr onClick={()=>{MessageReadNavigate(object)}}>
     <td>
     <Skeleton variant='rect' animation='pulse' style={{width:100,height:40}} /> 
    </td>
    <td>
    <Skeleton variant='rect' animation='pulse' style={{width:100,height:40}} /> 
     </td>
     <td className="mailbox-star"> <Skeleton variant='rect' animation='pulse' style={{width:100,height:40}} /> </td>
     <td className="mailbox-name"> <Skeleton variant='rect' animation='pulse' style={{width:100,height:40}} /> </td>
     <td className="mailbox-subject"> <Skeleton variant='rect' animation='pulse' style={{width:100,height:40}} />  </td>
     <td className="mailbox-attachment" />
      {/* <td className="mailbox-date">5 mins ago</td> */}
  </tr> 
  </React.Fragment>
  )
  })  




const EmptyMessageDisplayer = ()=>{
    return(
      <React.Fragment>
        <tr>
     <td>
    <div className="no-message-card">
       <h6>NO NEW MESSAGE !</h6>
  </div>
  </td>

</tr>
</React.Fragment>
)
}
    
  
  


  
  











return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="inbox-notification-wrapper">

    
<Grid container spacing={1}>


<Grid item xs={12} sm={3} className={classes.InboxSidebarMenu}>

   <InboxSideMenu />
 
</Grid>
{/* ---------SIDE BAR INBOX COLUMN ---------------*/}

{/* ---------INBOX COLUMN ---------------*/}
<Grid item xs={12} md={9}>
<div onClick={()=>{InboxsidemenuTrigger()}} className='inbox-sidebar-menu-button' style={{backgroundColor:`${themecolor1}`}}><MenuOpen fontSize='large' style={{color:'#fff'}}/></div>

<div className="card card-primary card-outline">
  <div className="card-header">
    <div className='inbox-notification-type' style={{backgroundColor:`${themecolor1}`}}>
       <div className='notification-type-circle'>  
        {NotificationType==='system_notification'?<AddAlert fontSize='large' style={{color:`${themecolor1}`}}/>:null}
        {NotificationType==='transactional_message'?<LocalAtm fontSize='large' style={{color:`${themecolor1}`}}/>:null}
        {NotificationType==='personal_message'?<Email fontSize='large' style={{color:`${themecolor1}`}}/>:null}
        
       </div>
      <span className='inbox-message-title'>{NotificationTitle}</span>
    </div>
    <h3 className="card-title">Inbox</h3>
    <div className="card-tools">
      <div className="input-group input-group-sm">
        <div className="input-group-append">
          <div className="btn btn-primary">
            <i className="fas fa-search" />
          </div>
        </div>
      </div>
    </div>
    {/* /.card-tools */}
  </div>
  {/* /.card-header */}
  <div className="card-body p-0">
    <div className="mailbox-controls">
      {/* Check all button */}
      <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="far fa-square" />
      </button>
      <div className="btn-group">
        <button type="button" className="btn btn-default btn-sm">
          <i className="far fa-trash-alt" />
        </button>
        <button type="button" className="btn btn-default btn-sm">
          <i className="fas fa-reply" />
        </button>
        <button type="button" className="btn btn-default btn-sm">
          <i className="fas fa-share" />
        </button>
      </div>
      {/* /.btn-group */}
      <button type="button" className="btn btn-default btn-sm">
        <i className="fas fa-sync-alt" />
      </button>
      <div className="float-right">
        1-50/200
        <div className="btn-group">
          <button type="button" className="btn btn-default btn-sm">
            <i className="fas fa-chevron-left" />
          </button>
          <button type="button" className="btn btn-default btn-sm">
            <i className="fas fa-chevron-right" />
          </button>
        </div>
        {/* /.btn-group */}
      </div>
      {/* /.float-right */}
    </div>
    <div className="table-responsive mailbox-messages">

      <table className="table table-hover table-striped">
        <tbody>

        {InboxMessageDisplayerMap}

        {ServerResponse===null?SkeletonInboxMessageDisplayerMap:null}

           
         {MessageInstance[InboxType].length===0 && ServerResponse===200?<EmptyMessageDisplayer />:null}  
        
{/* 
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check2" />
                <label htmlFor="check2" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star-o text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment"><i className="fas fa-paperclip" /></td>
            <td className="mailbox-date">28 mins ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check3" />
                <label htmlFor="check3" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star-o text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment"><i className="fas fa-paperclip" /></td>
            <td className="mailbox-date">11 hours ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check4" />
                <label htmlFor="check4" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment" />
            <td className="mailbox-date">15 hours ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check5" />
                <label htmlFor="check5" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment"><i className="fas fa-paperclip" /></td>
            <td className="mailbox-date">Yesterday</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check6" />
                <label htmlFor="check6" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star-o text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment"><i className="fas fa-paperclip" /></td>
            <td className="mailbox-date">2 days ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check7" />
                <label htmlFor="check7" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star-o text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment"><i className="fas fa-paperclip" /></td>
            <td className="mailbox-date">2 days ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check8" />
                <label htmlFor="check8" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment" />
            <td className="mailbox-date">2 days ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check9" />
                <label htmlFor="check9" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment" />
            <td className="mailbox-date">2 days ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check10" />
                <label htmlFor="check10" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star-o text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment" />
            <td className="mailbox-date">2 days ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check11" />
                <label htmlFor="check11" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star-o text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment"><i className="fas fa-paperclip" /></td>
            <td className="mailbox-date">4 days ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check12" />
                <label htmlFor="check12" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment" />
            <td className="mailbox-date">12 days ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check13" />
                <label htmlFor="check13" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star-o text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment"><i className="fas fa-paperclip" /></td>
            <td className="mailbox-date">12 days ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check14" />
                <label htmlFor="check14" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment"><i className="fas fa-paperclip" /></td>
            <td className="mailbox-date">14 days ago</td>
          </tr>
          <tr>
            <td>
              <div className="icheck-primary">
                <input type="checkbox" defaultValue id="check15" />
                <label htmlFor="check15" />
              </div>
            </td>
            <td className="mailbox-star"><a href="#none"><i className="fas fa-star text-warning" /></a></td>
            <td className="mailbox-name"><a href="read-mail.html">Alexander Pierce</a></td>
            <td className="mailbox-subject"><b>AdminLTE 3.0 Issue</b> - Trying to find a solution to this problem...
            </td>
            <td className="mailbox-attachment"><i className="fas fa-paperclip" /></td>
            <td className="mailbox-date">15 days ago</td>
          </tr>
        */}
       
       
        </tbody>
      </table>
      {/* /.table */}



      
    </div>
    {/* /.mail-box-messages */}
  </div>
  {/* /.card-body */}
  <div className="card-footer p-0">
    <div className="mailbox-controls">
      {/* Check all button */}
      <button type="button" className="btn btn-default btn-sm checkbox-toggle">
        <i className="far fa-square" />
      </button>
      <div className="btn-group">
        <button type="button" className="btn btn-default btn-sm">
          <i className="far fa-trash-alt" />
        </button>
        <button type="button" className="btn btn-default btn-sm">
          <i className="fas fa-reply" />
        </button>
        <button type="button" className="btn btn-default btn-sm">
          <i className="fas fa-share" />
        </button>
      </div>
      {/* /.btn-group */}
      <button type="button" className="btn btn-default btn-sm">
        <i className="fas fa-sync-alt" />
      </button>
      <div className="float-right">
        1-50/200
        <div className="btn-group">
          <button type="button" className="btn btn-default btn-sm">
            <i className="fas fa-chevron-left" />
          </button>
          <button type="button" className="btn btn-default btn-sm">
            <i className="fas fa-chevron-right" />
          </button>
        </div>
        {/* /.btn-group */}
      </div>
      {/* /.float-right */}
    </div>
  </div>
</div>




</Grid>




</Grid>



</div>
</Fragment>
 
    
);


// InboxNotification.propTypes = {}
        
// InboxNotification.defaultProps ={}


}
export {InboxNotification};

