import styled from 'styled-components';


const DashboardCard = styled.div`
width: 100%;
display: flex;
flex-direction: row;
`

const DashboardCardLogo = styled.div`
background-color:${props =>props.bgcolor?props.bgcolor:'#2B1B17'};
flex-grow: 1;
display: flex;
justify-content: center; 
align-items: center;
border-bottom-left-radius: 10px;
border-top-left-radius: 10px;
`

const DashboardCardCircle = styled.div`
background-color:#fff;
width: 80px;
height: 80px; 
border-radius: 50%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`

const DashboardCardText = styled.div`
background-color:${props =>props.bgcolor?props.bgcolor:'#2B1B17'};
display: flex;
width:60%;
flex-grow: 5; 
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
border:3px solid ${props =>props.boldercolor?props.boldercolor:'#2B1B17'};
`





export {DashboardCard,DashboardCardCircle,DashboardCardLogo,DashboardCardText}