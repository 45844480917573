import React ,{Fragment,useEffect,useContext}from 'react';
import './AddProductSells.scss';
import Grid from '@material-ui/core/Grid';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
//import transaction_withdraw from "./transaction_withdraw.svg";
import balance_icon from './balance_icon.svg';
import clc_points_icon from './clc_points_icon.svg';
import cpv_points_icon from "./cpv_points_icon.svg";
import network_icon from "./network_icon.svg";
//import {ArrowRightAlt} from '@material-ui/icons';
import {AddProductSellsCard,AddProductSellsCardCircle,AddProductSellsCardLogo,AddProductSellsCardText} from "../StyledComponents/styledcomponents";
import {ScaleLoader,ClipLoader} from "react-spinners";
import {Card,CardContent,Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {HtttSearchUserCommisionFunction,ServerFirstIndexedb_FetchProductList,
  ServerFirstIndexedb_AddProductPurchase,HtttSearchUserRankInformation} from "../../HttpcallUserApp";

import {CheckCircle,Cancel} from '@material-ui/icons';


    
const AddProductSells = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User,App} = Appstate
  const {CurrencyFormat} = Functions
  const {themecolor1,themecolor2} = Appsettings
  const {authentication_token,server_ip} = App

  //const classes = useStyles();


  const [UserID, setUserID] = React.useState('');

  const [ServerStatusCode, setServerStatusCode] = React.useState(null);
  const [ServerResponseMessage, setServerResponseMessage] = React.useState(null);

  const [ServerOperationStatus, setServerOperationStatus] = React.useState(null);

  const [ServerLoading, setServerLoading] = React.useState(false);

  const [AddProductLoading , setAddProductLoading] = React.useState(false);

  const [FetchProductLoading, setFetchProductLoading] = React.useState(false);






  const [SERVER_LOADING , SET_SERVER_LOADING] = React.useState(false);

  const [SERVER_STATUS, SET_SERVER_STATUS] = React.useState(null);

  const [SERVER_MESSAGE, SET_SERVER_MESSAGE] = React.useState(null);


  const [SEARCH_USER_RESPONSE, SET_SEARCH_USER_RESPONSE] = React.useState({
       server_status:null,
       server_message:null
  });

  const [SEARCH_PRODUCT_RESPONSE, SET_SEARCH_PRODUCT_RESPONSE] = React.useState({
    server_status:null,
    server_message:null
});






  const [FormValidation, setFormValidation] = React.useState({
    UserID_NOTEXIST:false,
    EmptyUSERID:false

  });

  const [ServerResponse, setServerResponse] = React.useState({
    ADD_PURCHASES_server_status:null,
    ADD_PURCHASES_server_message:null,
    ADD_PURCHASES_operation_status:null,
    

  });









const [PRODUCT_STOCK_LIST, SET_PRODUCT_STOCK_LIST] = React.useState(
{
  //  1:{
  //       product_code:"ITEM08D8CC0520F1AAC7",
  //       product_id:'1',
  //       product_name:"NALOF NATURAL HERBAL",
  //       coverphoto:"",
  //       price:30000,
  
  //       total_ppc_quantity:1,
  //       distributor_ppc_value:1000,
  //       tanzanite_ppc_value:3000,
  //       silver_ppc_value:1600,
  //       rubby_ppc_value:2400,
  //       grade6_ppc_value:0,
  //       grade7_ppc_value:0,
  //       grade8_ppc_value:0,
        
  
  //       total_lcp_quantity:0,
  //       distributor_lcp_value:0,
  //       silver_lcp_value:0,
  //       rubby_lcp_value:0,
  //       gold_lcp_value:0,
  //       tanzanite_lcp_value:0,
  //       grade6_lcp_value:0,
  //       grade7_lcp_value:0,
  //       grade8_lcp_value:0,
  
  //       total_rcp_quantity:0,
  //       distributor_rcp_value:0,
  //       silver_rcp_value:0,
  //       rubby_rcp_value:0,
  //       gold_rcp_value:0,
  //       tanzanite_rcp_value:3000,
  //       grade6_rcp_value:0,
  //       grade7_rcp_value:0,
  //       grade8_rcp_value:0
  //   },
  //   2:{
  //       product_code:"ITEM8D8CC0520F1AAC4",
  //       product_id:'2',
  //       product_name:"DETOX BODY UTERIZATION",
  //       coverphoto:"",
  //       price:30000,
  
  //       total_ppc_quantity:1,
  //       distributor_ppc_value:1000,
  //       tanzanite_ppc_value:3000,
  //       silver_ppc_value:1600,
  //       rubby_ppc_value:2400,
  //       grade6_ppc_value:0,
  //       grade7_ppc_value:0,
  //       grade8_ppc_value:0,
        
  
  //       total_lcp_quantity:0,
  //       distributor_lcp_value:0,
  //       silver_lcp_value:0,
  //       rubby_lcp_value:0,
  //       gold_lcp_value:0,
  //       tanzanite_lcp_value:0,
  //       grade6_lcp_value:0,
  //       grade7_lcp_value:0,
  //       grade8_lcp_value:0,
  
  //       total_rcp_quantity:0,
  //       distributor_rcp_value:0,
  //       silver_rcp_value:0,
  //       rubby_rcp_value:0,
  //       gold_rcp_value:0,
  //       tanzanite_rcp_value:3000,
  //       grade6_rcp_value:0,
  //       grade7_rcp_value:0,
  //       grade8_rcp_value:0
  //   }

});





const [SELECTED_PRODUCT, SET_SELECTED_PRODUCT] = React.useState(
   {
      product_code:"ITEM08D8CC0520F1AAC7",
      product_id:'1',
      product_name:"NALOF NATURAL HERBAL",
      coverphoto:"",
      price:30000,

      total_ppc_quantity:1,
      distributor_ppc_value:1000,
      tanzanite_ppc_value:3000,
      silver_ppc_value:1600,
      rubby_ppc_value:2400,
      grade6_ppc_value:0,
      grade7_ppc_value:0,
      grade8_ppc_value:0,
      

      total_lcp_quantity:0,
      distributor_lcp_value:0,
      silver_lcp_value:0,
      rubby_lcp_value:0,
      gold_lcp_value:0,
      tanzanite_lcp_value:0,
      grade6_lcp_value:0,
      grade7_lcp_value:0,
      grade8_lcp_value:0,

      total_rcp_quantity:0,
      distributor_rcp_value:0,
      silver_rcp_value:0,
      rubby_rcp_value:0,
      gold_rcp_value:0,
      tanzanite_rcp_value:3000,
      grade6_rcp_value:0,
      grade7_rcp_value:0,
      grade8_rcp_value:0
    },
);

const [SELECTED_PRODUCT_KEY, SET_SELECTED_PRODUCT_KEY] = React.useState('0')

const [USER_SEARCH_FOUND, SET_USER_SEARCH_FOUND] = React.useState(false)






const [PURCHASED_PACKAGE, SET_PURCHASED_PACKAGE] = React.useState(
   { 
    product_id:null,
    product_name:null,
    user_id:null,
    user_rank:null,
    user_full_name:null,
    last_name:null,
    product_name:null,

    sale_price:0,
    total_sale_price:0,
    purchased_quantity:1,

    total_ppc_quantity:0,
    total_ppc_value:0,

    total_lcp_quantity:0,
    total_lcp_value:0,

    total_rcp_quantity:0,
    total_rcp_value:0,

    

    

   }
);



const [PRODUCT_FIXED_VALUES, SET_PRODUCT_FIXED_VALUES] = React.useState(
  { 

   
   fixed_price:0,
   
   fixed_ppc_quantity:1,
   fixed_ppc_value:0,

   fixed_lcp_quantity:1,
   fixed_lcp_value:0,
   
   fixed_rcp_quantity:1, 
   fixed_rcp_value:0,

   

  }
);








const [SingleProductInformation, setSingleProductInformation] = React.useState({
  single_product_price:null,
  single_product_ppc_price: null,
  single_product_ppc_quantity: null,
  single_product_ppcprice_times_ppc_quantity:null,
  rank:null
});

var ResetSingleProductInformation ={
  single_product_price:null,
  single_product_ppc_price: null,
  single_product_ppc_quantity: null,
  single_product_ppcprice_times_ppc_quantity:null,
  rank:null
}


const [CurrentIndex, setCurrentIndex] = React.useState(null);

const [PurchaseQuantity, setPurchaseQuantity] = React.useState(1);










useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  
setFetchProductLoading(true)  
ServerFirstIndexedb_FetchProductList(server_ip,authentication_token).then(function(JsonResponse){
  setFetchProductLoading(false)
  const {status} = JsonResponse
  if(status===200){

    const {Jsondata} = JsonResponse

    SET_PRODUCT_STOCK_LIST(Jsondata)

    console.log(Jsondata)
 }
 
 }).catch(function(ErrorResponse){

    setFetchProductLoading(false)


 })



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])




const OnchangeUserID = (event) => {

   const {value} = event.target

   SET_PURCHASED_PACKAGE({...PURCHASED_PACKAGE,user_id:value})
   SET_USER_SEARCH_FOUND(false)
   SET_SEARCH_USER_RESPONSE({server_message:null,server_status:null})

     
};





const FormValidationFunction =()=>{
  return new Promise(function(resolve,reject){
    var JsonResponse = {status:200,message:null}

   if(PURCHASED_PACKAGE.user_id==null || JSON.stringify(PURCHASED_PACKAGE.user_id).length <= 5){
     
    JsonResponse['message'] = 'INPUT VALID USER ID FIRST'

    JsonResponse['status'] = 500

   }
   if(isNaN(PURCHASED_PACKAGE.purchased_quantity)){
     
    JsonResponse['message'] = 'PLEASE INPUT PURCHASED QUANTITY'

    JsonResponse['status'] = 500

   }

   return  resolve(JsonResponse)
  
})
}









const onchangeMinusPurchaseQuantity=(event)=>{
  const {value} = event.target
  if(!isNaN(value)){
    const PurchasedQuantity = parseInt(value)
   if(PurchasedQuantity !==0 && typeof(PURCHASED_PACKAGE.purchased_quantity)==='number'){
      SET_PURCHASED_PACKAGE({
        ...PURCHASED_PACKAGE,
        purchased_quantity:PurchasedQuantity,
        total_sale_price:(PRODUCT_FIXED_VALUES.fixed_price)*PurchasedQuantity,

        total_ppc_quantity:(PRODUCT_FIXED_VALUES.fixed_ppc_quantity)*PurchasedQuantity,
        total_ppc_value:(PRODUCT_FIXED_VALUES.fixed_ppc_value)*PurchasedQuantity,

        total_lcp_quantity:(PRODUCT_FIXED_VALUES.fixed_lcp_quantity)*PurchasedQuantity,
        total_rcp_quantity:(PRODUCT_FIXED_VALUES.fixed_rcp_quantity)*PurchasedQuantity,

        total_lcp_value:(PRODUCT_FIXED_VALUES.fixed_lcp_value)*PurchasedQuantity,
        total_rcp_value:(PRODUCT_FIXED_VALUES.fixed_rcp_value)*PurchasedQuantity

      })
    }

  }

   
};




const ProductStockListMap = Object.entries(PRODUCT_STOCK_LIST).map(function ([key,object],index){
  return(
  <Fragment key={index}>
     <option  style={{fontSize: 15}} value={key}>{object.product_name} </option>
   </Fragment>
  )})
  










const AddPurchaseFunction = ()=>{
  FormValidationFunction().then(function(JsonResponse){
    const {status,message} = JsonResponse
    if(status !==200){
      const {message} = JsonResponse
      alert(message)
    }
    if(status===200){

      SET_SERVER_LOADING(true)
      SET_SERVER_STATUS(null)
      console.log(PURCHASED_PACKAGE)
      ServerFirstIndexedb_AddProductPurchase(server_ip,authentication_token,PURCHASED_PACKAGE).then(function(ServerResponse){   
       const {status} = ServerResponse
    
       const {Jsondata} = ServerResponse
    
       const {server_message} = Jsondata
       
       SET_SERVER_LOADING(false)
       SET_SERVER_STATUS(status)
       SET_SERVER_MESSAGE(server_message)
    
       console.log(ServerResponse)
    
    
      }).catch(function(ErrorResponse){
         setAddProductLoading(false)
         const {status} = ErrorResponse
         
         setServerResponse({...ServerResponse,
          ADD_PURCHASES_server_status:status
        })
    
    
        })
    }

  })



}



const SearchUserRankInformation =()=>{

  FormValidationFunction().then(function(JsonResponse){
   const {status,message} = JsonResponse
    if(status !==200){
      const {message} = JsonResponse
      alert(message)
    }    
    if(status===200){ //VALIDATION
      SET_SERVER_LOADING(true)
      HtttSearchUserRankInformation({
          server_ip:server_ip,
          authentication_token:authentication_token,
          user_id:PURCHASED_PACKAGE.user_id,
        }).then(function(JsonResponse){
          const {server_status,server_message} = JsonResponse
          SET_SERVER_LOADING(false)
          SET_SEARCH_USER_RESPONSE({server_message:server_message,server_status:server_status})
         
          if(server_status===200){
            
            const {data} = JsonResponse
            
            SET_PURCHASED_PACKAGE({...PURCHASED_PACKAGE,...data})  
            SET_USER_SEARCH_FOUND(true)

          }
       

      })

    }


  })

}


const OnchangeProductSelection = (event) => {

  const {value} = event.target

  var objectKey = value

  SET_SELECTED_PRODUCT_KEY(value)

  if(Object.keys(PRODUCT_STOCK_LIST).length > 0 && value!=='0'){

    const SELECTED_PURCHASE_PRODUCT = PRODUCT_STOCK_LIST[objectKey]

    SET_SELECTED_PRODUCT(SELECTED_PURCHASE_PRODUCT)

    const {product_name,coverphoto,price} = SELECTED_PURCHASE_PRODUCT


      if(PURCHASED_PACKAGE.user_id!=null){

        SET_SERVER_LOADING(true)
        SET_SEARCH_PRODUCT_RESPONSE({server_status:null,server_message:null})
        HtttSearchUserCommisionFunction({
            server_ip:server_ip,
            authentication_token:authentication_token,
            user_id:PURCHASED_PACKAGE.user_id,
            product_id:SELECTED_PURCHASE_PRODUCT.product_id
          }).then(function(JsonResponse){
            const {server_status,server_message} = JsonResponse
            SET_SERVER_LOADING(false)
            SET_SEARCH_PRODUCT_RESPONSE({server_status:server_status,server_message:server_message})
            
            if(server_status===200){


              
              const {data} = JsonResponse    
              
              
              SET_PURCHASED_PACKAGE({
                ...PURCHASED_PACKAGE,
                ...data,
                product_name:product_name,
                total_sale_price:price,
                sale_price:price,
                product_id:SELECTED_PURCHASE_PRODUCT.product_id,
                purchased_quantity:1 //RESET THIS VALUE SO USER CAN ADD AGAIN TO ALLOW RECULCULATIONS
               })  
              SET_PRODUCT_FIXED_VALUES({
                ...PRODUCT_FIXED_VALUES,
                fixed_ppc_value:data.total_ppc_value,
                fixed_lcp_value:data.total_lcp_value,
                fixed_rcp_value:data.total_rcp_value,
                fixed_ppc_quantity:data.total_ppc_quantity,
                fixed_lcp_quantity:data.total_lcp_quantity,
                fixed_rcp_quantity:data.total_rcp_quantity,
                fixed_price:price,
    
              })
    
            }
         
    
        })
      }


  }
 




};





return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="transaction-wrapper">
<Button onClick={()=>{console.log(PURCHASED_PACKAGE,SELECTED_PRODUCT_KEY)}}>CHECKCHECK</Button>
<Grid container spacing={1}>

<Grid item xs={12} md={6}>
<Card>
<CardContent>
          
          <Typography variant='body1'>ADD PRODUCT PURCHASES</Typography>
          <Button onClick={()=>{console.log(PURCHASED_PACKAGE)}}>CHECK DATA</Button>
           <Grid container spacing={1}>

           <Grid item xs={12}>
           <Grid container spacing={0}>

           <Grid item xs={6} sm={7}>
             <TextField 
                id="product_name_field" 
                label="USER ID" 
                variant='filled' 
                helperText=''
                color='primary'
                required
                onChange={OnchangeUserID}
                value={PURCHASED_PACKAGE.user_id}
                fullWidth
                size='small'
                disabled={ServerLoading==true?true:false}
             />
            </Grid>
            <Grid item xs={6} sm={5}>
               <Button style={{height:48}} onClick={SearchUserRankInformation} fullWidth disabled={ServerLoading==true?true:false}  variant='contained' color='primary'>
                  SEARCH USER
                 &nbsp; {USER_SEARCH_FOUND?<CheckCircle />:<Cancel />}
                
                </Button>
            </Grid>

            </Grid>
          </Grid>
           
          <Grid item xs={12}>
          {SEARCH_USER_RESPONSE.server_status > 200?
                <Alert severity="error" style={{width:'100%'}}>
                <AlertTitle>SERVER ERROR({SEARCH_USER_RESPONSE.server_status}) : {SEARCH_USER_RESPONSE.server_message}</AlertTitle>
              </Alert>
            :null}
          </Grid>
            
            
           <Grid item xs={12}>
           <FormControl size='small' variant="filled" fullWidth={true}>
             <InputLabel htmlFor="outlined-age-native-simple">SELECT PRODUCT</InputLabel>
              <Select
              native
              value={SELECTED_PRODUCT_KEY}
              onChange={OnchangeProductSelection}
              label="SELECT PRODUCT"
              inputProps={{
              name: 'age',
              id: 'outlined-age-native-simple',
              }}
              fullWidth
              disabled={USER_SEARCH_FOUND?false:true}
              > 
              <option  style={{fontSize: 15}} value='0'>SELECT PRODUCT </option>
              {ProductStockListMap}

              </Select>
           </FormControl>
            </Grid>

   
                
              <Divider />
                <br />

               

            <Grid item xs={12}>
           <Grid container spacing={0}>

           <Grid item xs={12}>
             <TextField 
                id="product_name_field" 
                label="PURCHASE QUANTITY" 
                variant='filled' 
                type='number'
                helperText=''
                color='primary'
                required
                onChange={onchangeMinusPurchaseQuantity}
                value={PURCHASED_PACKAGE.purchased_quantity}
                fullWidth
                size='small'
                disabled={ServerLoading==true?true:false}
             />
            </Grid>
            </Grid>
          </Grid>

     
    
            <div className='product-purchase-summary-wrapper'>
                 <span><b>FULLNAME : </b> {PURCHASED_PACKAGE.user_full_name}</span>
                 <Divider />
                 <span><b>RANK : </b>{PURCHASED_PACKAGE.user_rank}</span>
                 <Divider />
                 <span><b>PRODUCT NAME : </b> {PURCHASED_PACKAGE.product_name}</span>
                 <Divider />
                 <span><b>PURCHASES QUANTITY : </b>{PURCHASED_PACKAGE.purchased_quantity} </span>
                 <Divider />
                 <span><b>TOTAL PPC QUANTITY : </b>  {PURCHASED_PACKAGE.total_ppc_quantity} </span>
                 <Divider />
                 <span><b>TOTAL PPC VALUE : </b>  {PURCHASED_PACKAGE.total_ppc_value} </span>
                 <Divider />
                 <span><b>TOTAL LCP QUANTITY : </b>  {PURCHASED_PACKAGE.total_lcp_quantity} </span>
                 <Divider />
                 <span><b>TOTAL LCP VALUE : </b>  {PURCHASED_PACKAGE.total_lcp_value} </span>
                 <Divider />
                 <span><b>TOTAL RCP QUANTITY : </b>  {PURCHASED_PACKAGE.total_rcp_quantity} </span>
                 <Divider />
                 <span><b>TOTAL RCP VALUE : </b>  {PURCHASED_PACKAGE.total_rcp_value} </span>
                 <Divider />
                 <span><b>SALE PRICE : </b>{PURCHASED_PACKAGE.sale_price} </span>
                 <Divider />
                 <span><b>TOTAL PURCHASED PRICE : </b>{PURCHASED_PACKAGE.total_sale_price} </span>

                <div className='update-profile-loader-wrapper'>

                   <ScaleLoader speedMultiplier={2} loading={SERVER_LOADING===true?true:false} height={50} width={10} color={themecolor1} />
                 
                 </div>
        
              </div>
           
              
              {SERVER_STATUS===200?
              <Alert severity="success" style={{width:'95%'}}>
                <AlertTitle>Success</AlertTitle>
                  <span>PURCHASES ADDED SUCCESSFULLY   <br />{SERVER_MESSAGE}</span>
              </Alert>
               :null}


               {SEARCH_PRODUCT_RESPONSE.server_status !=null && SEARCH_PRODUCT_RESPONSE.server_status > 200?
                <Alert severity="error" style={{width:'95%'}}>
                 <AlertTitle>SERVER ERROR({SEARCH_PRODUCT_RESPONSE.server_status}) : {SEARCH_PRODUCT_RESPONSE.server_message}</AlertTitle>
              </Alert>
               :null}


              {SERVER_STATUS !=null && SERVER_STATUS > 200?
                <Alert severity="error" style={{width:'95%'}}>
                 <AlertTitle>SERVER ERROR({SERVER_STATUS}) : {SERVER_MESSAGE}</AlertTitle>
              </Alert>
               :null}

              <Button fullWidth onClick={AddPurchaseFunction} disabled={SERVER_LOADING===true?true:false} variant='contained' color='primary'>ADD PURCHASES</Button>
           


       </Grid>
  </CardContent>
</Card>
</Grid>






</Grid>

</div>
</Fragment>
 
    
);


// AddProductSells.propTypes = {}
        
// AddProductSells.defaultProps ={}


}
export {AddProductSells};

