import React ,{Fragment,useEffect,useContext}from 'react';
import {useLocation,useHistory} from "react-router-dom";
import PropTypes from 'prop-types';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import './Generations.scss';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import distributor_badge from './distributor_badge.svg';
import ruby_badge from './ruby_badge.svg';
import tanzanite_badge from './tanzanite_badge.svg';
import silver_badge from './silver_badge.svg';
import gold_badge from './gold_badge.svg';
import grade_5_badge from './grade_5_badge.svg';
import grade_6_badge from './grade_6_badge.svg';
import grade_7_badge from './grade_7_badge.svg';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Skeleton from '@material-ui/lab/Skeleton';
import {ArrowForwardIos} from '@material-ui/icons';
import {Typography} from '@material-ui/core';
import {ServerFirstIndexedb_FetchGeneration} from "../../HttpcallUserApp";
import {SearchUserField} from "../SearchUserField/SearchUserField";
import {UserInstanceCard} from "../../Subcomponents/UserInstanceCard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

    
const Generations = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
 // const Functions = MainState.Functions

  const {Appsettings,App,User} = Appstate
  //const {} = Functions

  const {server_ip,authentication_token} = App
  const {themecolor1,themecolor2,AccountType} = Appsettings

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [currentTab, setcurrentTab] = React.useState('distributor');

  const Location = useLocation()
  const History = useHistory()

const {generation_id,distributor_count,generation6_count,
  generation7_count,generation8_count,gold_count,rubby_count,silver_count,tanzanite_count} =User


const [ServerResponse, setServerResponse] = React.useState(null);



 const [PaginationInterval, setPaginationInterval] = React.useState(9);//python  slicing system 9 == > 10 from 0 - 9


 const [PaginationStart, setPaginationStart] = React.useState(0);

 const [DisplayResult, setDisplayResult] = React.useState(null);


 const [PaginationEnd, setPaginationEnd] = React.useState(PaginationInterval);//python slicing system 9 means 10




const [UserGenerationList, setuserGenerationList] = React.useState({
    distributor:[
    //   {
    //   current_grade_points:3,
    //   first_name: "hunderson",
    //   id: "A79418-FMFP",
    //   last_name: "michael",
    //   middle_name: "challes",
    //   profile: "profile_avatar.png",
    //   rank: "distributor",
    //   total_community: 12
    //  }
    ],
      silver:[],
      gold:[],
      rubby:[],
      tanzanite:[],
      generation6:[],
      generation7:[],
      generation8:[],

  });


  
  const [Profile, setProfile] = React.useState(
    {
      distributor_count: 0,
      district: "",
      first_name: "",
      generation6_count: 0,
      generation7_count: 0,
      generation8_count: 0,
      gold_count: 0,
      id: "",
      last_name: "",
      middle_name: "",
      phone_number1: "",
      profile: "",
      rank: "",
      rank_points: 0,
      region: "",
      rubby_count: 0,
      silver_count: 0,
      tanzanite_count: 0,
      upliner_collection: [
        {  
          first_name: "Happines",
          id: "A67336SPA-FMFP",
          last_name: "Michale",
          middle_name: "Nickson",
          profile: "profile_avatar.png",
          rank: "distributor"
          },
           { 
            first_name: "Jeska",
            id: "A67336SPA-FMFP",
            last_name: "Pattrick",
            middle_name: "Hensen",
            profile: "profile_avatar.png",
            rank: "distributor"
            },
            { 
              first_name: "Jackline",
              id: "A67336SPA-FMFP",
              last_name: "Petter",
              middle_name: "Paul",
              profile: "profile_avatar.png",
              rank: "distributor"
              },
      
              { 
                first_name: "Daniel",
                id: "A67336SPA-FMFP",
                last_name: "Marembo",
                middle_name: "Tanis",
                profile: "profile_avatar.png",
                rank: "distributor"
                }
        ]
        
  });






const [UplinerProfileID, setUplinerProfileID] = React.useState(null);



useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  
  ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,currentTab,PaginationStart,PaginationInterval).then(function(JsonResponse){
  const {status} = JsonResponse
  setServerResponse(status)

  console.log(JsonResponse)
  if(status===200){
   
   const {Jsondata} = JsonResponse

   setuserGenerationList({...UserGenerationList,[currentTab]:Jsondata})

  }else if(status > 200){


     alert(`server error: ${status}`)

  }

  }).catch(function(ErrorResponse){

    alert('application error')
  })

 


return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[User])



const NavigateProfilePreview = (GenerationID)=>{

History.push({pathname:`/control/${AccountType}/users/profile`,state:{GenerationID:GenerationID}})


}



  
const GenerationListMap = UserGenerationList[currentTab].map(function(object,index){
 return(
     <React.Fragment key={index}>
          <Grid container spacing={1}>
          <Grid item xs={12}>
           
           <UserInstanceCard profile={object.profile} user_id={object.id} first_name={object.first_name} middle_name={object.middle_name} last_name={object.last_name}
             total_community={object.total_community} themecolor1={themecolor1}

             NavigateProfilePreview = {NavigateProfilePreview} current_grade_points={object.current_grade_points}
           />
             
             </Grid>
        </Grid>
        <br />
        
     </React.Fragment>
     )
})  








const GenerationListSkeleton = [0,1,2,3,4,5,6,7,8,9].map(function(object,index){
  return(
     <React.Fragment key={index}>
         <div className='generation-card'>
          <Grid container spacing={1}>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
              <div className='generation-skeleton-circle'>
              <Skeleton variant='circle' animation='pulse' style={{width:80,height:80}} /> 
              </div>
           </Grid>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
          </Grid>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
          </Grid>
 
          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"10%",height:30}} /> 
          </Grid>
         </Grid>
        </div>
        <br />
     </React.Fragment>
     )
})  




const GenerationNextFetch =(pagination_start,pagination_end,generation)=>{

  setServerResponse(null)
  ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,generation,pagination_start,pagination_end).then(function(JsonResponse){
    const {status} = JsonResponse
    setServerResponse(status)
  
    console.log(JsonResponse)
    if(status===200){
     
     const {Jsondata} = JsonResponse
  
     setuserGenerationList({...UserGenerationList,[currentTab]:[...UserGenerationList[currentTab],...Jsondata]})
  
    }else if(status > 200){
  
  
       alert(`server error: ${status}`)
  
    }
  
    }).catch(function(ErrorResponse){
  
      alert('application error')
    })
  
}




//GENERATION TABLE CLICK CHANGES
const handleChange = (event, newValue)=>{
  setValue(newValue);
  
  if(newValue===0){//distributor

   setcurrentTab('distributor')

   setPaginationStart(0)
   setPaginationEnd(PaginationInterval)

   if(distributor_count > 0 && UserGenerationList.distributor.length===0){
    setServerResponse(null)
    ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,'distributor',PaginationStart,PaginationInterval).then(function(JsonResponse){
    const {status} = JsonResponse
    setServerResponse(status)
  
    console.log(JsonResponse)
    if(status===200){
     
     const {Jsondata} = JsonResponse
  
     setuserGenerationList({...UserGenerationList,distributor:Jsondata})
  
    }else if(status > 200){
  
  
       alert(`server error: ${status}`)
  
    }
  
    }).catch(function(ErrorResponse){
  
      alert('application error')
    })
  
   }

   


   


 }else if(newValue===1){
   setcurrentTab('silver')

   
   setPaginationStart(0)
   setPaginationEnd(PaginationInterval)//RESET PAGINATION UMBER

    if(silver_count> 0 && UserGenerationList.silver.length===0){
      setServerResponse(null)
      ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,'silver',0,PaginationInterval).then(function(JsonResponse){
        const {status} = JsonResponse
        setServerResponse(status)
      
        console.log(JsonResponse)
        if(status===200){
         
         const {Jsondata} = JsonResponse
      
         setuserGenerationList({...UserGenerationList,silver:Jsondata})
      
        }else if(status > 200){
      
      
           alert(`server error: ${status}`)
      
        }
      
        }).catch(function(ErrorResponse){
      
          alert('application error')
        })

      

    }

    
  }
  else if(newValue===2){ 
    setcurrentTab('gold')

       
   setPaginationStart(0)
   setPaginationEnd(PaginationInterval)//RESET PAGINATION UMBER


    if(gold_count > 0 && UserGenerationList.gold.length===0){
      setServerResponse(null)
      ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,'gold',0,PaginationInterval).then(function(JsonResponse){
      const {status} = JsonResponse
      setServerResponse(status)
    
      console.log(JsonResponse)
      if(status===200){
       
       const {Jsondata} = JsonResponse
    
      setuserGenerationList({...UserGenerationList,gold:Jsondata})
    
      }else if(status > 200){
    
       alert(`server error: ${status}`)
    
      }
    
      }).catch(function(ErrorResponse){
    
        alert('application error')
      })
    
     }
  }
  else if(newValue===3){
   setcurrentTab('rubby')
   
   setPaginationStart(0)
   setPaginationEnd(PaginationInterval)//RESET PAGINATION UMBER

    if(gold_count > 0 && UserGenerationList.rubby.length===0){
      
      setServerResponse(null)
      ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,'rubby',0,PaginationInterval).then(function(JsonResponse){
      const {status} = JsonResponse
      setServerResponse(status)
    
      console.log(JsonResponse)
      if(status===200){
       
       const {Jsondata} = JsonResponse
    
       setuserGenerationList({...UserGenerationList,rubby:Jsondata})
    
      }else if(status > 200){
    
       alert(`server error: ${status}`)
    
      }
    
      }).catch(function(ErrorResponse){
    
        alert('application error')
      })
    
     }
  }
  else if(newValue===4){
  setcurrentTab('tanzanite')
   
     
  setPaginationStart(0)
  setPaginationEnd(PaginationInterval)//RESET PAGINATION UMBER

    if(gold_count > 0 && UserGenerationList.tanzanite.length===0){
      setServerResponse(null)
      ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,'tanzanite',0,PaginationInterval).then(function(JsonResponse){
      const {status} = JsonResponse
      setServerResponse(status)
    
      console.log(JsonResponse)
      if(status===200){
       
       const {Jsondata} = JsonResponse
    
       setuserGenerationList({...UserGenerationList,tanzanite:Jsondata})
    
      }else if(status > 200){
    
       alert(`server error: ${status}`)
    
      }
    
      }).catch(function(ErrorResponse){
    
        alert('application error')
      })
    
     }
  }
  else if(newValue===5){
    setcurrentTab('generation6')
       
   setPaginationStart(0)
   setPaginationEnd(PaginationInterval)//RESET PAGINATION UMBER


    if(gold_count > 0 && UserGenerationList.generation6.length===0){
      setServerResponse(null)
      ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,'generation6',0,PaginationInterval).then(function(JsonResponse){
      const {status} = JsonResponse
      setServerResponse(status)
    
      console.log(JsonResponse)
      if(status===200){
       
       const {Jsondata} = JsonResponse
    
       setuserGenerationList({...UserGenerationList,generation6:Jsondata})
    
      }else if(status > 200){
    
       alert(`server error: ${status}`)
    
      }
    
      }).catch(function(ErrorResponse){
    
        alert('application error')
      })
    
     }
  }
  else if(newValue===6){
    setcurrentTab('generation6')
    
   setPaginationStart(0)
   setPaginationEnd(PaginationInterval)

    if(gold_count > 0 && UserGenerationList.generation6.length===0){
      setServerResponse(null)
      ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,'generation6',PaginationStart,PaginationInterval).then(function(JsonResponse){
      const {status} = JsonResponse
      setServerResponse(status)
    
      console.log(JsonResponse)
      if(status===200){
       
       const {Jsondata} = JsonResponse
    
       setuserGenerationList({...UserGenerationList,generation6:Jsondata})
    
      }else if(status > 200){
    
       alert(`server error: ${status}`)
    
      }
    
      }).catch(function(ErrorResponse){
    
        alert('application error')
      })
    
     }
  }
  else if(newValue===7){
    setcurrentTab('generation7')
    
       
   setPaginationStart(0)
   setPaginationEnd(PaginationInterval)//RESET PAGINATION UMBER


    if(gold_count > 0 && UserGenerationList.generation7.length===0){
      setServerResponse(null)
      ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,'generation7',0,PaginationInterval).then(function(JsonResponse){
      const {status} = JsonResponse
      setServerResponse(status)
    
      console.log(JsonResponse)
      if(status===200){
       
       const {Jsondata} = JsonResponse
    
       setuserGenerationList({...UserGenerationList,generation7:Jsondata})
    
      }else if(status > 200){
    
       alert(`server error: ${status}`)
    
      }
    
      }).catch(function(ErrorResponse){
    
        alert('application error')
      })
    
     }
  }

};






const NextPaginationFunction =()=>{

  if(currentTab==='distributor'){

    if(PaginationEnd < distributor_count){
      // console.log(pagination_start,pagination_end)
      var pagination_start = PaginationEnd
      var pagination_end = (pagination_start+PaginationInterval)
    
      setPaginationStart(pagination_start)
      setPaginationEnd(pagination_end)
      GenerationNextFetch(pagination_start,pagination_end)


    }




  }else if(currentTab==='silver'){

    if(PaginationEnd < silver_count){
      // console.log(pagination_start,pagination_end)
      var pagination_start = PaginationEnd
      var pagination_end = (pagination_start+PaginationInterval)
    
      setPaginationStart(pagination_start)
      setPaginationEnd(pagination_end)
      GenerationNextFetch(pagination_start,pagination_end)
   }
}else if(currentTab==='gold'){

  if(PaginationEnd < gold_count){
    // console.log(pagination_start,pagination_end)
    var pagination_start = PaginationEnd
    var pagination_end = (pagination_start+PaginationInterval)
  
    setPaginationStart(pagination_start)
    setPaginationEnd(pagination_end)
    GenerationNextFetch(pagination_start,pagination_end)
 }
}else if(currentTab==='rubby'){

  if(PaginationEnd < silver_count){
    // console.log(pagination_start,pagination_end)
    var pagination_start = PaginationEnd
    var pagination_end = (pagination_start+PaginationInterval)
  
    setPaginationStart(pagination_start)
    setPaginationEnd(pagination_end)
    GenerationNextFetch(pagination_start,pagination_end)
 }
}
 

  
 




}





const EmptyGenerationMessage = ({GenerationLevel})=>{
return(
  <React.Fragment>
         <div className='empty-generation-message'>
          <h6>EMPTY {GenerationLevel} </h6>
        </div>
</React.Fragment>
)
}





const TESTFETCH =()=>{

  ServerFirstIndexedb_FetchGeneration(server_ip,authentication_token,currentTab,0,9).then(function(JsonResponse){
    const {status} = JsonResponse
    setServerResponse(status)
  
    console.log(JsonResponse)
    if(status===200){
     
     const {Jsondata} = JsonResponse
  
     //setuserGenerationList({...UserGenerationList,[currentTab]:Jsondata})
  
    }else if(status > 200){
  
  
       alert(`server error: ${status}`)
  
    }
  
    }).catch(function(ErrorResponse){
  
      alert('application error')
    })
  











}






return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="geneology-wrapper">
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          
        > 
          <Tab label={`D (${distributor_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={distributor_badge} alt='distributor'/> </div> } {...a11yProps(0)} />
          <Tab label={`SILVER (${silver_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={silver_badge} alt='silver'/> </div>} {...a11yProps(1)} />
          <Tab label={`GOLD (${gold_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={gold_badge} alt='sdistributor'/> </div>} {...a11yProps(2)} />
          <Tab label={`RUBY (${rubby_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={ruby_badge} alt='rubby'/> </div>} {...a11yProps(3)} />
          <Tab label={`TANZANITE (${tanzanite_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={tanzanite_badge} alt='tanzanite'/> </div>} {...a11yProps(4)} />
          <Tab label={`G 6 (${generation6_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={grade_5_badge} alt='grade 5'/> </div>} {...a11yProps(5)} />
          <Tab label={`G 7 (${generation7_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}> <img src={grade_6_badge} alt='g 6'/></div>} {...a11yProps(6)} />
          <Tab label={`G 8 (${generation8_count})`} icon={<div className='geneology-avator' style={{border:`4px solid ${Appsettings.themecolor1}`}}><img src={grade_7_badge} alt='grade 7'/> </div>} {...a11yProps(7)} />
        </Tabs>
      </AppBar>

      {/*---- DISTRIBUTOR TAB DISPLAYER ----*/}
      <TabPanel value={value} index={0}>
        {/* <button onClick={TESTFETCH}>TEST FUNCTION</button> */}
        <SearchUserField />
          {GenerationListMap}

        {ServerResponse===null && UserGenerationList.distributor.length===0?GenerationListSkeleton:null}

         
        {UserGenerationList[currentTab].length===0 && ServerResponse===200?
         <EmptyGenerationMessage GenerationLevel='Dlevel community'/>
        :null}
  
      </TabPanel>

      {/*---- SILVER TAB DISPLAYER ----*/}
      <TabPanel value={value} index={1}>
      <SearchUserField />
       
        {GenerationListMap}

       {ServerResponse===null && UserGenerationList.silver.length===0?GenerationListSkeleton:null}

      {UserGenerationList[currentTab].length===0 && ServerResponse===200?
         <EmptyGenerationMessage GenerationLevel='SILVER community'/>
        :null}

      </TabPanel>

        {/*---- GOLD TAB DISPLAYER ----*/}
      <TabPanel value={value} index={2}>

         {GenerationListMap}
         
         {ServerResponse===null && UserGenerationList.gold.length===0?GenerationListSkeleton:null}

         {UserGenerationList[currentTab].length===0 && ServerResponse===200?
         <EmptyGenerationMessage GenerationLevel='GOLD community'/>
        :null}

      </TabPanel>

      {/*---- RUBBY TAB DISPLAYER ----*/}
      <TabPanel value={value} index={3}>
         
      {GenerationListMap}

      {ServerResponse===null && UserGenerationList.rubby.length===0?GenerationListSkeleton:null}
       
      {UserGenerationList[currentTab].length===0 && ServerResponse===200?
         <EmptyGenerationMessage GenerationLevel='RUBBY community'/>
        :null}
      </TabPanel>

      {/*---- TANZANITE TAB DISPLAYER ----*/}
      <TabPanel value={value} index={4}>
        
         {GenerationListMap}
         
         {ServerResponse===null && UserGenerationList.tanzanite.length===0?GenerationListSkeleton:null}
         
         {UserGenerationList[currentTab].length===0 && ServerResponse===200?
         <EmptyGenerationMessage GenerationLevel='TANZANITE community'/>
        :null}

      </TabPanel>


      {/*---- GENERATION5 TAB DISPLAYER ----*/}
      <TabPanel value={value} index={5}>
        
         {GenerationListMap}
         
         {ServerResponse===null && UserGenerationList.generation6.length===0?GenerationListSkeleton:null}

         {UserGenerationList[currentTab].length===0 && ServerResponse===200?
         <EmptyGenerationMessage GenerationLevel='GENERATION6 community'/>
        :null}

      </TabPanel>
      <TabPanel value={value} index={6}>

         {GenerationListMap}

         {ServerResponse===null && UserGenerationList.generation7.length===0?GenerationListSkeleton:null}
       

         {UserGenerationList[currentTab].length===0 && ServerResponse===200?
         <EmptyGenerationMessage GenerationLevel='GENERATION7 community'/>
        :null}
      </TabPanel>
      <TabPanel value={value} index={7}>
           
        {GenerationListMap}

        {ServerResponse===null && UserGenerationList.generation8.length===0?GenerationListSkeleton:null}
       
        {UserGenerationList[currentTab].length===0 && ServerResponse===200?
         <EmptyGenerationMessage GenerationLevel='GENERATION8 community'/>
        :null}

      </TabPanel>
    </div>
     </Grid>
         
     <Grid item xs={12}> 
     <ButtonGroup fullWidth={true} size='small' variant="contained" color="primary" aria-label="contained primary button group">
        <Button onClick={NextPaginationFunction} endIcon={<ArrowForwardIos />}>Next</Button>
      </ButtonGroup>

     </Grid>


          </Grid>


</div>

</Fragment>
 
    
);


// Generations.propTypes = {}
        
// Generations.defaultProps ={}


}
export {Generations};

