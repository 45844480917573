import React ,{Fragment,useEffect,useContext}from 'react';
import {useHistory,useLocation} from 'react-router-dom';
import './NavigatioButtons.scss';
import Grid from '@material-ui/core/Grid';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {ArrowBack,Home} from '@material-ui/icons';


    
const NavigatioButtons = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User} = Appstate
  const {CurrencyFormat} = Functions

  const {themecolor1,themecolor2,AccountType} = Appsettings



  const History = useHistory()

useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  





return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])






const NavigationBack = ()=>{

  History.goBack()

}






return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="navigation-buttons-wrapper">

       <ButtonGroup fullWidth={true} size='small' variant="contained" color="primary" aria-label="contained primary button group">
           <Button startIcon={<Home style={{color:'#fff',fontSize:25}} />}><span>HOME</span></Button>
           <Button  onClick={NavigationBack} startIcon={<ArrowBack style={{color:'#fff',fontSize:25}} />}><span>GO BACK</span></Button>
      </ButtonGroup>
</div>
</Fragment>
 );


// NavigatioButtons.propTypes = {}
        
// NavigatioButtons.defaultProps ={}


}
export {NavigatioButtons};

