import React ,{Fragment,useEffect,useState}from 'react';
import {useLocation,NavLink,Link,useHistory,useParams} from 'react-router-dom';
import './Useraccount.scss'
//import { makeStyles } from '@material-ui/core/styles';
import {NavigationUseraccount} from './NavigationUseraccount';
import {ThemeProvider,createMuiTheme,Backdrop} from "@material-ui/core";
import {UseraccountContextApi} from "./UseraccountContextApi";
import {RewardSection} from "./components/RewardSection/RewardSection";
import Cookies from 'js-cookie';
import $ from 'jquery';
//import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {InboxSideMenu} from "./components/InboxNotification/InboxSideMenu";
import {NotificationApplication} from "./components/NotificationApplication/NotificationApplication";

import {LocalAtm,Email} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {ScaleLoader} from "react-spinners";
import {ServerFirstIndexedb_ApplicationMounting} from "../AdminAccounts/HttpcallUserApp";
import {Divider} from '@material-ui/core';   
import {BreadcumbNavigation} from "./components/BreadcumbNavigation/BreadcumbNavigation";
import {AddAlertRounded,SettingsApplications,Money,AddAlert,AccountCircle,GroupAdd,Settings,SettingsPower,Dashboard,PeopleAlt,AccountBalance,MonetizationOn} from '@material-ui/icons';

import DistributorBadge from './DistributorBadge.svg';
import RubbyBadge from './RubbyBadge.svg';
import TanzaniteBadge from './TanzaniteBadge.svg';
import SilverBadge from './SilverBadge.svg';
import GoldBadge from './GoldBadge.svg';
import Grade5Badge from './Grade5Badge.svg';
import Grade6Badge from './Grade6Badge.svg';
import Grade7Badge from './Grade7Badge.svg';





const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

    
const Useraccount = ({props}) => { //--------------MAIN FUNCTION----------------
  //const classes = useStyles();
const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };



const Location = useLocation()

const {pathname} = Location

const UrlLink = pathname.split('/')

const History = useHistory()

const UrlParams = useParams()

var AccountType = UrlParams.AccountType==='admin' || UrlParams.AccountType==='accountant' || UrlParams.AccountType==='storekeeper'?UrlParams.AccountType:null



const [Appstate,setAppstate] = useState({
  Appsettings:{
    themecolor1: "#387C44",
    themecolor2: "#CD7F32",
    AccountType:AccountType
  },
   User:{
    accountant: null,
    community: 0,
    company_district:null,
    company_email1: null,
    company_email2: null,
    company_motto: null,
    company_name: null,
    company_phone1: null,
    company_phone2: null,
    company_region: null,
    company_street: null,
    coverphoto: "company-coverphoto.jpg",
    distributor_count:0,
    execute:null,
    storekeeper: null,
    superadmin: null,
    write: null,
    read:null,
    first_name: "",
    generation6_count: 0,
    generation7_count: 0,
    generation8_count: 0,
    gold_count: 2,
    last_name: "",
    loan_request: 0,
    logo: "logo-avatar.png",
    rubby_count: 0,
    silver_count: 10,
    tanzanite_count: 0,
    themecolor1: "#008080",
    themecolor2: "#F52887",
    transactional_request: 0,
    username: "femsadmin@gmail.com",
    
},
  App:{
      authenticated:(function(){
      var token = Cookies.get("USR-IDD")
      if(token !==undefined){
      return true;
      }else{
      return false;
      }   
      })(),
      authentication_token:(function(){
        var token = Cookies.get("SUID-SESSION")
        if(token !==null && token !==undefined){
        return token;
        }else{
        return null;
        }   
      })(),
        server_ip:(function(){
          if(process.env.NODE_ENV==='production'){
           return "/";
          }else{ 
            return  "http://127.0.0.1:8000/";
           }
      
        })()
      
      }
})






const [ServerResponse, setServerResponse] = React.useState(null);





var WindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

var height = window.innerHeight|| document.documentElement.clientHeight || document.body.clientHeight;




const theme = createMuiTheme({

  palette:{
    primary:{
      main:`${Appstate.Appsettings.themecolor1}`
    },
    secondary:{
      main:`${Appstate.Appsettings.themecolor2}`
    },
  },
  typography:{
    //fontFamily:'Comic Sans Ms',
    body1:{
      fontSize:'1.1rem',
    
    },
    // h1:{
    //   color:'red'
    // }
  },
  shape:{
    borderRadius:0,
  },
  //spacing:8,
  overrides:{
    MuiButton:{
      root:{
        textTransform:'none',
        
      }
    },

  },
  props:{
    MuiButton:{
      // disableRipple:true,
      // variant:'text'
    },
    MuiButtonGroup:{
      // disableRipple:true,
      // variant:'outlined'
    },
    MuiTextField:{
      InputLabelProps:{
        shrink:true
      }
    }
    
  }

})





useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------



ServerFirstIndexedb_ApplicationMounting(Appstate.App.server_ip,Appstate.App.authentication_token).then(function(JsonResponse){
const {status} = JsonResponse

setServerResponse(status)
if(status===200){

  const {Jsondata} = JsonResponse
    
  
  
  setAppstate({...Appstate,User:Jsondata})

  const {profile_completed} = Jsondata
  
  if(profile_completed===0){//profile completed false


  // History.replace({pathname:"/account/profile"})  
  //History.replace({pathname:"/account/profile"})

}
 
}else if(status ===401){

 History.replace({pathname:"/control/login",state:{message:"your session has expired please login again"}})  


}

}).catch(function(ErrorResponse){


  setServerResponse('offline')
})


// if(Appstate.User.profile_completed===0){//profile completed false


//   History.replace({pathname:"/account/profile"})  

// }



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




 

}
},[])



const classes = useStyles();
const [open, setOpen] = React.useState(false);
const handleClose = () => {
  setOpen(false);
};
const handleToggle = () => {
  setOpen(!open);
};


const formatToCurrency = (amount) =>{

  return "TSH " + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  
};


const InboxsidemenuTrigger = ()=>{
  setState({...state,left:true})
}


const ProfilePhotoUpdateFuction =(ProfileImage)=>{

    setAppstate({...Appstate,User:{...Appstate.User,profile_photo:ProfileImage}})
}



const RankBadgeIdentifier = (Rank)=>{

if(Rank==='distributor'){

return(DistributorBadge)


}else if(Rank==='silver'){

  return(SilverBadge)

}else if(Rank==='gold'){

  return(GoldBadge)

}else if(Rank==='rubby'){

  return(RubbyBadge)
}else if(Rank==='tanzanite'){

  return(TanzaniteBadge)
}else if(Rank==='generation6'){

  return(Grade5Badge)

}else if(Rank==='generation7'){

  return(Grade6Badge)

}else if(Rank==='generation8'){

  return(Grade7Badge)
}else{

  return null
}



}

const UpdateProfileCompletedStatus = ()=>{


  setAppstate({...Appstate,User:{...Appstate.User,profile_completed:1}})


}


const SystemNotificationReaded = ()=>{

  if(Appstate.User.system_notification >0){

    var count = Appstate.User.system_notification - 1


    setAppstate({...Appstate,User:{...Appstate.User,system_notification:count}})



  }
  


}

const TransactionMesagesReaded = ()=>{


  if(Appstate.User.transactional_message > 0){
    var count = Appstate.User.transactional_message -1 

    setAppstate({...Appstate,User:{...Appstate.User,transactional_message:count}})



  }




}



const DeductAccountBalance = (amount)=>{

if(Appstate.User.balance >= amount){

    var LeftAmount = Appstate.User.balance - amount

    setAppstate({...Appstate,User:{...Appstate.User,balance:LeftAmount}})

}

}





const LogoutProtocal =()=>{
  return new Promise(function(resolve,reject){

    Cookies.remove('SUID-SESSION')
   
    return resolve({status:200});
 })
 }  


const LogoutFunction = ()=>{

LogoutProtocal().then(function(){
  
  setServerResponse(null)

  setTimeout(function(){


    setServerResponse(200)

     History.replace({pathname:"/control/login",state:{message:'you have been logged out successfully !'}})  

  },1000)




})  


} 
 

const HideSidemenuFunction =()=>{

if(WindowWidth <= 767){


var HideSidemenuButton = $('#sidebar-toggle-button')

HideSidemenuButton[0].click()


}


}









var TotalMessage = Appstate.User.personal_message+Appstate.User.transactional_message+Appstate.User.system_notification



var Functions={
CurrencyFormat:formatToCurrency,
InboxsidemenuTrigger:InboxsidemenuTrigger,
ProfilePhotoUpdateFuction:ProfilePhotoUpdateFuction,
RankBadgeIdentifier:RankBadgeIdentifier,
UpdateProfileCompletedStatus:UpdateProfileCompletedStatus,
TransactionMesagesReaded:TransactionMesagesReaded,
SystemNotificationReaded:SystemNotificationReaded,
DeductAccountBalance:DeductAccountBalance

 }



 const MessagesCount = ()=>{

  var count = 0

  return count
 }



const BakcdropLoading = ()=>{
return(
 <React.Fragment>
       <div>
      <Backdrop className={classes.backdrop} style={{backgroundColor:`${Appstate.Appsettings.themecolor2}`}} open={true} onClick={handleClose}>

        <ScaleLoader speedMultiplier={2} loading={ServerResponse===null && ServerResponse !=='offline'?true:false} height={60} width={10} color='#fff' />
          
          {ServerResponse > 200?
          <div className='server-error-message'> <br /><span>SERVER ERROR {ServerResponse}</span> </div>   
           :null}

        {ServerResponse==='offline'?
          <div className='server-error-message'> <br /><span>could not connect to server !</span> </div>   
           :null}

      </Backdrop>
     </div>
  </React.Fragment>
)
}
 
function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}




const RankQualificationMessage = () =>{

  if(Appstate.User.qualified_silver===1 || Appstate.User.qualified_gold===1 || Appstate.User.qualified_rubby===1
    || Appstate.User.qualified_tanzanite===1 || Appstate.User.qualified_tanzanite===1){

      return(
        <React.Fragment>
            <div class="callout callout-success">
              <div className='rank-qualified-wrapper'>
                <div className='rank-qualified-circle'><img src={RankBadgeIdentifier(Appstate.User.rank)} alt='user badge'/></div>  
                  <h5 style={{color:Appstate.Appsettings.themecolor2}}>{Appstate.User.rank.toLocaleUpperCase()} QUALIFIED !</h5> 
                </div>                             
                <p>Congratulation you have qualified as a <b style={{color:Appstate.Appsettings.themecolor2}}>{Appstate.User.rank}</b> candidate </p>
          </div>
      </React.Fragment>
    )


  }else{return null}


}






return (   //--------RETURN HTML---------------------------
<Fragment>
<ThemeProvider theme={theme}>   
<UseraccountContextApi.Provider value={{Appstate,Functions:Functions}}>
{/* {Appstate.User.first_name.length > 0 && ServerResponse===200? */}
{ServerResponse===200?
<div className="wrapper">
  {/* Navbar */}
  <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{backgroundColor:`${Appstate.Appsettings.themecolor1}`}}>
    {/* Left navbar links */}
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="/" role="button" id='sidebar-toggle-button'><i className="fas fa-bars" /></a>
      </li>
      <li>
        <span className='nav-brand-name'>Femsbay</span>
      </li>
    </ul>
    {/* Right navbar links */}
    <ul className="navbar-nav ml-auto">
   
      {/* Messages Dropdown Menu */}
      <li className="nav-item dropdown">
        <a className="nav-link" data-toggle="dropdown" href="/">
           
           {/*------ MESSAGE COUNTER BADGE --------------*/}
           {TotalMessage > 0?
            <div className='notification-badge-counter' style={{backgroundColor:`${Appstate.Appsettings.themecolor2}`}}>
           
             <span>{TotalMessage}</span>
            
            </div>
            :null}

        <div className='nav-sidebar-icon'><AddAlert fontSize='large' style={{color:`${Appstate.Appsettings.themecolor1}`}} /></div>
        </a>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" id='dropdown-message'>
          <Link to="/account/inbox/system_notification" className="dropdown-item">
            {/* Message Start */}
            <div className="media">
              <div className="dropdown-message-circle" style={{border:`2px solid ${Appstate.Appsettings.themecolor2}`,backgroundColor:`${Appstate.Appsettings.themecolor1}`}}>
                <AddAlert fontSize='large' style={{color:`#fff`}} />
                 </div>
              <div className="media-body">
                <h3 className="dropdown-item-title">
                  Notifications ({Appstate.User.system_notification})
                </h3>
                <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
              </div>
            </div>
            {/* Message End */}
          </Link>
          <div className="dropdown-divider" />
          <Link to="/account/inbox/transactional_message" className="dropdown-item">
            {/* Message Start */}
            <div className="media">
            <div className="dropdown-message-circle" style={{border:`2px solid ${Appstate.Appsettings.themecolor2}`,backgroundColor:`${Appstate.Appsettings.themecolor1}`}}>
                <LocalAtm fontSize='large' style={{color:`#fff`}} />
                 </div>
              <div className="media-body">
                <h3 className="dropdown-item-title">
                  Transaction ({Appstate.User.transactional_message})
                </h3>
                <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
              </div>
            </div>
            {/* Message End */}
          </Link>
          <div className="dropdown-divider" />
          <Link to="/account/inbox/personal_message" className="dropdown-item">
            {/* Message Start */}
            <div className="media">
            <div className="dropdown-message-circle" style={{border:`2px solid ${Appstate.Appsettings.themecolor2}`,backgroundColor:`${Appstate.Appsettings.themecolor1}`}}>
                <Email fontSize='large' style={{color:`#fff`}} />
                 </div>
              <div className="media-body">
                <h3 className="dropdown-item-title">
                  Messages ({Appstate.User.personal_message})
                </h3>
                <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
              </div>
            </div>
            {/* Message End */}
          </Link>
          <div className="dropdown-divider" />
        </div>
      </li>
     
      <li className="nav-item dropdown">
        <a  className="nav-link" data-toggle="dropdown" href="/">
          <div className='nav-sidebar-icon' style={{backgroundImage:'url(fems-logo.png)'}}></div>
        </a>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" id='dropdown-profile'>
          <span className="dropdown-header">Profile &amp; Settings</span>
          <div className="dropdown-divider" />
          <Link to="#" className="dropdown-item">
            <GroupAdd fontSize='large' style={{color:`${Appstate.Appsettings.themecolor1}`}}/> Add Account
          </Link>
          <div className="dropdown-divider" />
          <Link to="#" className="dropdown-item">
            <AccountCircle fontSize='large' style={{color:`${Appstate.Appsettings.themecolor1}`}} /> Profile
          </Link>
          <div className="dropdown-divider" />
          <Link to="#" className="dropdown-item">
            <Settings fontSize='large' style={{color:`${Appstate.Appsettings.themecolor1}`}} /> Settings
          </Link>
          <div className="dropdown-divider" />
          <Link to="#" onClick={LogoutFunction} className="dropdown-item">
            <SettingsPower fontSize='large' style={{color:`${Appstate.Appsettings.themecolor1}`}} /> Logout
          </Link>
    
        </div>
      </li>
    </ul>
  
  
  </nav>
  {/* /.navbar */}
  {/* Main Sidebar Container */}
  <aside className="main-sidebar sidebar-dark-primary elevation-4" id='aside-menu-wrapper'>
    {/* Brand Logo */}
    <div className='brand-logo-wrapper' style={{backgroundColor:`${Appstate.Appsettings.themecolor1}`}}> 
       <div className='brand-logo-circle' style={{backgroundImage:'url(fems-logo.png)'}}> </div>
       <Divider />
       <span className="brand-text font-weight-light">SUPER ADMIN</span>
       <Divider />
       <span className="account-full-name">{Appstate.User.first_name} {Appstate.User.last_name}</span>
    </div>
    <div className="sidebar">
       <div className='sidebar-menu-wrapper'>
        
        <div className='sidebar-inner' id='aside-left-tinny'></div>

        <div className='sidebar-inner' style={{backgroundColor:`${Appstate.Appsettings.themecolor1}`}}>

          {/* <NavLink onClick={HideSidemenuFunction} to="/account/dashboard" activeClassName='sidebar-menu-active'>
          <div className='sidebar-menu-circle' style={{border:`4px solid ${Appstate.Appsettings.themecolor2}`}}><Dashboard style={{fontSize:40,color:`${Appstate.Appsettings.themecolor1}`}} /> </div>
            <h6 className='aside-text-short'>DASHBOARD</h6>
          </NavLink>
          <Divider /> */}

          <NavLink onClick={HideSidemenuFunction} to={`/control/${AccountType}/notifications`} activeClassName='sidebar-menu-active'>
          <div className='sidebar-menu-circle' style={{border:`4px solid ${Appstate.Appsettings.themecolor2}`}}><AddAlertRounded style={{fontSize:40,color:`${Appstate.Appsettings.themecolor1}`}} /> </div>
            <h6 className='aside-text-short'>NOTIFICATIONS</h6>
          </NavLink>
          <Divider />

          <NavLink onClick={HideSidemenuFunction} to={`/control/${AccountType}/users`} activeClassName='sidebar-menu-active'>
          <div className='sidebar-menu-circle' style={{border:`4px solid ${Appstate.Appsettings.themecolor2}`}}><PeopleAlt style={{fontSize:40,color:`${Appstate.Appsettings.themecolor1}`}} />  </div>
            <h6 className='aside-text-short'>COMMUNITY</h6>
          </NavLink>

          <Divider />

          <NavLink onClick={HideSidemenuFunction} to={`/control/${AccountType}/settings`} activeClassName='sidebar-menu-active'>
          <div className='sidebar-menu-circle' style={{border:`4px solid ${Appstate.Appsettings.themecolor2}`}}><Settings style={{fontSize:40,color:`${Appstate.Appsettings.themecolor1}`}} />  </div>
            <h6 className='aside-text-long'>PROFILE SETTINGS</h6>
          </NavLink>

          <Divider />

          {/* <NavLink onClick={HideSidemenuFunction}  to="/account/inbox" activeClassName='sidebar-menu-active'>
           <div className='sidebar-menu-circle' style={{border:`4px solid ${Appstate.Appsettings.themecolor2}`}}><Email style={{fontSize:40,color:`${Appstate.Appsettings.themecolor1}`}} />  </div>
           <h6 className='aside-text-long'>NOTIFICATION CENTER</h6>
          </NavLink> */}

          {/* <Divider />
          <NavLink onClick={HideSidemenuFunction} to="/account/loanapplication" activeClassName='sidebar-menu-active'>
          <div className='sidebar-menu-circle' style={{border:`4px solid ${Appstate.Appsettings.themecolor2}`}}><Money style={{fontSize:40,color:`${Appstate.Appsettings.themecolor1}`}} /> </div>
            <h6 className='aside-text-long'>LOAN APPLICATION</h6>
          </NavLink> */}

          {/* <Divider />
          <NavLink onClick={HideSidemenuFunction} to="/account/profile" activeClassName='sidebar-menu-active'>
          <div className='sidebar-menu-circle' style={{border:`4px solid ${Appstate.Appsettings.themecolor2}`}}><SettingsApplications style={{fontSize:40,color:`${Appstate.Appsettings.themecolor1}`}} /> </div>
            <h6 className='aside-text-long'>PROFILE  &amp; SETTINGS</h6>
          </NavLink> */}

          <div className='aside-footer-padding'>

          </div>






      


        </div>






         </div>
     
      {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
  </aside>
  {/* Content Wrapper. Contains page content */}
  <div className="content-wrapper">
    {/* Content Header (Page header) */}
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-12" id='breadcumb-wrapper'>
               
               {/* Rank Qualificatiom Message */}

               <RankQualificationMessage />
              
              {/* Breadcumb navigation */}
             <BreadcumbNavigation />
          </div>{/* /.col */}
        </div>{/* /.row */}
      </div>{/* /.container-fluid */}
    </div>
    {/* /.content-header */}
    {/* Main content */}
    <div className="content">
      <div className="container-fluid">
        <div className="row">

         <NotificationApplication /> 

             
         {/*------- body content goes here --------*/}
         <NavigationUseraccount />



          
         <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
              {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {anchor==='right'?
            <React.Fragment>

           
           <h6>REWARDS ACHIEVEMENT</h6>
           <RewardSection />
           </React.Fragment>
           :<InboxSideMenu />}


          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
        
        
        </div>
        {/* /.row */}
      </div>{/* /.container-fluid */}
    </div>
    {/* /.content */}
  </div>
  {/* /.content-wrapper */}
  {/* Control Sidebar */}
  <aside className="control-sidebar control-sidebar-light">
    {/* Control sidebar content goes here */}
    <div className="p-3">
      <h6>REWARDS ACHIEVEMENT</h6>
    </div>
    <RewardSection />

  </aside>
  {/* /.control-sidebar */}
  {/* Main Footer */}
  <footer className="main-footer">
    {/* To the right */}
    <div className="float-right d-none d-sm-inline">
      Anything you want
    </div>
    {/* Default to the left */}
    <strong>Copyright © 2014-2021 <a href="/">Femsbay</a>.</strong> All rights reserved.
  </footer>
</div>

:<BakcdropLoading /> }

</UseraccountContextApi.Provider>
</ThemeProvider>
</Fragment>
);


// Useraccount.propTypes = {}
        
// Useraccount.defaultProps ={}

}
export {Useraccount};

