import React ,{Fragment,useEffect,useContext}from 'react';
import './AddProductStock.scss';
import Grid from '@material-ui/core/Grid';
//import { makeStyles } from '@material-ui/core/styles';
//import {Money,History,LocalAtm,AccountBalance} from '@material-ui/icons';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
//import transaction_withdraw from "./transaction_withdraw.svg";
import balance_icon from './balance_icon.svg';
import clc_points_icon from './clc_points_icon.svg';
import cpv_points_icon from "./cpv_points_icon.svg";
import network_icon from "./network_icon.svg";
import ProductFormComponent from "../AddProductForm/ProductFormComponent";
//import {ArrowRightAlt} from '@material-ui/icons';
// import {AddProductStockCard,AddProductStockCardCircle,AddProductStockCardLogo,AddProductStockCardText} from "../StyledComponents/styledcomponents";

//import {MoreVert} from '@material-ui/icons';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
// }));



    
const AddProductStock = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User} = Appstate
  const {CurrencyFormat} = Functions
   const {themecolor1,themecolor2} = Appsettings

  //const classes = useStyles();


const [AddProductStockInfor, setAddProductStockInfor] = React.useState({
  title:'',
  description:'',
  icon:'',
});

const {balance,clc,community,cpv} = User





useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  



//0683300300 msangwa herbal kona ya nyamhongoro



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])




const ActivateModalInfor = (AddProductStock)=>{

  if(AddProductStock==='cpv'){
  setAddProductStockInfor({
      title:'MY CPV POINTS',
      description:'commision points value (cpv) these are the points earned per each products purchases , these points enable you uprade to upper grade when they reach 3 or more points',
      icon:cpv_points_icon
    })
 
  
  }else if(AddProductStock==='balance'){
    setAddProductStockInfor({
      title:'MY BALANCE',
      description:'Your account balance has a monetary value , you can withdraw and get paid by cash,your account balance can be recharged when you purchased a product',
      icon:balance_icon
    })
  
  }
  else if(AddProductStock==='clc'){
    setAddProductStockInfor({
      title:'MY CLC POINTS',
      description:<p>These are the points earned when you and your networks(downliner) purchased a products<br/>when your clc points reach 1000+ your qualified to apply for a loan.</p>,
      icon:clc_points_icon
    })
  
  }else if(AddProductStock==='network'){
    setAddProductStockInfor({
      title:'MY NETWORK',
      description:<p>Each individual who has joined Femsworldtz through your links or your membership ID are pressumed as your network<br/>You get a commission whenever you invite a new member to join.</p>,
      icon:network_icon
    })
  
  }
  
  
  
  }
  
  





// const WithdrawReportMap = [0,1,2,3,4,5].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// })  



// const PendingTransactionMap= [0].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// }) 



return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="transaction-wrapper">


    
<Grid container spacing={1}>

  <ProductFormComponent />




</Grid>





</div>
</Fragment>
 
    
);


// AddProductStock.propTypes = {}
        
// AddProductStock.defaultProps ={}


}
export {AddProductStock};

