import React ,{Fragment,useEffect,useState,useContext} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery'


var regions ={
    DarEsSalaam:true,
    Dodoma:false,
    Mwanza:false,
    Mbeya:true,
    Arusha:false,
    Kilimanjaro:false,
    Pwani:false,
    Tanga:false,
    Morogoro:false,
    Pemba:false,
    Iringa:false,
    Kigoma:false,
    Lindi:false,
    Mara:false,
    Unguja:false,
    Mtwara:false,
    Njombe:false,
    Rukwa:false,
    Ruvuma:false,
    Singida:false,
    Shinyanga:false,
    Songwe:false,
    Geita:false,
    Katavi:false,
    Manyara:false,
    Simiyu:false,
    Tabora:false
}

const  TanzaniaRegionsList = ({props}) => {
return (
<Fragment>

  <option style={{fontSize: 15}} value="Dar_es_salaam">Dar Es Salaam</option>
  <option style={{fontSize: 15}} value="Dodoma">Dodoma</option>
  <option style={{fontSize: 15}} value="Mwanza">Mwanza</option>
  <option style={{fontSize: 15}} value="Mbeya">Mbeya</option>
  <option style={{fontSize: 15}} value="Arusha">Arusha</option>
  <option style={{fontSize: 15}} value="Kilimanjaro">Kilimanjaro</option>
  <option style={{fontSize: 15}} value="Pwani">Pwani</option>
  <option style={{fontSize: 15}} value="Tanga">Tanga</option>
  <option style={{fontSize: 15}} value="Morogoro">Morogoro</option>
  <option style={{fontSize: 15}} value="Pemba">Pemba</option>
  <option style={{fontSize: 15}} value="Iringa">Iringa</option>
  <option style={{fontSize: 15}} value="Kigoma">Kigoma</option>
  <option style={{fontSize: 15}} value="Lindi">Lindi</option>
  <option style={{fontSize: 15}} value="Mara">Mara</option>
  <option style={{fontSize: 15}} value="Unguja">Unguja</option>
  <option style={{fontSize: 15}} value="Mtwara">Mtwara</option>
  <option style={{fontSize: 15}} value="Njombe">Njombe</option>
  <option style={{fontSize: 15}} value="Rukwa">Rukwa</option>
  <option style={{fontSize: 15}} value="Ruvuma">Ruvuma</option>
  <option style={{fontSize: 15}} value="Singida">Singida</option>
  <option style={{fontSize: 15}} value="Shinyanga">Shinyanga</option>
  <option style={{fontSize: 15}} value="Songwe">Songwe</option>
  <option style={{fontSize: 15}} value="Geita">Geita</option>
  <option style={{fontSize: 15}} value="Katavi">Katavi</option>
  <option style={{fontSize: 15}} value="Kigoma">Kigoma</option>
  <option style={{fontSize: 15}} value="Manyara">Manyara</option>
  <option style={{fontSize: 15}} value="Simiyu">Simiyu</option>
  <option style={{fontSize: 15}} value="Tabora">Tabora</option>


</Fragment>    
);

}


const   DarEsSalaamDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 15}} value="Kinondoni">Kinondoni</option>
  <option style={{fontSize: 15}} value="Ilala">Ilala</option>
  <option style={{fontSize: 15}} value="Ubungo">Ubungo</option>
  <option style={{fontSize: 15}} value="Kigamboni">Kigamboni</option>
  <option style={{fontSize: 15}} value="Temeke">Temeke</option>

  </Fragment>    
    );
    
}

const   DodomaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 15}} value="Dodoma Urban">Dodoma Urban</option>
  <option style={{fontSize: 15}} value="Kondoa">Kondoa</option>
  <option style={{fontSize: 15}} value="Chemba">Chemba</option>
  <option style={{fontSize: 15}} value="Kongwa">Kongwa</option>
  <option style={{fontSize: 15}} value="Mpwapwa">Mpwapwa</option>
  <option style={{fontSize: 15}} value="Bahi">Bahi</option>
  <option style={{fontSize: 15}} value="Chamwino">Chamwino</option>


  </Fragment>    
    );
    
}

const   MwanzaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 15}} value="Ilemela">Ilemela</option>
  <option style={{fontSize: 15}} value="Kiwimba">Kiwimba</option>
  <option style={{fontSize: 15}} value="Magu">Magu</option>
  <option style={{fontSize: 15}} value="Misungwi">Misungwi</option>
  <option style={{fontSize: 15}} value="Nyamagana">Nyamagana</option>
  <option style={{fontSize: 15}} value="Sengerema">Sengerema</option>
  <option style={{fontSize: 15}} value="Ukerewe">Ukerewe</option>
  </Fragment>    
    );
}

const   MbeyaDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 15}} value="Chunya">Chunya</option>
  <option style={{fontSize: 15}} value="Ileje">Ileje</option>
  <option style={{fontSize: 15}} value="Kyela">Kyela</option>
  <option style={{fontSize: 15}} value="Mbarali">Mbarali</option>
  <option style={{fontSize: 15}} value="Mbeya Urban">Mbeya Urban</option>
  <option style={{fontSize: 15}} value="Mbozi">Mbozi</option>
  <option style={{fontSize: 15}} value="Momba">Momba</option>
  <option style={{fontSize: 15}} value="Rungwe">Rungwe</option>


  </Fragment>    
    );
}

const   ArushaDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 15}} value="Arusha City">Arusha City</option>
  <option style={{fontSize: 15}} value="Arusha Rural District (in Arumeru) ">Arusha Rural District (in Arumeru) </option>
  <option style={{fontSize: 15}} value="Karatu">Karatu</option>
  <option style={{fontSize: 15}} value="Longido">Longido</option>
  <option style={{fontSize: 15}} value="Meru (in Arumeru)">Meru (in Arumeru)</option>
  <option style={{fontSize: 15}} value="Monduli">Monduli</option>
  <option style={{fontSize: 15}} value="Ngorongoro">Ngorongoro</option>

  </Fragment>    
    );
}


const   KilimanjaroDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 15}} value="Hai">Hai</option>
  <option style={{fontSize: 15}} value="Moshi Urban">Moshi Urban</option>
  <option style={{fontSize: 15}} value="Moshi Rural">Moshi Rural</option>
  <option style={{fontSize: 15}} value="Mwanga">Mwanga</option>
  <option style={{fontSize: 15}} value="Rombo">Rombo</option>
  <option style={{fontSize: 15}} value="Same">Same</option>
  <option style={{fontSize: 15}} value="Siha">Siha</option>

  </Fragment>    
    );
}

const   PwaniDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 15}} value="Bagamoyo">Bagamoyo</option>
  <option style={{fontSize: 15}} value="Kibaha">Kibaha</option>
  <option style={{fontSize: 15}} value="Kisarawe">Kisarawe</option>
  <option style={{fontSize: 15}} value="Mafia">Mafia</option>
  <option style={{fontSize: 15}} value="Mkuranga">Mkuranga</option>
  <option style={{fontSize: 15}} value="Rufiji">Rufiji</option>

  </Fragment>    
    );
}

const   TangaDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 15}} value="Handeni Rural">Handeni Rural</option>
  <option style={{fontSize: 15}} value="Handeni Urban">Handeni Urban</option>
  <option style={{fontSize: 15}} value="Kilindi">Kilindi</option>
  <option style={{fontSize: 15}} value="Korogwe Rural">Korogwe Rural</option>
  <option style={{fontSize: 15}} value="Korogwe Urban">Korogwe Urban</option>
  <option style={{fontSize: 15}} value="Lushoto">Lushoto</option>
  <option style={{fontSize: 15}} value="Muheza">Muheza</option>
  <option style={{fontSize: 15}} value="Mkinga">Mkinga</option>
  <option style={{fontSize: 15}} value="Pangani">Pangani</option>
  <option style={{fontSize: 15}} value="Tanga">Tanga</option>

</Fragment>    
    );
}

const   MorogoroDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 15}} value="Gairo">Gairo</option>
  <option style={{fontSize: 15}} value="Kilombero">Kilombero</option>
  <option style={{fontSize: 15}} value="Kilosa">Kilosa</option>
  <option style={{fontSize: 15}} value="Morogoro Urban">Morogoro Urban</option>
  <option style={{fontSize: 15}} value="Mvomero">Mvomero</option>
  <option style={{fontSize: 15}} value="Ulanga">Ulanga</option>

  </Fragment>    
    );
}

const   PembaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 15}} value="Chake Chake">Chake Chake</option>
  <option style={{fontSize: 15}} value="Mkoani">Mkoani</option>
  <option style={{fontSize: 15}} value="Micheweni">Micheweni</option>
  <option style={{fontSize: 15}} value="Wete">Wete</option>

  </Fragment>    
    );
}

const   IringaDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 15}} value="Iringa District Council">Iringa District Council</option>
  <option style={{fontSize: 15}} value="Kilolo District Council">Kilolo District Council</option>
  <option style={{fontSize: 15}} value="Mufindi District Council">Mufindi District Council</option>
  <option style={{fontSize: 15}} value="Iringa Municipal Council">Iringa Municipal Council</option>
  <option style={{fontSize: 15}} value="Mafinga Town Council">Mafinga Town Council</option>

</Fragment>    
    );
}

const   KigomaDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 15}} value="Buhigwe">Buhigwe</option>
  <option style={{fontSize: 15}} value="Kakonko">Kakonko</option>
  <option style={{fontSize: 15}} value="Kasulu Rural">Kasulu Rural</option>
  <option style={{fontSize: 15}} value="Kasulu Urban">Kasulu Urban</option>
  <option style={{fontSize: 15}} value="Kibondo">Kibondo</option>
  <option style={{fontSize: 15}} value="Kigoma Rural">Kigoma Rural</option>
  <option style={{fontSize: 15}} value="Kigoma Urban">Kigoma Urban</option>
  <option style={{fontSize: 15}} value="Uvinza">Uvinza</option>

</Fragment>    
    );
}

const   LindiDistricts = ({props}) => {
    return (
<Fragment>

  <option style={{fontSize: 15}} value="Kilwa">Kilwa</option>
  <option style={{fontSize: 15}} value="Lindi District">Lindi District</option>
  <option style={{fontSize: 15}} value="Liwale">Liwale</option>
  <option style={{fontSize: 15}} value="Nachingwea">Nachingwea</option>
  <option style={{fontSize: 15}} value="Ruangwa">Ruangwa</option>


</Fragment>    
    );
}

const   MaraDistricts = ({props}) => {
    return (
<Fragment>

  <option style={{fontSize: 15}} value="Bunda">Bunda</option>
  <option style={{fontSize: 15}} value="Butiama">Butiama</option>
  <option style={{fontSize: 15}} value="Musoma Rural">Musoma Rural</option>
  <option style={{fontSize: 15}} value="Musoma Urban">Musoma Urban</option>
  <option style={{fontSize: 15}} value="Rorya">Rorya</option>
  <option style={{fontSize: 15}} value="Serengeti">Serengeti</option>
  <option style={{fontSize: 15}} value="Tarime">Tarime</option>


</Fragment>    
    );
}

const   UngujaDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 15}} value="Kaskazini A ">Kaskazini A </option>
  <option style={{fontSize: 15}} value="Kaskazini B">Kaskazini B</option>

</Fragment>    
    );
}

const   MtwaraDistricts = ({props}) => {
    return (
<Fragment>

  <option style={{fontSize: 15}} value="Masasi">Masasi</option>
  <option style={{fontSize: 15}} value="Mtwara District">Mtwara District</option>
  <option style={{fontSize: 15}} value="Nanyumbu">Nanyumbu</option>
  <option style={{fontSize: 15}} value="Newala">Newala</option>
  <option style={{fontSize: 15}} value="Tandahimba">Tandahimba</option>


</Fragment>    
    );
}

const   NjombeDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 15}} value="Ludewa District">Ludewa District</option>
  <option style={{fontSize: 15}} value="Makambako Town Council">Makambako Town Council</option>
  <option style={{fontSize: 15}} value="Makete District">Makete District</option>
  <option style={{fontSize: 15}} value="Njombe District Council">Njombe District Council</option>
  <option style={{fontSize: 15}} value="Njombe Town Council">Njombe Town Council</option>
  <option style={{fontSize: 15}} value="Wangingombe">Wanging'ombe</option>


</Fragment>    
);
}

const   RukwaDistricts = ({props}) => {
    return (
<Fragment>

  <option style={{fontSize: 15}} value="Kalambo">Kalambo</option>
  <option style={{fontSize: 15}} value="Lyamba lya Mfipa">Lyamba lya Mfipa</option>
  <option style={{fontSize: 15}} value="Nkasi">Nkasi</option>
  <option style={{fontSize: 15}} value="Sumbawanga">Sumbawanga</option>


</Fragment>    
);
}

const   RuvumaDistricts = ({props}) => {
return (
<Fragment>

  <option style={{fontSize: 15}} value="Mbinga">Mbinga</option>
  <option style={{fontSize: 15}} value="Namtumbo">Namtumbo</option>
  <option style={{fontSize: 15}} value="Nyasa">Nyasa</option>
  <option style={{fontSize: 15}} value="Songea Municipal">Songea Municipal</option>
  <option style={{fontSize: 15}} value="Songea Rural">Songea Rural</option>
  <option style={{fontSize: 15}} value="Tunduru">Tunduru</option>


</Fragment>    
);
}

const   SingidaDistricts = ({props}) => {
return (
<Fragment>

  <option style={{fontSize: 15}} value="Iramba">Iramba</option>
  <option style={{fontSize: 15}} value="Ikungi">Ikungi</option>
  <option style={{fontSize: 15}} value="Manyoni">Manyoni</option>
  <option style={{fontSize: 15}} value="Mkalama">Mkalama</option>
  <option style={{fontSize: 15}} value="Singida District">Singida District</option>
  <option style={{fontSize: 15}} value="Singida Municipality">Singida Municipality</option>
  <option style={{fontSize: 15}} value="Itigi">Itigi</option>


</Fragment>    
);
}

const   ShinyangaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 15}} value="Kahama Rural">Kahama Rural</option>
  <option style={{fontSize: 15}} value="Kahama Urban">Kahama Urban</option>
  <option style={{fontSize: 15}} value="Kishapu">Kishapu</option>
  <option style={{fontSize: 15}} value="Shinyanga Rural">Shinyanga Rural</option>
  <option style={{fontSize: 15}} value="Shinyanga Urban">Shinyanga Urban</option>


    </Fragment>    
    );
}


const   SongweDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 15}} value="Ileje District">Ileje District</option>
  <option style={{fontSize: 15}} value="Mbozi District">Mbozi District</option>
  <option style={{fontSize: 15}} value="Momba District">Momba District</option>
  <option style={{fontSize: 15}} value="Songwe District">Songwe District</option>

 </Fragment>    
    );
}

const   GeitaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 15}} value="Bukombe">Bukombe</option>
  <option style={{fontSize: 15}} value="Chato">Chato</option>
  <option style={{fontSize: 15}} value="Geita">Geita</option>
  <option style={{fontSize: 15}} value="Mbogwe">Mbogwe</option>
  <option style={{fontSize: 15}} value="Nyanghwale">Nyang'hwale</option>

   </Fragment>    
    );
}

const   KataviDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 15}} value="Nsimbo">Nsimbo</option>
  <option style={{fontSize: 15}} value="Mpanda">Mpanda</option>
  <option style={{fontSize: 15}} value="Mlele">Mlele</option>
  </Fragment>    
    );
}



const   ManyaraDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 15}} value="Babati Rural">Babati Rural</option>
  <option style={{fontSize: 15}} value="Babati Town">Babati Town</option>
  <option style={{fontSize: 15}} value="Kiteto">Kiteto</option>
  <option style={{fontSize: 15}} value="Hanang">Hanang</option>
  <option style={{fontSize: 15}} value="Mbulu">Mbulu</option>
  <option style={{fontSize: 15}} value="Simanjiro">Simanjiro</option>

  </Fragment>    
    );
}

const   SimiyuDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 15}} value="Bariadi">Bariadi</option>
  <option style={{fontSize: 15}} value="Busega">Busega</option>
  <option style={{fontSize: 15}} value="Itilima">Itilima</option>
  <option style={{fontSize: 15}} value="Maswa">Maswa</option>
  <option style={{fontSize: 15}} value="Meatu">Meatu</option>

  </Fragment>    
    );
}

const   TaboraDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 15}} value="Igunga">Igunga</option>
  <option style={{fontSize: 15}} value="Kaliua">Kaliua</option>
  <option style={{fontSize: 15}} value="Nzega">Nzega</option>
  <option style={{fontSize: 15}} value="Sikonge"> Sikonge</option>
  <option style={{fontSize: 15}} value="Tabora Municipal">Tabora Municipal</option>
  <option style={{fontSize: 15}} value="Urambo">Urambo</option>
  <option style={{fontSize: 15}} value="Uyui">Uyui</option>

  </Fragment>    
    );
}


const TanzaniaDistrictList = ({props,region}) => {


useEffect(()=>{




},[region])



if(region==="Dar_es_salaam"){
return (<DarEsSalaamDistricts />);
} 
else if(region==="Dodoma"){
    return (<DodomaDistricts />);
} 
else if(region==="Mwanza"){
    return (<MwanzaDistricts />);
}
else if(region==="Mbeya"){
    return (<MbeyaDistricts />);
}
else if(region==="Arusha"){
    return (<ArushaDistricts />);
}
else if(region==="Kilimanjaro"){
    return (<KilimanjaroDistricts />);
}
else if(region==="Pwani"){
    return (<PwaniDistricts />);
}
else if(region==="Tanga"){
    return (<TangaDistricts />);
}
else if(region==="Morogoro"){
    return (<MorogoroDistricts />);
}
else if(region==="Pemba"){
    return (<PembaDistricts />);
}
else if(region==="Iringa"){
    return (<IringaDistricts />);
}
else if(region==="Kigoma"){
    return (<KigomaDistricts />);
}
else if(region==="Lindi"){
    return (<LindiDistricts />);
}
else if(region==="Mara"){
    return (<MaraDistricts />);
}
else if(region==="Unguja"){
    return (<UngujaDistricts />);
}
else if(region==="Mtwara"){
    return (<MtwaraDistricts />);
}
else if(region==="Njombe"){
    return (<NjombeDistricts />);
}
else if(region==="Rukwa"){
    return (<RukwaDistricts />);
}
else if(region==="Ruvuma"){
    return (<RuvumaDistricts />);
}
else if(region==="Singida"){
    return (<SingidaDistricts />);
}
else if(region==="Shinyanga"){
    return (<ShinyangaDistricts />);
}
else if(region==="Songwe"){
    return (<SongweDistricts />);
}
else if(region==="Geita"){
    return (<GeitaDistricts />);
}
else if(region==="Katavi"){
    return (<KataviDistricts />);
}
else if(region==="Kigoma"){
    return (<KigomaDistricts />);
}
else if(region==="Manyara"){
    return (<ManyaraDistricts />);
}
else if(region==="Simiyu"){
    return (<SimiyuDistricts />);
}
else if(region==="Tabora"){
    return (<TaboraDistricts />);
}
else{
    return null
}




   
}

const ProductCategoryComponent = ()=>{
    //----ATTENSTION --
   //+ == &
   // _ == empty space
   // - === ,
return(
<Fragment>
  <option style={{fontSize: 15}} value="vehicle+motorbike">vehicle&amp; Motorbike</option>
  <option style={{fontSize: 15}} value="fashion+apparel">fashion &amp;apparel</option>
  <option style={{fontSize: 15}} value="home+furniture">homes&amp;furniture</option>
  <option style={{fontSize: 15}} value="electronics">electronics</option>
  <option style={{fontSize: 15}} value="beauty-healthcare+medicals">beauty,healthcare&amp; medicals</option>
  <option style={{fontSize: 15}} value="food-groceries+fruits">food_groceries&amp;fruits</option>
  <option style={{fontSize: 15}} value="books+stationary">books &amp;stationary</option>
  <option style={{fontSize: 15}} value="kids+toys">kids &amp;toys equipment</option>
  <option style={{fontSize: 15}} value="electricity+tellecoms">electricity &amp;tellecoms</option>
  <option style={{fontSize: 15}} value="security+safety">security &amp;safety</option>
  <option style={{fontSize: 15}} value="agriculture+farming">agriculture &amp; farming </option>
  <option style={{fontSize: 15}} value="sports+entertainment">sports &amp;entertainment</option>
  <option style={{fontSize: 15}} value="others">others category</option>
</Fragment>     
)
}



const ProductSubCategory = ({props,category})=>{


const AutoTransportationBike = ()=>{
    //----ATTENSTION --
   //+ == &
   // _ == empty space
   // - === ,
return(
   <Fragment>

  <option style={{fontSize: 15}} value="automobiles">Automobiles</option>
  <option style={{fontSize: 15}} value="transportations">Transportations</option>
  <option style={{fontSize: 15}} value="garage+accessories">Garage &amp; Accessories</option>
  <option style={{fontSize: 15}} value="motors+bikes">Motors &amp; Bike</option>
  <option style={{fontSize: 15}} value="vehicles+tools">Vehicles Tools</option>
  <option style={{fontSize: 15}} value="vehicle-care+cleaning">Vehicle Care &amp; Cleaning</option>
  <option style={{fontSize: 15}} value="vehicles+electrical_system">Vehicles Electrical System</option>
  <option style={{fontSize: 15}} value="vehicles+electronics">Vehicles Electronics</option>
  <option style={{fontSize: 15}} value="others">Others Categories</option>
</Fragment>

)
}

const FashionApparel = ()=>{
    //----ATTENSTION --
   //+ == &
   // _ == empty space
   // - === ,
return(
<Fragment>
  <option style={{fontSize: 15}} value="womens_fashions">(women) Fashions</option>
  <option style={{fontSize: 15}} value="mens_fashions">(men) Fashions</option>
  <option style={{fontSize: 15}} value="kids_fashions">(Kids) Fashions</option>
</Fragment>
    
    )
}

const HomeGarden = ()=>{
       //----ATTENSTION --
   //+ == &
   // _ == empty space
   // - === ,
    return(
<Fragment>
  <option style={{fontSize: 15}} value="kitchen+dining">Kitchen &amp; Dining</option>
  <option style={{fontSize: 15}} value="home+decor">Home Decor</option>
  <option style={{fontSize: 15}} value="lightings">Lightings</option>
  <option style={{fontSize: 15}} value="smart_home+automation">Smart Home Automation</option>
  <option style={{fontSize: 15}} value="garden+supplies">Garden Supplies</option>
  <option style={{fontSize: 15}} value="furniture">Furniture</option>
  <option style={{fontSize: 15}} value="house-cleaning+laundry">House Cleaning &amp; Laundry</option>
  <option style={{fontSize: 15}} value="household+sundries">Household Sundries</option>
  <option style={{fontSize: 15}} value="barware">Barware</option>
  <option style={{fontSize: 15}} value="home+textile">Home Textile</option>
  <option style={{fontSize: 15}} value="others">Others Categories</option>
</Fragment>
    
    )
}
const Electronics = ()=>{
    
   //----ATTENSTION --
   //+ == &
   // _ == empty space
   // - === ,

    return(
<Fragment>
  <option style={{fontSize: 15}} value="mobile_phones+tablets">Mobile Phones &amp; Tablets</option>
  <option style={{fontSize: 15}} value="mobile_phones+accessories">Mobile Phones Accessories</option>
  <option style={{fontSize: 15}} value="computers+desktop">Computers ,&amp; Desktop</option>  
  <option style={{fontSize: 15}} value="computers_accessories">Computers Accessories</option>
  <option style={{fontSize: 15}} value="wearables+smartwatches">Wearables &amp; Smart Watches</option>
  <option style={{fontSize: 15}} value="tv-music+home_theater">Tv,Music and Home Theater</option>
  <option style={{fontSize: 15}} value="cctv-drones+security">Cctv,Drones and Security</option>
  <option style={{fontSize: 15}} value="digital_cameras+comecoders">Digital Camerasand Comecoders</option>
  <option style={{fontSize: 15}} value="office+equipment_supplies">Office Equipment and Supplies</option>
  <option style={{fontSize: 15}} value="bluetooth-speakers+headphones">Bluetooth,Speakers and Headphones</option>
  <option style={{fontSize: 15}} value="homes+kitchen_equipments">Homes&amp;Kitchen Equipmements</option>
  <option style={{fontSize: 15}} value="others">Others Categories</option>

</Fragment>
    
    )
}

const BeautyHealthMedical = ()=>{


   //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,

    return(
<Fragment>
  <option style={{fontSize: 15}} value="health+nutrition">Health &amp; Nutrition</option>
  <option style={{fontSize: 15}} value="hair-extensions+wigs">Hair Extensions &amp; Wigs</option>
  <option style={{fontSize: 15}} value="fragrances+perfumes">Fragrances &amp; Perfumes</option>
  <option style={{fontSize: 15}} value="medication">Medication</option>
  <option style={{fontSize: 15}} value="herbal+foods_supplements">Herbal&amp;foods supplement</option>
  <option style={{fontSize: 15}} value="hair-saloon+equipmements">Hair Saloon Equipmements</option>
  <option style={{fontSize: 15}} value="feminine+hygiene">Feminine Hygiene</option>
  <option style={{fontSize: 15}} value="nail_supplies">Nail Supplies</option>
  <option style={{fontSize: 15}} value="makeups_beauty">Makeups &amp; Beauty</option>
  <option style={{fontSize: 15}} value="skin-body+care">Skin &amp; Body Care</option>
  <option style={{fontSize: 15}} value="sanitary_paper">Sanitary Paper</option>
  <option style={{fontSize: 15}} value="others">Others Categories</option>
</Fragment>
    
    )
}

const FoodsGroceryFruits= ()=>{
    //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
    return(
<Fragment>

  <option style={{fontSize: 15}} value="food">Food</option>
  <option style={{fontSize: 15}} value="beverage">Beverage</option>
  <option style={{fontSize: 15}} value="bakery_goods">Bakery Goods</option>
  <option style={{fontSize: 15}} value="vegetable_products">Vegetable Products</option>
  <option style={{fontSize: 15}} value="seasoning_candiments">Seasoning &amp; Candiments</option>
  <option style={{fontSize: 15}} value="sea_food">Sea Food</option>
  <option style={{fontSize: 15}} value="meat_poultry">Meat &amp; Poultry</option>
  <option style={{fontSize: 15}} value="fruits_products">Fruits Products</option>
  <option style={{fontSize: 15}} value="eggs+eggs_products">Eggs &amp; Eggs Products</option>
  <option style={{fontSize: 15}} value="honey+products">Honey Products</option>
  <option style={{fontSize: 15}} value="others">Others Categories</option>

</Fragment>
    
    )
}

const BooksStationary = ()=>{
    //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
    return(
<Fragment>

<option style={{fontSize: 15}} value="books">Books</option>
<option style={{fontSize: 15}} value="stationary_accessories">Stationary Accessories</option>
<option style={{fontSize: 15}} value="arts_crafts">Arts &amp; Crafts</option>
<option style={{fontSize: 15}} value="others">Others Categories</option>


</Fragment>
    
    )
}

const ElectricityTellecoms = ()=>{
    //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
    return(
<Fragment>
<option style={{fontSize: 15}} value="electrical+equipmentssupplies">Electrical Equipments Supplies</option>
<option style={{fontSize: 15}} value="telecommunications">Telecommunications</option>
<option style={{fontSize: 15}} value="others">other categories</option>
</Fragment>
    
    )
}


const SecuritySafety = ()=>{
    //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
    return(
<Fragment>
<option style={{fontSize: 15}} value="security_protections">Security &amp; Protections</option>
<option style={{fontSize: 15}} value="work_place-safety+supplies">Work Place Safety Supplies</option>
<option style={{fontSize: 15}} value="fire_fighting+supplies">Fire Fighting Supplies</option>
<option style={{fontSize: 15}} value="others">other categories</option>
</Fragment>
    
    )
}

const Agriculture = ()=>{
    //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
    return(
<Fragment>
   <option style={{fontSize: 15}} value="raw_materials">Raw Materials</option>
  <option style={{fontSize: 15}} value="animals">Animals</option>
  <option style={{fontSize: 15}} value="poultry">Poultry</option>
  <option style={{fontSize: 15}} value="forestry">Forestry</option>
  <option style={{fontSize: 15}} value="raw_fruits">Raw Fruits</option>
  <option style={{fontSize: 15}} value="others">other categories</option>
</Fragment>
    
    )
}

const SportsEntertainments = ()=>{
   //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
    return(
<Fragment>
  
  <option style={{fontSize: 15}} value="video_games+console">Video Games &amp; Console</option>
  <option style={{fontSize: 15}} value="fitness+body_building">Fitness &amp; Body Building</option>
  <option style={{fontSize: 15}} value="indoor_sports">Indoor Sports</option>
  <option style={{fontSize: 15}} value="team_sports">Team Sports</option>
  <option style={{fontSize: 15}} value="amusements_parks">Amusements Parks</option>
  <option style={{fontSize: 15}} value="outdoor_sports">Outdoor Sports</option>
  <option style={{fontSize: 15}} value="water_sports">Water Sports</option>
  <option style={{fontSize: 15}} value="sports_wear">Sports Wear</option>
  <option style={{fontSize: 15}} value="music">Music</option>
  <option style={{fontSize: 15}} value="sports_shoes">Sports Shoes</option>
  <option style={{fontSize: 15}} value="others">other categories</option>
</Fragment>
    
    )
}



useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
        
        
         
        
        
return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------
        
        
        
        
        
}
},[category]) 



if(category==="vehicle+motorbike"){

  return AutoTransportationBike()  
}else if(category==="fashion+apparel"){
    

return FashionApparel()
}
else if(category==="home+furniture"){
    

return HomeGarden()
}
else if(category==="electronics"){
    

    return Electronics()
    
}
else if(category==="beauty-healthcare+medicals"){
    

    return BeautyHealthMedical()
    
    
}
else if(category==="electricity+tellecoms"){
    

    return ElectricityTellecoms()
  
    
}
else if(category==="agriculture+farming"){
    

    return Agriculture()
  
  
    
}
else if(category==="sports+entertainment"){
    

    return SportsEntertainments()
    
  
    
}
else if(category==="security+safety"){
    

    return SecuritySafety()
    
  
    
}
else if(category==="books+stationary"){
    

    return BooksStationary()
    
  
    
}
else if(category==="food-groceries+fruits"){
    

    return FoodsGroceryFruits()
}
else if(category==="kids+toys"){
    

return (<option style={{fontSize: 15}} value="kids_toys+accessories">kids toys accessories</option>)
}

else if(category==="others"){
    

return (<option style={{fontSize: 15}} value="others">others</option>)
}

else{
return null
}
    
    
    
    
    
}





const InnerCategory = ({props,sub_category})=>{

//--Listens for all fashion categories
   //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
 


const WomenFashions = ()=>{
       //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
return(
    <Fragment>
    <option style={{fontSize: 15}} value="women_clothes">(women) clothes</option>
    <option style={{fontSize: 15}} value="women_shoes">(women) shoes</option>
    <option style={{fontSize: 15}} value="women_bags+luggage">(women) bags,purse&amp;luggage</option>
    <option style={{fontSize: 15}} value="women_watches_jewels+glasses">(women) watches,jewels&amp;glasses</option>
    <option style={{fontSize: 15}} value="women_fashion+accessories">(women) others fashion accessories</option>
   </Fragment>
            
    )
}

const MensFashions = ()=>{
    //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
    return(
        <Fragment>
        <option style={{fontSize: 15}} value="men_clothes">(men) clothes</option>
        <option style={{fontSize: 15}} value="men_shoes">(men)  shoes</option>
        <option style={{fontSize: 15}} value="men_bags+luggage">(men)  bags&amp;luggage</option>
        <option style={{fontSize: 15}} value="men_watches-ewels+glasses">(men) watches,jewels&amp;glasses</option>
        <option style={{fontSize: 15}} value="men_fashion+accessories">(men) others fashion accessories</option>
       </Fragment>
                
        )
}

const KidsFashions = ()=>{
       //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
    return(
        <Fragment>
        <option style={{fontSize: 15}} value="kids_clothes">(kids) clothes</option>
        <option style={{fontSize: 15}} value="kids_shoes">(kids)  shoes</option>
        <option style={{fontSize: 15}} value="kids_bags+luggage">(kids)  bags&amp;luggage</option>
        <option style={{fontSize: 15}} value="kids_watches+jewels">(kids) watches&amp;jewels </option>
        <option style={{fontSize: 15}} value="kids_fashion+accessories">(kids) others fashion accessories</option>
       </Fragment>
                
        )
}

    
useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
            
            
             
            
            
return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------
            
            
            
            
            
}
},[sub_category]) 
    
    

     
    
if(sub_category==="womens_fashions"){
    
return <WomenFashions /> 

}else if(sub_category==="mens_fashions"){
        
    
return <MensFashions/>
}
else if(sub_category==="kids_fashions"){
        
    
return <KidsFashions />
}
else{

return null

}
        
        
}
        



const InnerMostCategory = ({props,inner_category})=>{

    //--Listens for all fashion categories



    
    const MensClothes = ()=>{
    return(
        <Fragment>
<option style={{fontSize: 15}} value="men_sets">(men) sets</option>
<option style={{fontSize: 15}} value="men_shirts">(men) shirts</option>
<option style={{fontSize: 15}} value="men_tshirts">(men) t_shirt</option>
<option style={{fontSize: 15}} value="men_jeans">(men)  jeans</option>
<option style={{fontSize: 15}} value="men_shorts">(men)  shorts</option>
<option style={{fontSize: 15}} value="men_suits">(men)  suits</option>
<option style={{fontSize: 15}} value="men_coat+jackets">(men)  coats&amp;jackets</option>
<option style={{fontSize: 15}} value="men_pants">(men)  pants</option>
<option style={{fontSize: 15}} value="men_activetracksuits">(men)  activetracksuits </option>
<option style={{fontSize: 15}} value="men_swimwear">(men)  swimwear </option>
<option style={{fontSize: 15}} value="men_underwear+boxers">(men) underwear&amp;boxers </option>
<option style={{fontSize: 15}} value="men_sweaters+hoodies">(men)  sweaters&amp;hoodies</option>
<option style={{fontSize: 15}} value="men_sockes+footwear">(men)  socks&amp;footwear</option>
<option style={{fontSize: 15}} value="men_clothes+accessories">(men)  others clothes accessories</option>
       </Fragment>
                
        )
    }
    
 


    const MenShoes = ()=>{
        //--Listens for all fashion categories
   //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
 
        return(
            <Fragment>
         <option style={{fontSize: 15}} value="men_sneakers">(men) sneakers</option>
<option style={{fontSize: 15}} value="men_sandals+flipflops">(men) sandals &amp; flipflops</option>
<option style={{fontSize: 15}} value="men_slippers">(men) slippers</option>
<option style={{fontSize: 15}} value="men_canvas+shoes">(men) canvas shoes</option>
<option style={{fontSize: 15}} value="men_boots">(men) boots</option>
<option style={{fontSize: 15}} value="men_office_shoes">(men) office shoes</option>
<option style={{fontSize: 15}} value="men_doug_shoes">(men) doug shoes </option>
<option style={{fontSize: 15}} value="men_loafers">(men) loafers </option>
<option style={{fontSize: 15}} value="men_shoes+accessories">(men) others shoes accessories</option>
           </Fragment>
                    
            )
        }
    
   
    
const MenWatchesJewelsGlasses = ()=>{
    //--Listens for all fashion categories
   //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
 

            return(
                <Fragment>
                <option style={{fontSize: 15}} value="men_watches">(men)  watches</option>
                <option style={{fontSize: 15}} value="men_glasses">(men) glasses</option>
                <option style={{fontSize: 15}} value="men_necklaces">(men) necklace</option>
                <option style={{fontSize: 15}} value="men_bracelets">(men) bracelets</option>
                <option style={{fontSize: 15}} value="men_rings">(men) rings</option>
                <option style={{fontSize: 15}} value="men_earings">(men) earings</option>
                <option style={{fontSize: 15}} value="men_jewels+accessories">(men) others jewels accessories</option>
               </Fragment>
                        
                )
     }    


 
const MenLuggageBags = ()=>{
         //--Listens for all fashion categories
   //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
 
        return(
            <Fragment>
<option style={{fontSize: 15}} value="men_shoulder_bags">shoulder bags</option>
<option style={{fontSize: 15}} value="men_travelling_bags">travelling  bags</option>
<option style={{fontSize: 15}} value="men_handle_bags">handle bags</option>
<option style={{fontSize: 15}} value="men_briefcase">briefcase</option>
<option style={{fontSize: 15}} value="men_waist_packs">waist packs</option>
<option style={{fontSize: 15}} value="men_wallets+clutches">wallets&amp; clutches</option>
<option style={{fontSize: 15}} value="men_bags+luggage_accessories">others luggages accessories</option>

           </Fragment>
                    
            )
    }
        
        
    

    
    const WomenClothes = ()=>{
    //--Listens for all fashion categories
   //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
        return(
            <Fragment>
        <option style={{fontSize: 15}} value="women_skirts+miniskirts">(women) skirts &amp; miniskirts</option>
<option style={{fontSize: 15}} value="women_shirts+blouse">(women)  shirts &amp; blouse</option>
<option style={{fontSize: 15}} value="women_jeans">(women)  jeans &amp; boyfriendjeans</option>
<option style={{fontSize: 15}} value="women_sweaters+hoodies">(women)  sweaters&amp; hoodies</option>
<option style={{fontSize: 15}} value="women_shorts">(women) shorts</option>
<option style={{fontSize: 15}} value="women_pants+sweatpants">(women) pants &amp; sweatpants</option>
<option style={{fontSize: 15}} value="women_coats+jackets">(women) coats&amp;jackets</option>
<option style={{fontSize: 15}} value="women_bra-brassiere">(women) bra/brassiere</option>
<option style={{fontSize: 15}} value="women_swimwear">(women) swimwear </option>
<option style={{fontSize: 15}} value="women_wedding_dresses">(women) wedding dresses</option>
<option style={{fontSize: 15}} value="women_dresses">(women) dresses </option>
<option style={{fontSize: 15}} value="women_nightwear">(women) night wear</option>
<option style={{fontSize: 15}} value="women_clothes+accessories">(women) others clothes accessories</option>

           </Fragment>
                    
            )
    }   

 
   
    const WomenShoes = ()=>{
 //--Listens for all fashion categories
   //----ATTENSTION --
   //+ means &
   // _ means  empty space
   // - means ,
        return(
            <Fragment>
      <option style={{fontSize: 15}} value="women_sneakers">(women) sneakers</option>
<option style={{fontSize: 15}} value="women_heels">(women)  heels &amp;highheels</option>
<option style={{fontSize: 15}} value="women_sandals+flipflops">(women) sandals &amp; flipflops</option>
<option style={{fontSize: 15}} value="women_nude_shoes">(women) nude shoes</option>
<option style={{fontSize: 15}} value="women_canvas_shoes">(women) canvas shoes</option>
<option style={{fontSize: 15}} value="women_loafers">(women) loafers</option>
<option style={{fontSize: 15}} value="women_boots">(women) boots</option>
<option style={{fontSize: 15}} value="women_slippers">(women) slippers</option>
<option style={{fontSize: 15}} value="women_doug+shoes">(women) doug shoes</option>
<option style={{fontSize: 15}} value="women_shoes+accessories">(women) others shoes accessories</option>

</Fragment>
                    
            )
    }   

   
    const WomenLuggageBags = ()=>{
        return(
            <Fragment>
           <option style={{fontSize: 15}} value="women_shoulder_bags">(women) shoulder bags</option>
<option style={{fontSize: 15}} value="women_sets">(women) sets&amp;purse</option>
<option style={{fontSize: 15}} value="women_wallets+purse">(women) wallets&amp;purse</option>
<option style={{fontSize: 15}} value="women_travelling_bags">(women) travelling bags</option>
<option style={{fontSize: 15}} value="women_handle_bags">(women) handle bags</option>
<option style={{fontSize: 15}} value="women_clutches">(women)  clutches</option>
<option style={{fontSize: 15}} value="women_back_packs">(women)  back packs</option>
<option style={{fontSize: 15}} value="women_cross_body_bags">(women) cross body bags</option>
<option style={{fontSize: 15}} value="women_bags_accessories">(women) others bags accessories</option>

           </Fragment>
                    
            )
    }   

    const WomenWatchesJewelsGlasses = ()=>{
        return(
            <Fragment>
         <option style={{fontSize: 15}} value="women_watches">(women) watches</option>
<option style={{fontSize: 15}} value="women_glasses">(women) glasses</option>
<option style={{fontSize: 15}} value="women_necklaces">(women) necklaces</option>
<option style={{fontSize: 15}} value="women_earings">(women) earings</option>
<option style={{fontSize: 15}} value="women_rings">(women) rings</option>
<option style={{fontSize: 15}} value="women_jewelry_sets">(women) jewerly sets</option>
<option style={{fontSize: 15}} value="women_bracelets">(women) bracelets</option>
<option style={{fontSize: 15}} value="women_anklets">(women) anklets</option>
<option style={{fontSize: 15}} value="women_jewels_accessories">(women) others jewels accessories</option>

           </Fragment>
                    
            )
    }   
  

  
    

 
    
     
  
    const KidsClothes = ()=>{
        return(
            <Fragment>
 <option style={{fontSize: 15}} value="kids_skirts">(kids)  skirts</option>
<option style={{fontSize: 15}} value="kids_shirts+blouse">(kids) kids shirts &amp; blouse</option>
<option style={{fontSize: 15}} value="kids_jeans">(kids) jeans</option>
<option style={{fontSize: 15}} value="kids_sweaters+hoodies">(kids) sweaters&amp; hoodies</option>
<option style={{fontSize: 15}} value="kids_coats+jackets">(kids) coats&amp;jackets</option>
<option style={{fontSize: 15}} value="kids_dresses">(kids) kids dresses </option>
<option style={{fontSize: 15}} value="kids_clothes+accessories">(kids) others clothes accessories</option>


           </Fragment>
                    
            )
    }   



    const KidsShoes = ()=>{
        return(
            <Fragment>
       <option style={{fontSize: 15}} value="kids_sneakers">(kids) sneakers</option>
<option style={{fontSize: 15}} value="kids_sandals+flipflops">(kids) sandals&amp; flipflops</option>
<option style={{fontSize: 15}} value="kids_nude_shoes">(kids) nude shoes</option>
<option style={{fontSize: 15}} value="kids_canvas_shoes">(kids) canvas shoes</option>
<option style={{fontSize: 15}} value="kids_loafers">(kids) loafers</option>
<option style={{fontSize: 15}} value="kids_boots">(kids) boots</option>
<option style={{fontSize: 15}} value="kids_slippers">(kids)slippers</option>
<option style={{fontSize: 15}} value="kids_shoes_accessories">(kids) others shoes accessories</option>

           </Fragment>
                    
            )
    }  
    
    

    
    const KidsLuggageBags = ()=>{
        return(
            <Fragment>
            <option style={{fontSize: 15}} value="kids_bags">(kids) bags</option>
            <option style={{fontSize: 15}} value="kids_bags+luggage-accessories">(kids) others accessories</option>
           </Fragment>
                    
            )
    }
        

    const KidsWatchesJewelsGlasses = ()=>{
        return(
            <Fragment>
            <option style={{fontSize: 15}} value="kids_watches">(kids) watches</option>
            <option style={{fontSize: 15}} value="kids_glasses">(kids) glasses</option>
            <option style={{fontSize: 15}} value="kids_necklaces">(kids) necklaces</option>
            <option style={{fontSize: 15}} value="kids_bracelets">(kids) bracelets</option>
            <option style={{fontSize: 15}} value="kids_jewels-accessories">(kids) others jewels accessories</option>
           </Fragment>
                    
            )
    }  
        
    
 


useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
                
                
                 
                
                
    return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------
                
                
                
                
                
    }
    },[inner_category]) 
        


    // <option style={{fontSize: 15}} value="kids-clothes">clothes</option>
    // <option style={{fontSize: 15}} value="kids-shoes">shoes</option>
    // <option style={{fontSize: 15}} value="kids-bags+luggage">bags&amp;luggage</option>
    // <option style={{fontSize: 15}} value="kids-watches+jewels">watches&amp;jewels </option>
    // <option style={{fontSize: 15}} value="kids-fashion+accessories">others kids accessories</option>
         
        
if(inner_category ==='men_clothes'){
        

    return <MensClothes /> 
}else if(inner_category ==='men_shoes'){
        

    return <MenShoes /> 
}else if(inner_category ==='men_bags+luggage'){
        

    return <MenLuggageBags /> 

}else if(inner_category ==='men_watches_jewels+glasses'){
        

    return <MenWatchesJewelsGlasses /> 
}else if(inner_category ==='men_fashion+accessories'){
        

    return (<option style={{fontSize: 15}} value="men_fashion+accessories">Others fashion accessories</option> )

}
//-----------------------------------------------------------------
else if(inner_category ==='women_clothes'){ //-- women fashion listens
        

    return <WomenClothes />
}
else if(inner_category ==='women_shoes'){
        

    return <WomenShoes />
}
else if(inner_category ==='women_bags+luggage'){
        

    return <WomenLuggageBags />
}
else if(inner_category ==='women_watches_jewels+glasses'){
        

    return <WomenWatchesJewelsGlasses />
}

//---------------------------------------------------------
else if(inner_category ==='kids_clothes'){ //--KIDS FASHION LISTENS
        

    return <KidsClothes />
}
else if(inner_category ==='kids_shoes'){ 
        

    return <KidsShoes />
}
else if(inner_category ==='kids_bags+luggage'){ 
        

    return <KidsLuggageBags />
}
else if(inner_category ==='kids_watches+jewels'){ 
        

    return <KidsWatchesJewelsGlasses />
}
else if(inner_category ==='kids_fashion+accessories'){
        

    return (<option style={{fontSize: 15}} value="kids_fashion+accessories">others kids accessories</option>    )
}

else{
    
    return null
    
    }
            
            
}
            
         
        
    
    

  
  
    




















export {
TanzaniaDistrictList,
TanzaniaRegionsList,
DarEsSalaamDistricts,
DodomaDistricts,
MwanzaDistricts,
MbeyaDistricts,
ArushaDistricts,
KigomaDistricts,
PwaniDistricts,
TangaDistricts,
MorogoroDistricts,
PembaDistricts,
IringaDistricts,
LindiDistricts,
MaraDistricts,
UngujaDistricts,
MtwaraDistricts,
NjombeDistricts,
RukwaDistricts,
RuvumaDistricts,
SingidaDistricts,
ShinyangaDistricts,
SongweDistricts,
GeitaDistricts,
KataviDistricts,
ManyaraDistricts,
SimiyuDistricts,
TaboraDistricts,


ProductCategoryComponent,
ProductSubCategory,
InnerCategory,
InnerMostCategory

};










