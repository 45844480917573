import React ,{Fragment,useEffect,useContext}from 'react';
import {Link}from 'react-router-dom';
import $ from 'jquery';
import { makeStyles} from '@material-ui/core/styles';
import './NotificationApplication.scss';
import Grid from '@material-ui/core/Grid';
import {CheckCircle,NotificationImportant,Warning} from '@material-ui/icons';
// import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import {DashboardCard,DashboardCardCircle,DashboardCardLogo,DashboardCardText} from "../StyledComponents/styledcomponents";




const useStyles = makeStyles((theme) => ({
     NotificationCard:{
      [theme.breakpoints.down('xs')]:{
        width: '98%',
        top: '55px',
        right: '4px',
        position: 'fixed',
        display:'flex',
        flexDirection: 'row',
        borderRadius:'20px'
       },
       [theme.breakpoints.up('sm')]:{
        width: '60%',
        top: '55px',
        right: '4px',
        position: 'fixed',
        display:'flex',
        flexDirection: 'row',
        borderRadius:'20px' 
       } 
    },

 
}));


    
















const NotificationCloseFunction = (MessageType)=>{

if(MessageType==='success-message'){

  $('#notification-appp-success-message').animate({top:'-140%'},function(){

      $(this).css({visibility:"hidden"})
   })


}
  
if(MessageType==='warning-message'){
$('#notification-appp-warning-message').animate({top:'-140%'},function(){

    $(this).css({visibility:"hidden"})
 }) 

}

if(MessageType==='information-message'){
  $('#notification-appp-information-message').animate({top:'-140%'},function(){
  
      $(this).css({visibility:"hidden"})
   }) 
  
}



}








const NotificationActivate = (message,type,sound=true,disappear=true,duration=60*1000) =>{

  var SucessNotification = $('#notification-appp-success-message')
  var SucessText = $('#notification-appp-success-text')
 

  var InformationNotification = $('#notification-appp-information-message')
  var InformationText = $('#notification-appp-information-text')

  

  var ErrorNotification = $('#notification-appp-error-message')
  var ErrorText = $('#notification-appp-error-text')


  var WarningNotification = $('#notification-appp-warning-message')
  var WarningText = $('#notification-appp-warning-text')
 

  
  if(type==='success'){ //Success Notifications
   SucessNotification.css({visibility:"visible"})
   SucessText.html(message)   
   SucessNotification.animate({top: '60px'})
 
  
  if(sound===true){
    // var audio = new Audio('success.mp3')
    // audio.play()

    
  } 

  if(disappear){
    setTimeout(function(){
      SucessNotification.animate({top:'-140%'},function(){

        SucessNotification.css({visibility:"hidden"})
      })
      
  
       },duration)

  }


  }else if(type==='information'){ //Information Notifications

    InformationNotification.css({visibility:"visible"}) 
    InformationText.html(message)  
    InformationNotification.animate({top: '80px'})
   
   if(sound===true){
    //  var audio = new Audio('success.mp3')
    //  audio.play()

     
   } 

   if(disappear){
     setTimeout(function(){

       InformationNotification.animate({top:'-140%'},function(){

        InformationNotification.css({visibility:"hidden"})
      })
   
    },duration)

   }
  }else if(type==='warning'){ // Warning Notifications

  WarningNotification.css({visibility:"visible"}) 
  WarningText.html(message)  
  WarningNotification.animate({top: '60px'})

  if(sound===true){
    // var audio = new Audio('warning.mp3')
    // audio.play()
  } 

  if(disappear){

      WarningNotification.animate({top:'-140%'},function(){

      WarningNotification.css({visibility:"hidden"})
    })
    
  }
 

  }else if(type==='error'){ // Error Notifications
    ErrorNotification.css({visibility:"visible"}) 
    ErrorText.html(message)  
    ErrorNotification.animate({top: '80px'})

    if(sound===true){
      // var audio = new Audio('warning.mp3')
      // audio.play()
    } 

    if(disappear){
      setTimeout(function(){
          ErrorNotification.animate({top:'-140%'},function(){

          ErrorNotification.css({visibility:"hidden"})
        })
        
        },duration)
    }
   

    }
   

  
  
 
  
}


    
const NotificationApplication = ({props}) => { //--------------MAIN FUNCTION----------------

  const classes = useStyles();


useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  





return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])







return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="notification-application-wrapper">


              <div className={`${classes.NotificationCard} success-notification-card`} id='notification-appp-success-message'>
                 <Grid container spacing={0}> 
                   <Grid item xs={2}><div className='notification-icon-holder'><CheckCircle style={{color:'#fff',fontSize:40}} /></div></Grid>
                   <Grid item xs={9}><div className='notification-text-holder'>
                     <span className='notification-text' id='notification-appp-success-text'>
                       
                     </span> 
                     </div>
                     </Grid>

                 <Grid item xs={1}>
                   <div onClick={()=>{NotificationCloseFunction('success-message')}} className='notification-icon-holder' id='notification-appp-success-message-close'>
                    <Link to="#">X</Link> 
                   </div>
                   </Grid>
  
                    </Grid>
               </div>

              
              
               <div className={`${classes.NotificationCard} warning-notification-card`} id='notification-appp-warning-message'>
                 <Grid container spacing={0}> 
                   <Grid item xs={2}><div className='notification-icon-holder'><Warning style={{color:'#fff',fontSize:40}} /></div></Grid>
                   <Grid item xs={9}>
                     <div className='notification-text-holder'>
                       <span className='notification-text' id='notification-appp-warning-text'>
                        
                         </span> 
                       </div>
                       </Grid>

                 <Grid item xs={1}>
                   <div onClick={(NotificationCloseFunction('warning-message'))} className='notification-icon-holder'>
                    <Link to="#">X</Link> 
                   </div>
                   </Grid>
  
                    </Grid>
               </div>



               <div className={`${classes.NotificationCard} information-notification-card`} id='notification-appp-information-message'>
                 <Grid container spacing={0}> 
                   <Grid item xs={2}><div className='notification-icon-holder'><NotificationImportant style={{color:'#fff',fontSize:40}} /></div></Grid>
                   <Grid item xs={9}><div className='notification-text-holder'>
                     <span className='notification-text' id='notification-appp-information-text'>
                   
                       
                       </span> 
                       </div>
                       </Grid>

                 <Grid item xs={1}>
                   <div  onClick={(NotificationCloseFunction('information-message'))} className='notification-icon-holder'>
                    <Link to="#">X</Link> 
                   </div>
                   </Grid>
  
                    </Grid>
               </div>





</div>
</Fragment>
 
    
);


// NotificationApplication.propTypes = {}
        
// NotificationApplication.defaultProps ={}


}
export {NotificationApplication,NotificationActivate,NotificationCloseFunction};

