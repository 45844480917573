import React ,{Fragment,useEffect,useContext}from 'react';
import './PaymentRequest.scss';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import {ClipLoader,ScaleLoader} from "react-spinners";
import {ServerFirstIndexedb_FetchPaymentRequest,ServerFirstIndexedb_ApprovePaymentRequest} from "../../HttpcallUserApp";
import {NotificationActivate} from "../NotificationApplication/NotificationApplication";




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));


    
const PaymentRequest = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User,App} = Appstate
  const {CurrencyFormat} = Functions
   const {themecolor1,themecolor2} = Appsettings

   const {server_ip,authentication_token} = App

   const [currentTab, setcurrentTab] = React.useState('pending');

   const [CurrentIndex, setCurrentIndex] = React.useState(null);

   const [ApproveLoading, setApproveLoading] = React.useState(null);

   const [RequestInstanceList, setRequestInstanceList] = React.useState({
    pending:[
        //  {
        //   generation_id: 'A16398-FMFP',
        //   approved: 1,
        //   bank_account_fullname: "William jacksonn",
        //   bank_name: "AXIS",
        //   bank_number: "0495745749",
        //   current_grade_points: 7,
        //   final_withdraw_amount:50000,
        //   first_name: "William",
        //   gv_tax: 0,
        //   id: 1,
        //   issuer_role: "accountant",
        //   issuer_username: "femsadmin@gmail.com",
        //   last_name: "Nicholous",
        //   loan_deduction: 0,
        //   middle_name: "Jackson",
        //   pending: 0,
        //   profile:"profile_avatar.png",
        //   rank: "SILVER",
        //   rejected: 0,
        //   total_community: 110,
        //   transaction_date: "",
        //   transaction_fee: 0,
        //   user_id: "",
        //   withdraw_amount: 450000,
        //   transaction_status:'pending'
        //  },
        //  {
        //   approved: 0,
        //   generation_id: 'A16398-FMFP',
        //   bank_account_fullname: "Jackson jacksonn",
        //   bank_name: "AXIS",
        //   bank_number: "04957457409",
        //   current_grade_points: 7,
        //   final_withdraw_amount:50000,
        //   first_name: "Happiness",
        //   gv_tax: 0,
        //   id:2,
        //   issuer_role: "accountant",
        //   issuer_username: "femsadmin@gmail.com",
        //   last_name: "Nicholous",
        //   loan_deduction: 0,
        //   middle_name: "Michale",
        //   pending: 1,
        //   profile:"profile_avatar.png",
        //   rank: "silver",
        //   rejected: 0,
        //   total_community: 110,
        //   transaction_date: "",
        //   transaction_fee: 0,
        //   user_id: "",
        //   withdraw_amount: 456666,
        // transaction_status:'pending'
        //  }
    ],
    approved:[],
    rejected:[]



   }
    );


  const [CurrentProfile, setCurrentProfile] = React.useState({
      bank_account_fullname: "William jacksonn",
      bank_name: "AXIS",
      bank_number: "0495745749",
      current_grade_points: 7,
      final_withdraw_amount:50000,
      first_name: "William",
      gv_tax: 0,
      id: 1,
      issuer_role: "accountant",
      issuer_first_name: "Erradius",
      issuer_middle_name: "Michael",
      issuer_last_name: "Patrick",
      issuer_username: "femsadmin@gmail.com",
      last_name: "Nicholous",
      loan_deduction: 0,
      middle_name: "Jackson",
      profile:"profile_avatar.png",
      rank: "SILVER",
      rejected: 0,
      total_community: 110,
      transaction_date: "",
      transaction_fee: 0,
      user_id: "",
      withdraw_amount: 0,
      generation_id:'',
      transaction_status:'pending'
    });

  
  const [RadioState, setRadioState] = React.useState({approved:false,rejected:false});

  const [ServerResponse, setServerResponse] = React.useState(null);

  const [ApprovalError, setApprovalError] = React.useState(null);


  const classes = useStyles();
  const [value, setValue] = React.useState(0);




useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  
 //pending,approved,rejected 
 ServerFirstIndexedb_FetchPaymentRequest(server_ip,currentTab,authentication_token).then(function(JsonResponse){
  const {status} = JsonResponse
  setServerResponse(status)
  console.log(JsonResponse)
   if(status===200){
    const {Jsondata} = JsonResponse

    setRequestInstanceList({...RequestInstanceList,[currentTab]:Jsondata})

  }else{ 
      
    NotificationActivate(`(server_error: ${status}) could not retrieve requests !`,'information',true,false,10*1000)
  }

}).catch(function(ErrorResponse){


  NotificationActivate(`(application error) could not contact server!`,'information',true,false,10*1000)


})




return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])






const handleChange = (event, newValue) => {
  setValue(newValue);

  if(newValue===0){//NEW REQUEST
    setcurrentTab('pending')
    if(RequestInstanceList['pending'].length===0){
      setServerResponse(null)
     
      //pending,approved,rejected 
      ServerFirstIndexedb_FetchPaymentRequest(server_ip,'pending',authentication_token).then(function(JsonResponse){
       
       const {status} = JsonResponse
       setServerResponse(status)
       console.log(JsonResponse)

       if(status===200){
        const {Jsondata} = JsonResponse

        setRequestInstanceList({...RequestInstanceList,pending:Jsondata})

      }else{ 
     
        NotificationActivate(`(server_error: ${status}) could not retrieve requests !`,'information',true,false,10*1000)
     }

     }).catch(function(ErrorResponse){

        NotificationActivate(`(application error) could not contact server!`,'information',true,false,10*1000)


     })



    }



  }else if(newValue===1){//CONFIRMED REQUEST
    setcurrentTab('approved')
    if(RequestInstanceList['approved'].length===0){
       setServerResponse(null)

       //pending,approved,rejected 
       ServerFirstIndexedb_FetchPaymentRequest(server_ip,'approved',authentication_token).then(function(JsonResponse){
        
        const {status} = JsonResponse
        setServerResponse(status)
        console.log(JsonResponse)

        if(status===200){
         const {Jsondata} = JsonResponse

         setRequestInstanceList({...RequestInstanceList,approved:Jsondata})

       }else{ 
      
         NotificationActivate(`(server_error: ${status}) could not retrieve requests !`,'information',true,false,10*1000)
      }

      }).catch(function(ErrorResponse){

         NotificationActivate(`(application error) could not contact server!`,'information',true,false,10*1000)


      })

   }



  }
  else if(newValue===2){//CONFIRMED REQUEST
    setcurrentTab('rejected')
    console.log('CONFIRMED_TAB_CLICKED')
    if(RequestInstanceList['rejected'].length===0){
       setServerResponse(null)

       //pending,approved,rejected 
       ServerFirstIndexedb_FetchPaymentRequest(server_ip,'rejected',authentication_token).then(function(JsonResponse){
        
        const {status} = JsonResponse
        setServerResponse(status)
        console.log(JsonResponse)

        if(status===200){
         const {Jsondata} = JsonResponse

         setRequestInstanceList({...RequestInstanceList,rejected:Jsondata})

       }else{ 
      
         NotificationActivate(`(server_error: ${status}) could not retrieve requests !`,'information',true,false,10*1000)
      }

      }).catch(function(ErrorResponse){

         NotificationActivate(`(application error) could not contact server!`,'information',true,false,10*1000)


      })

   }



  }
};





 const TestFetch =()=>{
ServerFirstIndexedb_FetchPaymentRequest(server_ip,0,0,1,authentication_token).then(function(JsonResponse){

    const {status} = JsonResponse

    if(status===200){

      const {Jsondata} = JsonResponse
      console.log(Jsondata)
 
 
    }
 
 
 
   })
 


 }

 const ActivateCurrentProfile =(profile,index)=>{


  setCurrentProfile(profile)
  setCurrentIndex(index)

  setApprovalError(null) 

  // setRadioState({approved:false,rejected:false})
 }



 const TransactionStatusDisplayer=({transaction_status})=>{
  return(
    <React.Fragment>
      {transaction_status==='pending'?
       <span class="badge bg-primary">pending</span>
      :null
      }
      {transaction_status==='approved'?
       <span class="badge bg-success">approved</span>
      :null}
       {transaction_status==='rejected'?
       <span class="badge bg-danger">rejected</span>
      :null}
  </React.Fragment>
  )
  }
  


 
const UserRequestListMap = RequestInstanceList[currentTab].map(function(object,index){
  return(
      <React.Fragment key={index}>
               <div className="callout callout-success">
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={3} className='user-request-column'>
                      <div className='payment-request-profile-circle' style={{backgroundImage:`url(${object.profile})`}}>

                        <div className='user-request-profile-badge' style={{backgroundColor:`${themecolor1}`}}><span>{object.current_grade_points}</span></div>
                      </div>
                      
                      </Grid>

                    <Grid item xs={12} sm={3} className='user-request-column'><span>{object.first_name} {object.middle_name} {object.last_name}</span></Grid>
                    <Grid item xs={12} sm={2} className='user-request-column'><span class="badge bg-success">ISSUER:{object.issuer_role}</span></Grid>
                    
                    <Grid item xs={12} sm={2} className='user-request-column'>
                
                      <TransactionStatusDisplayer transaction_status={object.transaction_status} />
                    
                    </Grid>
                    
                    <Grid item xs={12} sm={2} className='user-request-column'>
                     
                     <div className="dropdown">
                       <button  style={{backgroundColor:`${themecolor1}`}} className="btn btn-secondary dropdown-toggle" type="button" id={`dropdownMenuButton${index}`} data-bs-toggle="dropdown" aria-expanded="false">
                               Action
                        </button>
                     <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${index}`}>
                       <li><button  onClick={()=>{ActivateCurrentProfile(object,index)}} data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="dropdown-item">View Request</button></li>
                       <li><b className="dropdown-item">View Profile</b></li>
                     </ul>
                    </div>
                </Grid>
              </Grid>     
            </div>
         <br />
         
      </React.Fragment>
      )
 })  





const OnchangeApproveFunction =(event)=>{
    const {checked} = event.target
    if(checked===true){

      setCurrentProfile({...CurrentProfile,transaction_status:'approved'})

      setRadioState({...RadioState,approved:true,rejected:false})

      setApprovalError(null)
    }
    
} 



const OnchangeRejectFunction =(event)=>{
 const {checked} = event.target

   if(checked===true){

      setCurrentProfile({...CurrentProfile,transaction_status:'rejected'})

      setRadioState({...RadioState,rejected:true,approved:false})
  }
  if(RequestInstanceList[currentTab][CurrentIndex].transaction_status==='approved' && RadioState.rejected===true){
  
    setApprovalError('YOU CANNOT UNDO THE APPROVED TRANSACTION')
  

   }
  
  


} 



const ConfirmActionFunction =()=>{
if(RequestInstanceList[currentTab][CurrentIndex].transaction_status==='approved' && RadioState.rejected===true){
  
  setApprovalError('YOU CANNOT UNDO THE APPROVED TRANSACTION')

 }else{

  setApprovalError(null)
  var FormPayload ={
    transaction_status:CurrentProfile.transaction_status,
    generation_id:CurrentProfile.generation_id,
    request_id:CurrentProfile.id
  }

  console.log(FormPayload)

   setApproveLoading(true)
   ServerFirstIndexedb_ApprovePaymentRequest(server_ip,FormPayload,authentication_token).then(function(JsonResponse){
    const {status} = JsonResponse
    setApproveLoading(false)
    console.log(JsonResponse)
    if(status===200){

     RequestInstanceList[currentTab][CurrentIndex]['transaction_status'] = CurrentProfile.transaction_status

  
     //REMOVE THE DATA FROM THE LIST 
    //  RequestInstanceList[currentTab].splice(CurrentIndex,1)
    //  setRequestInstanceList({...RequestInstanceList})
     
     setRequestInstanceList({...RequestInstanceList})

     NotificationActivate(`approved successfully!`,'success',true,true,6*1000)



    }else if(status > 200){

    NotificationActivate(`(server_error: ${status}) could not approve request !`,'information',true,false,6*1000)


    }


 }).catch(function(ErrorResponse){

  setApproveLoading(false)


  NotificationActivate(`(application error) could not contact server!`,'information',true,true,6*1000)

     

 })





 }
}


const UserRequestSkeletonMap = [0,1,2,3,4].map(function(object,index){
  return(
     <React.Fragment key={index}>
         <div className='generation-card'>
          <Grid container spacing={1}>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
              <div className='generation-skeleton-circle'>
              <Skeleton variant='circle' animation='pulse' style={{width:80,height:80}} /> 
              </div>
           </Grid>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
          </Grid>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
          </Grid>
 
          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"10%",height:30}} /> 
          </Grid>
         </Grid>
        </div>
        <br />
     </React.Fragment>
     )
})  

 

const EmptyGenerationMessage = ({GenerationLevel})=>{
  return(
    <React.Fragment>
           <div className='empty-generation-message'>
            <h6>EMPTY {GenerationLevel} </h6>
          </div>
  </React.Fragment>
  )
}


const StatusDisplayer=({transaction_status})=>{
  return(
    <React.Fragment>
      {transaction_status==='pending'?
       <span class="badge bg-primary">pending</span>
      :null
      }
      {transaction_status==='approved'?
       <span class="badge bg-success">approved</span>
      :null}
       {transaction_status==='rejected'?
       <span class="badge bg-danger">rejected</span>
      :null}
  </React.Fragment>
  )
  }
  
 




return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="payment-request-wrapper">
<div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="NEW REQUEST" {...a11yProps(0)} />
          <Tab label="CONFIRMED" {...a11yProps(1)} />
          <Tab label="REJECTED" {...a11yProps(2)} />
          {/* <Tab label="Item Four" {...a11yProps(3)} />
          <Tab label="Item Five" {...a11yProps(4)} />
          <Tab label="Item Six" {...a11yProps(5)} />
          <Tab label="Item Seven" {...a11yProps(6)} />*/}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        
        {UserRequestListMap}

        {RequestInstanceList['pending'].length===0 && ServerResponse===null?
         UserRequestSkeletonMap
        :null}

        {RequestInstanceList['pending'].length===0 && ServerResponse >= 200?
         <EmptyGenerationMessage GenerationLevel='NEW REQUEST'/>
        :null}

      </TabPanel>

      <TabPanel value={value} index={1}>
        
      {UserRequestListMap}
      
      {RequestInstanceList['approved'].length===0 && ServerResponse===null?
        
        UserRequestSkeletonMap
      :null}

      {RequestInstanceList['approved'].length===0 && ServerResponse >= 200?
       <EmptyGenerationMessage GenerationLevel='CONFIRMED REQUEST'/>
      :null}

      </TabPanel>

      <TabPanel value={value} index={2}>
      {UserRequestListMap} 
      {RequestInstanceList['rejected'].length===0 && ServerResponse===null?
         UserRequestSkeletonMap
        :null}

        {RequestInstanceList['rejected'].length===0 && ServerResponse >= 200?
         <EmptyGenerationMessage GenerationLevel='REJECTED REQUEST'/>
        :null}
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </div>
    {/*------------------------------ MODAL START--------------------------- */}

<div>
  {/* Button trigger modal */}
  {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
    Launch static backdrop modal
  </button> */}
  {/* Modal */}
  <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">PAYMENTS WITHDRAW REQUEST</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className='modal-payment-request' style={{backgroundColor:`${themecolor1}`}}>

               <div className='modal-request-profile-circle' style={{backgroundImage:`url(${CurrentProfile.profile})`}}></div>
               <Divider />
               <h6>Total community({CurrentProfile.total_community})</h6>
               <Divider />
               <h6>{CurrentProfile.first_name} {CurrentProfile.middle_name} {CurrentProfile.last_name} </h6>
               <Divider />
               <h6>{CurrentProfile.rank.toUpperCase()}</h6>

          </div>
            <ul className="list-group"> 
                <li className="list-group-item"><b>REQUESTED AMOUNT</b>: {CurrentProfile.withdraw_amount} </li>
                <li className="list-group-item"><b>GV.TAX</b>: {CurrentProfile.gv_tax}</li>
                <li className="list-group-item"><b>TRANSACTION FEE</b>:{CurrentProfile.transaction_fee} </li>
                <li className="list-group-item"><b>LOAN DEDUCTION</b>: {CurrentProfile.loan_deduction} </li>
                <li className="list-group-item"><b>BANK NAME</b>: {CurrentProfile.bank_name} </li>
                <li className="list-group-item"><b>BANK NUMBER</b>: {CurrentProfile.bank_number} </li>
                <li className="list-group-item"><b>BANK ACCOUNT NAME</b>: {CurrentProfile.bank_account_fullname} </li>
                <li className="list-group-item"><b>FINAL WITHDRAW</b>: {CurrentProfile.final_withdraw_amount} </li>

                <li className="list-group-item"><b>REQUEST STATUS</b>:

                     <StatusDisplayer transaction_status={CurrentProfile.transaction_status}/>
                
                 </li>

                 <li className="list-group-item"><b>ISSUER</b>: {CurrentProfile.issuer_role}  </li>
                 <li className="list-group-item"><b>ISSUER FULL NAME</b>: {CurrentProfile.issuer_first_name} {CurrentProfile.issuer_middle_name} {CurrentProfile.issuer_last_name}  </li>

    
    
            </ul>
               
               <ButtonGroup fullWidth={true} variant='outlined' size="small" aria-label="small outlined button group">
                 <Button   htmlFor="radioSuccess2" type="button"   color='primary'>APPROVE
                   
                    <div className="icheck-success d-inline">
                      <input onClick={OnchangeApproveFunction} checked={CurrentProfile.transaction_status==='approved'?true:false}   type="radio" name="confirm-request" id="radioSuccess2" />
                      <label htmlFor="radioSuccess2">
                     </label>
                   </div>

                  </Button>

                 <Button htmlFor="radioDanger3" type="button"  color='primary'>REJECT
                    <div className="icheck-danger d-inline">
                      <input onClick={OnchangeRejectFunction}  checked={CurrentProfile.transaction_status==='rejected'?true:false}  type="radio" name="confirm-request"  id="radioDanger3" />
                      <label htmlFor="radioDanger3">
                     </label>
                      </div>

                  </Button>
               </ButtonGroup>

                <div className='transaction-modal-divider'></div>
                
                {ApprovalError !==null?
               <div className="alert alert-info alert-dismissible">
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className="icon fas fa-info" /> Alert!</h5>
                        {ApprovalError}
                </div>
                :null}

              
               <div className='update-profile-loader-wrapper'>
                <ScaleLoader speedMultiplier={2} loading={ApproveLoading} height={50} width={10} color={themecolor1} />
                </div>
                


               <ButtonGroup fullWidth={true} variant='outlined' size="small" aria-label="small outlined button group">
                 <Button onClick={ConfirmActionFunction}  type="button"  color='primary'>CONFIRM ACTION</Button>
               </ButtonGroup>
            
            

          
        </div>
             <div className="modal-footer">
               <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>



</div>
</Fragment>
 
    
);


// PaymentRequest.propTypes = {}
        
// PaymentRequest.defaultProps ={}


}
export {PaymentRequest};

