import React ,{Fragment,useEffect,useState,useContext}from 'react';
import PropTypes from 'prop-types';
import './ColorListComponent.scss';

import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';





const  ColorListComponent = ({props,GetSelecteColorFunction,DefaultColorList}) => {

  const [SelectedColorList,setSelectedColorList] = useState(DefaultColorList)

  const OnchangeProductColors = (event) => {
   var color = event.target.value
   var colorName = event.target.name
 
  
  if(event.target.checked===true){
   SelectedColorList[colorName] = color
   setSelectedColorList({...SelectedColorList})
  }else if(event.target.checked===false){

    delete SelectedColorList[colorName]
    setSelectedColorList({...SelectedColorList})

  } 
  
  };



  

useEffect(()=>{ //----------COMPONENT DID MOUNT




GetSelecteColorFunction(SelectedColorList)  



return()=>{//------Component Wiil Unmount




}

},[SelectedColorList])  




return (

<Fragment>
{/* <button onClick={()=>{console.log(DefaultColorList)}}>CHECK </button> */}

<Grid container spacing={0}>
          
          <Grid item xs={12}>
          <Grid container spacing={0}> 

          <Grid item  xs={2}>
           <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("red")?true:false} value="#FF0000" name="red" color="primary" /> }
         label=""
           onChange={OnchangeProductColors}/> 
          </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#FF0000'}} className="color-div"></div>
            </Grid>

            <Grid item xs={2}>
             <b className="color-names">Red</b>
            </Grid>

            </Grid>
          </Grid>


          <Grid item xs={12}>
             <Grid container spacing={0}> 
            <Grid item xs={2}>
          <FormControlLabel control={<Checkbox  checked={SelectedColorList.hasOwnProperty("cyan")?true:false} value="#00FFFF" name="cyan" color="primary" /> }
         label=""
          onChange={OnchangeProductColors}/> 
          </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#00FFFF'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Cyan</b>
            </Grid>

            </Grid>
          </Grid>
                 
          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("dark_blue")?true:false}  value="#0000A0" name="dark_blue" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#0000A0'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">DarkBlue</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("light_blue")?true:false}  value="#ADD8E6" name="light_blue" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#ADD8E6'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">LightBlue</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}




          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("purple")?true:false}  value="#800080" name="purple" color="primary" /> }
                 label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#800080'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Purple</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("yellow")?true:false}  value="#FFFF00" name="yellow" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#FFFF00'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Yellow</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("lime")?true:false}  value="#00FF00" name="lime" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#00FF00'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Lime</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("meganta")?true:false}  value="#FF00FF" name="meganta" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#FF00FF'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Meganta</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("white")?true:false}  value="#FFFFFF" name="white" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#FFFFFF'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">white</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("silver")?true:false}  value="#C0C0C0" name="silver" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#C0C0C0'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Silver</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("gray")?true:false}  value="#808080" name="gray" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#808080'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Gray</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("black")?true:false}  value="#000000" name="black" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#000000'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Black</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("orange")?true:false}  value="#FFA500" name="orange" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#FFA500'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Orange</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("brown")?true:false}  value="#A52A2A" name="brown" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#A52A2A'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Brown</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("maroon")?true:false}  value="#800000" name="maroon" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#800000'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Maroon</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("green")?true:false}  value="#008000" name="green" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#008000'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Green</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("olive")?true:false}  value="#808000" name="olive" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#808000'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Olive</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("gunmetal")?true:false}  value="#2C3539" name="gunmetal" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#2C3539'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Gunmetal</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("platinum")?true:false}  value="#E5E4E2" name="platinum" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#E5E4E2'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Platinum</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("marble_blue")?true:false}  value="#566D7E" name="marble_blue" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#566D7E'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">MarblebLUE</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("blue_jay")?true:false}  value="#2B547E" name="blue_jay" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#2B547E'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">BlueJay</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("dark_slate_blue")?true:false}  value="#2B3856" name="dark_slate_blue" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#2B3856'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">DarkSlateBlue</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}

          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("mid_night_blue")?true:false}  value="#151B54" name="mid_night_blue" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#151B54'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">MidNightBlue</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("navy_blue")?true:false}  value="#000080" name="navy_blue" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#000080'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">DarkGray</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("earth_blue")?true:false}  value="#0000A0" name="earth_blue" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#0000A0'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">EarthBlue</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("jelly_fish")?true:false}  value="#46C7C7" name="jelly_fish" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#46C7C7'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">JellyFish</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("greenish_blue")?true:false}  value="#307D7E" name="greenish_blue" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#307D7E'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">GreenishBlue</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("tea")?true:false}  value="#008080" name="tea" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#008080'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Tea</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}





          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("venom_green")?true:false}  value="#728C00" name="venom_green" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#728C00'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">VenomGreen</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("fenn_green")?true:false}  value="#667C26" name="fenn_green" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#667C26'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">FennGreen</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("dark_forest_green")?true:false}  value="#254117" name="dark_forest_green" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#254117'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">DarkForestGreen</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("medium_sea_green")?true:false}  value="#306754" name="medium_sea_green" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#306754'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">MediumSeaGreen </b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("green_apple")?true:false}  value="#4CC417" name="green_apple" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#4CC417'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">GreenApple</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          
          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("peach")?true:false}  value="#FFE5B4" name="peach" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#FFE5B4'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Peach</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          
          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("bright_gold")?true:false}  value="#FDD017" name="bright_gold" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#FDD017'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">BrightGold</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          
          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty('suffron')?true:false}  value="#FBB917" name="suffron" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#FBB917'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Suffron</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          
          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("taupe")?true:false}  value="#483C32" name="taupe" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#483C32'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Taupe</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          
          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("coffee")?true:false}  value="#6F4E37" name="coffee" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#6F4E37'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Coffee</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("sepia")?true:false}  value="#7F462C" name="sepia" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#7F462C'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Sepia</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("rust")?true:false}  value="#C36241" name="rust" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#C36241'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Rust</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("sedona")?true:false}  value="#CC6600" name="sedona" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#CC6600'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Seona</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}





          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("papaya_orange")?true:false}  value="#E56717" name="papaya_orange" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#E56717'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">PapayaOrange</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}




          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("pumkin_orange")?true:false}  value="#F87217" name="pumkin_orange" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#F87217'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">PumkinOrange</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}




          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("sun_rise_orange")?true:false}  value="#E67451" name="sun_rise_orange" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#E67451'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">SunRiseOrange</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("chocolate")?true:false}  value="#C85A17" name="chocolate" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#C85A17'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Chocolate</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}


          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("shocking_orange")?true:false}  value="#E55B3C" name="shocking_orange" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#E55B3C'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">ShockingOrange</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}




          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("scarlet")?true:false}  value="#FF2400" name="scarlet" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#FF2400'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">Scarlet</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}



          <Grid item xs={12}> {/*---- SINGLE ROW */}
            <Grid container spacing={0}> 
             <Grid item xs={2}>
               <FormControlLabel control={<Checkbox checked={SelectedColorList.hasOwnProperty("chili_pepper")?true:false}  value="#C11B17" name="chili_pepper" color="primary" /> }
              label=""
               onChange={OnchangeProductColors}/> 
              </Grid>

            <Grid item xs={6}>
            <div style={{width:'100%',height:25,backgroundColor:'#C11B17'}} className="color-div"></div>
            </Grid>
            <Grid item xs={2}>
             <b className="color-names">ChiliPepper</b>
            </Grid>

            </Grid>
          </Grid> {/*---- END SINGLE*/}











         </Grid>
</Fragment>

    
   

  );


ColorListComponent.propTypes = {

GetSelecteColorFunction:PropTypes.func,
DefaultColorList:PropTypes.object
    
        
}
          
ColorListComponent.defaultProps ={
          
GetSelecteColorFunction:function(){alert("function has to be passed")},
DefaultColorList:{}   
      
}

}
export default ColorListComponent;

