import React ,{Fragment,useEffect}from 'react';
import {useHistory} from "react-router-dom";
import './UserRegistrationForm.scss';
//import { makeStyles } from '@material-ui/core/styles';
import {ThemeProvider,createMuiTheme} from "@material-ui/core";
import {Grid,Divider,Typography,Button,ButtonGroup} from '@material-ui/core';   
import {TanzaniaRegionsList,TanzaniaDistrictList} from "../../../../TanzaniaRegionList";
import {CheckCircle,CancelPresentation} from '@material-ui/icons';
import {HttpServer_UserRegistration,HttpServer_FetchUserProfileInformation} from "../../HttpcallUserApp";
//import $ from 'jquery';
import TextField from '@material-ui/core/TextField';

import {ClipLoader,ScaleLoader} from "react-spinners";





// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
// }));


    
const UserRegistrationForm = ({props}) => { //--------------MAIN FUNCTION----------------

//const classes = useStyles();


const [Formsettings, setFormsettings] = React.useState({
  logo:'logo_avatar.png',
  themecolor1:'#FBB117',
  themecolor2:'#CA226B',
});



const theme = createMuiTheme({

  palette:{
    primary:{
      main:`${Formsettings.themecolor1}`
    },
    secondary:{
      main:`${Formsettings.themecolor2}`
    },
  },
  typography:{
    //fontFamily:'Comic Sans Ms',
    body1:{
      fontSize:'1.1rem',
    
    },
    // h1:{
    //   color:'red'
    // }
  },
  shape:{
    borderRadius:0,
  },
  //spacing:8,
  overrides:{
    MuiButton:{
      root:{
        textTransform:'none',
        
      }
    },

  },
  props:{
    MuiButton:{
      // disableRipple:true,
      // variant:'text'
    },
    MuiButtonGroup:{
      // disableRipple:true,
      // variant:'outlined'
    },
    MuiTextField:{
      InputLabelProps:{
        shrink:true
      }
    }
    
  }

})

const History = useHistory()


const [DemoUsernameCount, setDemoUsernameCount] = React.useState(1);


const [RegistrationForm, setRegistrationForm] = React.useState({
  username_email:'',
  password:'',
  confirm_password:'',
  first_name:'',
  last_name:'',
  middle_name:'',
  sponsor_id:'',
  primary_email:'',
  phone_number1:'',
  phone_number2:'',
  region_name:'0',
  district_name:'0',
  ward_name:'',
  voucher_pin:'34DDRFGT544335',
  inheritance_first_name:'',
  inheritance_middle_name:'',
  inheritance_last_name:'',
  inheritance_relationship:'',
  inheritance_phone_number:'' ,
  inheritance_bank_name:'+91374738364',
  inheritance_bank_number:'083746383847',
  inheritance_bank_account_name:'AXIS-BANK'


  }
  );  


const [FormValidationError, setFormValidationError] = React.useState({
    FirstNameError:false,
    LastNameError:false,
    MiddleNameError:false,
    PhoneNumber1Error:false,
    PhoneNumber2Error:false,
    RegionNameError:false,
    DistrictNameError:false,
    WardNameError:false,
    SponsoridError:false,
    VoucherError:false,
    PrimaryEmailError:false,
    UsernameEmailError:false,
    PasswordError:false,
    PasswordMatchError:false,
    InheritanceFirstnameError:false,
    InheritanceLastNameError:false,
    InheritanceMiddlenameError:false,
    InheritanceBanknameError:false,
    InheritanceBankAccountNameError:false,
    InheritanceBankNumberError:false,
    InheritanceRelationshipError:false,
    InheritancePhoneNumberError:false,
    }
);

const [ServerStatusResponse, setServerStatusResponse] = React.useState(null);
 
const [ServerMessage, setServerMessage] = React.useState(null);

// const [ServerInnerStatus, setServerInnerStatus] = React.useState(null);

const [RegistrationLoading, setRegistrationLoading] = React.useState(false);



const [GenerationMemberList, setGenerationMemberList] = React.useState([]);

 
const [ValidationErrorMessage, setValidationErrorMessage] = React.useState(null);

const [ServerIpAddress, setServerIpAddress] = React.useState(()=>{
  if(process.env.NODE_ENV==='production'){
    return "/";
   }else{ 
     return  "http://127.0.0.1:8000/";
    }
});





useEffect(()=>{//------------COMPONENT DID MOUNT--------------------------------------
  
 

return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])



const OnchangeFirstName =(event)=>{

  setRegistrationForm({...RegistrationForm,first_name:event.target.value})

  if(event.target.value.length > 2){

    setFormValidationError({...FormValidationError,FirstNameError:false})

  }
}


const OnchangeLastName =(event)=>{

setRegistrationForm({...RegistrationForm,last_name:event.target.value})

if(event.target.value.length > 2){

setFormValidationError({...FormValidationError,LastNameError:false})

}
}


const OnchangeMiddleName =(event)=>{

setRegistrationForm({...RegistrationForm,middle_name:event.target.value})

if(event.target.value.length > 2){

setFormValidationError({...FormValidationError,SurNameError:false})

}
}





const OnchangeVoucher =(event)=>{

  const {value} = event.target
  
  setRegistrationForm({...RegistrationForm,voucher_pin:value})
  
  if(value.length >= 14){
  
    setFormValidationError({...FormValidationError,InheritanceFirstnameError:false})
  
  }
}

   

const OnchangeSponsorid =(event)=>{

  const {value} = event.target
  
  setRegistrationForm({...RegistrationForm,sponsor_id:value})
  
  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,SponsoridError:false})
  
  }
} 


const OnchangeUsernameEmail =(event)=>{

  const {value} = event.target
  setRegistrationForm({...RegistrationForm,username_email:value})
  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,SponsoridError:false})
  
  }
} 

const OnchangePasword =(event)=>{
  
  const {value} = event.target
  setRegistrationForm({...RegistrationForm,password:value})

  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,PasswordError:false})
  
  }
} 

const OnchangeConfirmPassword =(event)=>{
  
  const {value} = event.target
  setRegistrationForm({...RegistrationForm,confirm_password:value})
  
  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,PasswordMatchError:false})
  
  }
} 





const FormValidation = () =>{
  return new Promise(function(resolve,reject){

   if(RegistrationForm.username_email.length < 5){
   
    setFormValidationError({...FormValidationError,UsernameEmailError:true})
    return reject('username or emails is too short')

   }else if(RegistrationForm.confirm_password !== RegistrationForm.password){

    setFormValidationError({...FormValidationError,PasswordMatchError:true})

   }else if(RegistrationForm.first_name.length < 3){

    setFormValidationError({...FormValidationError,FirstNameError:true})
   }
   else if(RegistrationForm.last_name.length < 3){

    setFormValidationError({...FormValidationError,LastNameError:true})
   }
   else if(RegistrationForm.sponsor_id.length < 6){

    setFormValidationError({...FormValidationError,SponsoridError:true})
   }
   else if(RegistrationForm.voucher_pin.length < 14){

    setFormValidationError({...FormValidationError,VoucherError:true})
   }
   else if(RegistrationForm.voucher_pin.length < 14){

    setFormValidationError({...FormValidationError,VoucherError:true})
   }else{

    return resolve('successfuly validation')
   }


  })
}






const OnsubmitRegistration = ()=>{

//---validate form first
FormValidation().then(function(JsonResponse){

  setServerStatusResponse(null)
  var FormPayload = RegistrationForm
  setRegistrationLoading(true)
  HttpServer_UserRegistration(ServerIpAddress,FormPayload).then(function(JsonResponse){
  const {status,Jsondata} = JsonResponse
  setServerStatusResponse(status)
  setRegistrationLoading(false)
  console.log(JsonResponse)


  // setDemoUsernameCount(DemoUsernameCount+1)
  // setRegistrationForm({...RegistrationForm,username_email:`Happiness joseph${DemoUsernameCount}@gmail.com`})

  if(status===200){

    const {message} = Jsondata
    // const {inner_status} = Jsondata 

    // setServerInnerStatus(inner_status)


   
    setServerMessage(message)

    

   }else if(status===202){

    const {data,message} = Jsondata
    setGenerationMemberList(data)
    setServerStatusResponse(status)
    setServerMessage(message)

   }else{
   
    const {message} = Jsondata
    setServerStatusResponse(status)
    setServerMessage(message)
  }

}).catch(function(ErrorResponse){

     setRegistrationLoading(false)
     setServerStatusResponse('offline')
})

}).catch(function(ValidationErrorMesage){

  

})


}


const OnchangeGenerationSelection =(event)=>{
  const {value} = event.target
  setRegistrationForm({...RegistrationForm,sponsor_id:value})
} 


const GenerationMemberListMap = GenerationMemberList.map(function(object,index){
  return(
     <React.Fragment key={index}>
           <Grid item xs={12} sm={6}>
           <div className='generation-selection-wrapper'>
             <div className='generation-profile-circle' style={{border:`4px solid ${Formsettings.themecolor2}`}}><img src='profile_avatar.png' alt='profile'/></div>
               <ul>
                  <li>{object.first_name} {object.middle_name}</li>
                  <br />
                  <li>Community({object.downline_count})</li>
                </ul>

            <div className="icheck-success d-inline">
             <input onClick={OnchangeGenerationSelection} type="radio" name="r3" value={object.id}  id={`member${index}`}  />
              <label htmlFor={`member${index}`}>
              </label>
           </div>
          </div>
        </Grid>
     </React.Fragment>
     )
})  




const UserProfileInformation = ()=>{

    HttpServer_FetchUserProfileInformation('http://127.0.0.1:8000/',RegistrationForm.username_email).then(function(JsonResponse){
      
    console.log(JsonResponse)
  
  })
  
}
  



const NavigateLogin =()=>{

  History.replace({pathname:"/auth/login"})
}

  
  







return (   //--------RETURN HTML---------------------------
<Fragment>
<ThemeProvider theme={theme}>   

<div className="profile-wrapper">


<div className='registration-top-nav'></div>
<div className="card" id='registration-form-card'>
    <div className="card-body" style={{opacity:RegistrationLoading===true?'0.7':null}}> 
     
     
     
       <div className='company-logo-container'>
           <div class='company-logo-circle' style={{border:`4px solid ${Formsettings.themecolor1}`}}>
                    
                    <img src={Formsettings.logo} alt='brand logo'/>
              </div>
      
          </div>
          <br />

  
  
        
          <Typography variant='caption'>---------PERSONAL INFORMATION---------</Typography>
          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Username OR Email"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeUsernameEmail} value={RegistrationForm.username_email}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.username_email.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.UsernameEmailError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
              <Grid item xs={10}>
                {FormValidationError.UsernameEmailError===true?
                <div className="validation-error-message">
                <span>Username or email too short </span>
                </div>
                :null}
              </Grid>
           </Grid>
           <Divider />
           <br/>

           <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Password"
              type='password'
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangePasword} value={RegistrationForm.password}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.password > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.PasswordError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
           </Grid>
           <Divider />
           <br/>

           <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Confirm Password"
              type='password'
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeConfirmPassword} value={RegistrationForm.confirm_password}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.confirm_password.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.PasswordMatchError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
              <Grid item xs={10}>
                {FormValidationError.PasswordMatchError===true?
                <div className="validation-error-message">
                <span>Password did not match </span>
                </div>
                :null}
              </Grid>
           </Grid>
           <Divider />
           <br/>

          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
               <TextField
              id="filled-required"
              label="First Name"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeFirstName} value={RegistrationForm.first_name}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.primary_email.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.PrimaryEmailError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>

              <Grid item xs={10}>
                {FormValidationError.FirstNameError===true?
                <div className="validation-error-message">
                <span>Firstname too short </span>
                </div>
                :null}
             </Grid>


           </Grid>
           <Divider />
           <br/>

           
          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Middle Name"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeMiddleName} value={RegistrationForm.middle_name}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.middle_name.length > 3?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.MiddleNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
           </Grid>
            <Divider />
            <br/>

            <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Last Name"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeLastName} value={RegistrationForm.last_name}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.last_name.length > 3?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.LastNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>

              <Grid item xs={10}>
                {FormValidationError.LastNameError===true?
                <div className="validation-error-message">
                <span>lastname too short </span>
                </div>
                :null}
             </Grid>


           </Grid>
            <Divider />
            <br/>
            {GenerationMemberList.length===0?
            <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="SPONSOR ID"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'

              onChange={OnchangeSponsorid} value={RegistrationForm.sponsor_id}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

             <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.sponsor_id.length > 3?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.SponsoridError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>

              <Grid item xs={10}>
                {FormValidationError.SponsoridError===true?
                <div className="validation-error-message">
                <span>Sponsor ID too short </span>
                </div>
                :null}
             </Grid>

           </Grid>
             :null}
            <Divider />
            <br/>

            <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="VOUCHER PIN"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeVoucher} value={RegistrationForm.voucher_pin}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.voucher_pin.length >= 14?<CheckCircle fontSize="default" className="validation-success"/>:null}
                  {FormValidationError.VoucherError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>

              <Grid item xs={10}>
                {FormValidationError.VoucherError===true?
                <div className="validation-error-message">
                <span>Invalid voucher pin </span>
                </div>
                :null}
             </Grid>


           </Grid>
            <Divider />
            <br/>

            
            {/* <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Phone number"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangePhoneNumber1} value={RegistrationForm.phone_number1}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.phone_number1.length >= 10 && RegistrationForm.phone_number1.length <= 13?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.PhoneNumber1Error ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
              <Grid item xs={10}>
                {FormValidationError.PhoneNumber1Error===true?
                <div className="validation-error-message">
                <span>Invalid phone number </span>
                </div>
                :null}
             </Grid>
           </Grid>
            <Divider />
            <br/>


            <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Phone number 2(optional)"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangePhoneNumber2} value={RegistrationForm.phone_number2}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

              <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.phone_number2.length >= 10 && RegistrationForm.phone_number2.length <= 13?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 </div>
              </Grid>
           </Grid>
            <Divider />
            <br/>


                <Grid container spacing={0}>
                 <Grid item xs={11} sm={10}>
                 <div className="form-group">
                    <label htmlFor="exampleFormControlSelect4">Select Region(City)</label>
                      <select  disabled={RegistrationLoading===true?true:false}  onChange={OnchangeRegionName}  value={RegistrationForm.region_name} className="form-control" id="exampleFormControlSelect4">
     
                      <option value="0">select</option>
                     <TanzaniaRegionsList />
                     </select>
                  </div>

                </Grid>
              
               <Grid item xs={1}>
                 <div className="registration-validation">
                 {RegistrationForm.region_name !=="0"?<CheckCircle fontSize="default" className="validation-success"/>:null}    
                {FormValidationError.RegionNameError===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
               
                 </div>
                 </Grid>
                 <Grid item xs={10}>
                {FormValidationError.RegionNameError===true?
                <div className="validation-error-message">
                <span>please select region </span>
                </div>
                :null}
             </Grid>
                </Grid>


                <Grid container spacing={0}>
                 <Grid item xs={11} sm={10}>
                  <div className="form-group">
                    <label htmlFor="FormControlSelectDistrict"> Select District</label>
                    <select disabled={RegistrationLoading===true?true:false}  value={RegistrationForm.district_name} onChange={OnchangeDistrictName} className="form-control" id="FormControlSelectDistrict">

                    <option value="0"> select district</option>
                    <TanzaniaDistrictList region={RegistrationForm.region_name}/>
     
                     </select>
                    </div>

                </Grid>
              
               <Grid item xs={1}>
                 <div className="registration-validation">
                {RegistrationForm.district_name !=="0"?<CheckCircle fontSize="default" className="validation-success"/>:null}     
                {FormValidationError.DistrictNameError===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}    
                  
                 </div>
                 </Grid>

                 <Grid item xs={10}>
                {FormValidationError.DistrictNameError===true?
                <div className="validation-error-message">
                <span>please select district </span>
                </div>
                :null}
               </Grid>


                </Grid>
                

                <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Ward"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeWardName} value={RegistrationForm.ward_name}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.ward_name.length >  2?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.WardNameError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
              <Grid item xs={10}>
                {FormValidationError.WardNameError===true?
                <div className="validation-error-message">
                <span>please select ward (within your district) </span>
                </div>
                :null}
             </Grid>

             <Grid item xs={10}>
                {FormValidationError.WardNameError===true?
                <div className="validation-error-message">
                <span>fill in ward name </span>
                </div>
                :null}
               </Grid>


           </Grid>
                <Divider />
            */}

            {GenerationMemberList.length > 0 ?
             <div className="card card-success">
               <div className="card-header" id='full-network-title'>
               <h4 className="card-title">YOUR SPONSOR NETWORK IF FULL  </h4>
              <br />
             <h6 className="card-title">(please select descendants community to join to.)</h6>
             </div>
              <div className="card-body">
               <Grid container spacing={1}> 

               {GenerationMemberListMap}

      
             </Grid>
            </div>
           </div>
           :null}
   
               {/*----- SERVER MESSAGE ------------*/}
               {ServerStatusResponse=== 202 && GenerationMemberList.length===0?
                <div className="alert alert-warning alert-dismissible">
                 {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button> */}
                   <h5><i className="icon fas fa-exclamation-triangle" /> Alert!</h5>
                    {ServerMessage}
                </div>
                :null}

                 {/*----- SERVER ERROR ------------*/}
               {ServerStatusResponse > 202 && GenerationMemberList.length===0?
                <div className="alert alert-warning alert-dismissible">
                 {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button> */}
                   <h5><i className="icon fas fa-exclamation-triangle" /> Alert!</h5>
                    SERVER ERROR {ServerStatusResponse}{ServerMessage}
                </div>
                :null}
                
                {ServerStatusResponse===200?
               <div className="alert alert-success alert-dismissible">
                {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button> */}
                 <h5><i className="icon fas fa-check" /> Alert!</h5>
                   {ServerMessage}
                </div>
                :null}


                      
                {ServerStatusResponse==='offline'?
               <div className="alert alert-warning alert-dismissible">
                {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button> */}
                 <h5><i className="icon fas fa-check" /> Alert!</h5>
                   COULD NOT CONNECT !
                </div>
                :null}

            

           </div>
               <div className='registration-loader-wrapper'>
                <ScaleLoader speedMultiplier={2} loading={RegistrationLoading} height={50} width={10} color={Formsettings.themecolor1} />
                </div>
                


     
               <ButtonGroup fullWidth={true}  color='secondary' aria-label="small outlined button group">
                 <Button disabled={RegistrationLoading===true?true:false}   onClick={OnsubmitRegistration} type="button"  data-bs-dismiss="modal"  color='primary'>Register</Button>
                 <Button disabled={RegistrationLoading===true?true:false}   onClick={NavigateLogin} type="button"  data-bs-dismiss="modal"  color='primary'>Login</Button>
               
               </ButtonGroup>

               {/* <ButtonGroup fullWidth={true}  color='secondary' aria-label="small outlined button group">
                 <Button  onClick={UserProfileInformation} type="button"  data-bs-dismiss="modal"  color='primary'>PROFILE INFO</Button>
               </ButtonGroup> */}

               <div className="registration-form-footer">
              
                </div>
      </div>




</div>

</ThemeProvider>
</Fragment>
 
    
);


// UserRegistrationForm.propTypes = {}
        
// UserRegistrationForm.defaultProps ={}


}
export {UserRegistrationForm};

