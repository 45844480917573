import React ,{Fragment,useEffect,useState}from 'react';
import {Route,Switch,Redirect} from 'react-router-dom';
import {Useraccount} from "./Components/AdminAccounts/Useraccount";
import {UserRegistration} from "./Components/Landingpage/UserRegistration/UserRegistration";
import {SuperAccountRegistration} from "./Components/Landingpage/SuperAccountRegistration/SuperAccountRegistration";
import {LoginPage} from "./Components/Landingpage/LoginPage/LoginPage";
import Cookies from 'js-cookie';




const  RouteNavigation = (props) => {
//var Location = useLocation() 
//var AppUrl = Location.pathname.split('/')



const [Authenticated,setAuthenticated] = useState(()=>{
    var Token = Cookies.get("SUID-SESSION")
    if(Token !==null && Token !==undefined){

    return true

   }else{return false}
    
})








useEffect(()=>{//COMPONENT DID MOUNT






 
    


},[])




  




// const BackdropLoadingComponent = ()=>{

// return(
// <React.Fragment>
//       <Backdrop className={classes.backdrop} open={open}>
//         {siteNotFound===false && InternetError===false?<CircularProgress color="inherit" />:null}
//         {siteNotFound===false && InternetError===false?<span>Loading...</span>:null}
//         {siteNotFound===true?<span>site not found...</span>:null}  
//         {InternetError===true?<span>could not contact server!</span>:null} 
//       </Backdrop>
//     </React.Fragment>
// )

// }  


  








return (  //=----RETURN MAIN HTML NAVIGATION
<Fragment>
<Switch>


<Route  path='/control/login' render={(props)=>(<LoginPage />)} /> 

<Route  path='/auth/registration' render={(props)=>(<UserRegistration />)} /> 

<Route  path='/adminpanel/add_supperaccount' render={(props)=>(<SuperAccountRegistration />)} /> 

<Route  path='/control/:AccountType' render={(props)=>(Authenticated===true?<Useraccount />:<Redirect to={{pathname:'/control/login'}}/>)} /> 

<Route render={(props)=>(<h1>PAGE NOT FOUND !</h1> )}  /> 

</Switch>

</Fragment>
);
}
export {RouteNavigation};

