import React ,{Fragment,useEffect,useState,useContext}from 'react';
import $ from 'jquery';
import './ProductFormComponent.scss';

import ColorListComponent from "../../../../Subcomponents/ColorListComponent/ColorListComponent";



//import {Notifications} from '../Notifications/Notification';
import {LoadingSpinner} from '../../../../Subcomponents/Subcomponents';
import {ProductCategoryComponent,ProductSubCategory,InnerCategory,InnerMostCategory} from '../../../../ProductCategoryList';
import {ImageValidator} from '../../../../Subcomponents/Subcomponents';
//import {AdminAppContext} from "../../ContextApi";


import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Select from '@material-ui/core/Select';


import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';



import {AddAPhotoOutlined,Done,CheckCircle,Cancel,LibraryAddCheck,Send,DeleteForever} from '@material-ui/icons';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import Favorite from '@material-ui/icons/Favorite';
//import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
//import { withStyles } from '@material-ui/core/styles';


//import {Box,Container} from '@material-ui/core';
//import CardActionArea from '@material-ui/core/CardActionArea';
//import CardMedia from '@material-ui/core/CardMedia';
//import {HttpServer_UpdateProduct} from "../../HttpServerCallAdminApp";
//import {useHistory} from 'react-router-dom';
//import PropTypes, { func } from 'prop-types';
//import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';



// import FormLabel from '@material-ui/core/FormLabel';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
//import DialogContentText from '@material-ui/core/DialogContentText';

//import RootRef from '@material-ui/core/RootRef';



import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,


  },
  formControl: {
    margin: theme.spacing(1),
    width:'95%'
  },
  media:{
    height:180
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  table: {
    width:'100%',
  },
  image:{
    position:'relative',
    [theme.breakpoints.up('sm')]:{
      width:'50%',
      
    },
    [theme.breakpoints.down('md')]:{
      width:'100%',
    }
  }


}));




const  ProductFormComponent = ({props,EdditProduct=false,ProductEdditSelected={},HttpPostProductFunction=undefined,HttpUpdatedProductFunction=undefined}) => { //--------------MAIN FUNCTION----------------
  const classes = useStyles();

    
  // const AppSubState = useContext(AppNotificationContext)

  // const ActivateLoading = AppSubState.ApiLoading
  // const {setProgress} = AppSubState

  
  

  // const {profile_completed} = AppMainState.userState
  // var {AppState} = AppMainState
  // var {onlineState} = AppMainState

  //const AppMainState = useContext(AdminAppContext)
  
 // const UserState = AppMainState.UserDataState
 // const AppState = AppMainState.AppDataState

  // const {TriggerProductPreviewModal} = AppMainState.Functions



//const [ImagesCounter,setImagesCounter] = useState(0)

const [CurrentClickedImage,setCurrentClickedImage] = useState("")
const [ProductSpecificationTitle,setProductSpecificationTitle] = useState("")
const [ProductSpecificationValue,setProductSpecificationValue] = useState("")

const [AddProductLoading,setAddProductLoading] = useState(false)
const [EdditProductLoading,setEdditProductLoading] = useState(false)
  

const [ProductFormError,setProductFormError] = useState({
ProductNameError:false,
BrandNameError:false,
MadeFromError:false,
PriceError:false,
InnerCategoryError:false,
InnerMostCategoryError:false,
DiscountPriceError:false,
PercentageRewardError:false,
SpecialDiscountEndDateError:false,
SizeVariationError:false,
ProductQuantityError:false,
ProductCategoryError:false,
ProductsSubCategoryError:false,
ProductWeightValueError:false,
ProductWeightUnitError:false,
ProductSizeError:false,
ProductMaterialError:false,
ProductWarantyTimeValueError:false,
ProductWarantyTimeUnitError:false,
ColorVariationError:false,
ProductFeatureError:false,
ProductImagesError:false,
ProductStatusError:false
})

const [ProductForm,setProductForm] = useState(
{
product_name:"",
brand_name:"",
made_from:"",
price:0,
discount_off_percent :0,  
quantity_in_store:1,
percentage_reward:1,
credit_rewards:0,
category:'0',
sub_category:'0',
inner_category:'0',
innermost_category:'0',
product_status:'0',
on_tranding_sales:false,
on_special_offer:false,
special_offer_enddate:'',
color_variation:{},
size_variation:{},
product_size:"",
product_material:"",
product_main_image:"default-main-image.jpg",
product_image1:"",
product_image2:"",
product_image3:"",
product_image4:"",
product_image5:"",
product_image6:"",
product_specifications:[],
product_extra_images:[{image:'',description:''}],
product_feature:"",
viewers:0,
id:null,
indexedb_id:null,
date_posted:null,
date_updated:null,
date_purchased:null
})


const [SizeVariation,setSizeVariation] = useState({value:0,unit:'0'})



const OnchangeProductName = (event) => {


  setProductForm({...ProductForm,product_name:event.target.value});

  if(event.target.value.length > 2){

  return  setProductFormError({...ProductFormError,ProductNameError:false})
  }   
  if(event.target.value.length < 2){
    setProductFormError({...ProductFormError,ProductNameError:true})
  }
        
          
};

const OnchangeBrandName = (event) => {
  setProductForm({...ProductForm,brand_name:event.target.value});
  if(event.target.value.length > 2){

  return  setProductFormError({...ProductFormError,BrandNameError:false})
  }   
  if(event.target.value.length < 2){
    setProductFormError({...ProductFormError,BrandNameError:true})
  }
        
          
};

const OnchangeProductStatus = (event) => {
  console.log(event.target.value)
  setProductForm({...ProductForm,product_status:event.target.value});
  if(event.target.value !=="0"){

  return  setProductFormError({...ProductFormError,ProductStatusError:false})
  }   
  if(event.target.value ==="0"){
    setProductFormError({...ProductFormError,ProductStatusError:true})
  }
        
          
};


const OnchangeMadeFrom = (event) => {
  setProductForm({...ProductForm,made_from:event.target.value});
  if(event.target.value.length > 2){

  return  setProductFormError({...ProductFormError,MadeFromError:false})
  }   
  if(event.target.value.length < 2){
    setProductFormError({...ProductFormError,MadeFromError:true})
  }
        
          
};

const OnchangeProductSize = (event) => {
  setProductForm({...ProductForm,product_size:event.target.value});
  if(event.target.value.length > 2){
  
    setProductFormError({...ProductFormError,ProductSizeError:false})
  }   
  if(event.target.value.length < 2){
    setProductFormError({...ProductFormError,ProductSizeError:true})
  }
        
          
};
      


const ButtonsImagesUploads = (imageid) => {

  setCurrentClickedImage(imageid)

  const Button = $("#main-image-input")
  Button[0].click()
        
          
};

const ImagesReader = new FileReader()

const OnchangeImagesUpload = (event) => {
  const image = event.target.files[0]

  try {
  //const imageName =  image.name  
  const imageSize = image.size
  const imageType = image.type
  var imageBlob

  var Validator = ImageValidator(imageSize,imageType)
  if(Validator.validSize ===true && Validator.validType==true){//------------Process valid image
    ImagesReader.readAsDataURL(image)
    var ImageTimer = setInterval(function(){
      console.log("IMAGES_UPLOAD_CALLED")
      
      if(ImagesReader.result.length !==0){
        imageBlob = ImagesReader.result
        
        if(CurrentClickedImage==="product-main-image"){
          setProductForm({...ProductForm,product_main_image:imageBlob})

          setTimeout(function(){
          $('#main-image-input').val('')


          },20)
          
          
  

        }else if(CurrentClickedImage === 'product-image1'){

          setProductForm({...ProductForm,product_image1:imageBlob})
          setTimeout(function(){
            $('#image1-inputt').val('')
  
  
            },20)
          
        }
        else if(CurrentClickedImage==="product-image2"){

          setProductForm({...ProductForm,product_image2:imageBlob})

          setTimeout(function(){
            $('#image2-input').val('')
  
  
            },20)
        }
        else if(CurrentClickedImage==="product-image3"){

          setProductForm({...ProductForm,product_image3:imageBlob})

          setTimeout(function(){
            $('#image3-input').val('')
  
  
            },20)
          
        }
        else if(CurrentClickedImage==="product-image4"){

          setProductForm({...ProductForm,product_image4:imageBlob})

          setTimeout(function(){
            $('#image4-input').val('')
  
  
            },20)
        }
        else if(CurrentClickedImage==="product-image5"){

          setProductForm({...ProductForm,product_image5:imageBlob})

          setTimeout(function(){
            $('#image5-input').val('')
  
  
            },20)
        }
        else if(CurrentClickedImage==="product-image6"){

          setProductForm({...ProductForm,product_image6:imageBlob})

          setTimeout(function(){
            $('#image6-input').val('')
  
  
            },20)
        }
       
        clearInterval(ImageTimer)
      }




    },500)



  }else{

    alert("Image format has to be png,gif,jpeg and size less than 4Mb")

    //Notifications("Image format has to be png,gif,jpeg and size less than 4Mb","warning",true,true,7*1000)

  }
    
  }catch (error) {//---ERROR ARE BEING CATCHED
    console.log("SOMETHING_WENT_WRONG_WHILE_PROCESSING_IMAGE")
    
  }

  

        
          
};


var ImageCount = 0
const ImageCounterFunction =()=>{
  var OldValue = ImageCount
  if(ProductForm.product_main_image.length >100){
      
      OldValue+=1
  
  }
  if(ProductForm.product_image1.length >100){
    OldValue+=1
   
  
  }
  if(ProductForm.product_image2.length >100){
    OldValue+=1
   
  
  }
  if(ProductForm.product_image3.length >100){
    OldValue+=1
   
  
  }
  if(ProductForm.product_image4.length >100){
    OldValue+=1
   
  
  }
  if(ProductForm.product_image5.length >100){
    OldValue+=1
   
  
  }
  if(ProductForm.product_image6.length >100){
    OldValue+=1
   
  
  }
  
  return OldValue
  
}
const RemoveMainImage = (event) => {
  setProductForm({...ProductForm,product_main_image:"default-main-image.jpg"})
          
};
const OnchangeProductPrice= (event) => {
  setProductForm({...ProductForm,price:parseFloat(event.target.value)});
  if(event.target.value  > 0){
  
    setProductFormError({...ProductFormError,PriceError:false})
  }   
  if(event.target.value.length < 0){
    setProductFormError({...ProductFormError,PriceError:true})
  }
        
          
};

const OnchangeProductDiscountPrice= (event) => {

 
  if(parseFloat(event.target.value) <= 100){

    setProductForm({...ProductForm,discount_off_percent :parseFloat(event.target.value)});
  }
  
  

  if(event.target.value  > 0){
  
    setProductFormError({...ProductFormError,DiscountPriceError:false})
  }   
  if(event.target.value.length < 0){
    setProductFormError({...ProductFormError,DiscountPriceError:true})
  }
        
          
};

const OnchangeProductQuantity = (event) => {
  setProductForm({...ProductForm,quantity_in_store:parseFloat(event.target.value)});
  if(event.target.value  > 0){
  
    setProductFormError({...ProductFormError,ProductQuantityError:false})
  }   
  if(event.target.value.length < 0){
    setProductFormError({...ProductFormError,ProductQuantityError:true})
  }
        
          
};


const OnchangeProductCategory = (event) => {
  setProductForm({...ProductForm,category:event.target.value});
  if(event.target.value  !== "0"){
  
    setProductFormError({...ProductFormError,ProductCategoryError:false})
  }   
  if(event.target.value === "0"){
    setProductFormError({...ProductFormError,ProductCategoryError:true})
  }
        
          
};

const OnchangeProductSubCategory = (event) => {
  setProductForm({...ProductForm,sub_category:event.target.value});
  if(event.target.value  !== "0"){
  
    setProductFormError({...ProductFormError,ProductsSubCategoryError:false})
  }   
  if(event.target.value === "0"){
    setProductFormError({...ProductFormError,ProductsSubCategoryError:true})
}
        
          
};


// const OnchangeProductWeightUnit = (event) => {

//   setProductForm({...ProductForm,product_weight:{...ProductForm.product_weight,unit:event.target.value}});

//   if(event.target.value !=="0"){
  
//     setProductFormError({...ProductFormError,ProductWeightUnitError:false})
//   }   
//   if(event.target.value === "0"){
//     setProductFormError({...ProductFormError,ProductWeightUnitError:true})
//   }
        
          
// };


const OnchangeProductMaterial = (event) => {
  setProductForm({...ProductForm,product_material:event.target.value});
  if(event.target.value.length > 2){
  
    setProductFormError({...ProductFormError,ProductMaterialError:false})
  }   
  if(event.target.value.length < 2){
    setProductFormError({...ProductFormError,ProductMaterialError:true})
  }
        
          
};

// const OnchangeProductWarantyTimeValue = (event) => {

//   setProductForm({...ProductForm,product_waranty_time:{...ProductForm.product_waranty_time,value:event.target.value}});

//   if(event.target.value !=="0"){
  
//     setProductFormError({...ProductFormError,ProductWarantyTimeValueError:false})
//   }   
//   if(event.target.value === "0"){
//     setProductFormError({...ProductFormError,ProductWarantyTimeValueError:true})
//   }
        
          
// };

// const OnchangeProductWarantyTimeUnit = (event) => {

//   setProductForm({...ProductForm,product_waranty_time:{...ProductForm.product_waranty_time,unit:event.target.value}});

//   if(event.target.value !=="0"){
  
//     setProductFormError({...ProductFormError,ProductWarantyTimeUnitError:false})
//   }   
//   if(event.target.value === "0"){
//     setProductFormError({...ProductFormError,ProductWarantyTimeUnitError:true})
//   }
        
          
// };

const OnchangeProductSpecificationTitle = (event) => {
  setProductSpecificationTitle(event.target.value);
          
};

const OnchangeProductSpecificationValue = (event) => {
  setProductSpecificationValue(event.target.value);
   
          
};

const OnchangeProductFeature = (event) => {
  setProductForm({...ProductForm,product_feature:event.target.value});
  
          
};



const OnchangePercentageReward= (event) => {



    setProductForm({...ProductForm,percentage_reward:parseFloat(event.target.value)});

 
};

const OnchangeSizeVariationValue= (event) => {

  setSizeVariation({...SizeVariation,value:event.target.value});

  // if(event.target.value > 0){
  
  //   setProductFormError({...ProductFormError,ProductWeightValueError:false})
  // }   
  // if(event.target.value < 0){
  //   setProductFormError({...ProductFormError,ProductWeightValueError:true})
  // }
        
          
};

const OnchangeSizeVariationUnit= (event) => {

  setSizeVariation({...SizeVariation,unit:event.target.value});

  // if(event.target.value > 0){
  
  //   setProductFormError({...ProductFormError,ProductWeightValueError:false})
  // }   
  // if(event.target.value < 0){
  //   setProductFormError({...ProductFormError,ProductWeightValueError:true})
  // }
        
          
};
 



const OnchangeInnerCategory = (event) => {

  console.log(event.target.value)
  setProductForm({...ProductForm,inner_category:event.target.value});
  if(event.target.value  !== "0"){
  
    setProductFormError({...ProductFormError,InnerCategoryError:false})
  }   
  if(event.target.value === "0"){
    setProductFormError({...ProductFormError,InnerCategoryError:true})
}
        
          
};





const OnchangeInnerMostCategory = (event) => {
  
  console.log(event.target.value)
  setProductForm({...ProductForm,innermost_category:event.target.value});
  if(event.target.value  !== "0"){
  
    setProductFormError({...ProductFormError,InnerMostCategoryError:false})
  }   
  if(event.target.value === "0"){
    setProductFormError({...ProductFormError,InnerMostCategoryError:true})
}
        
          
};


const AddSizeVariation = ()=>{

const {unit,value} = SizeVariation

if(value>0 && unit !=='0'){

  ProductForm.size_variation[`${value}`] = unit
  setProductForm({...ProductForm})
  setProductFormError({...ProductFormError,SizeVariationError:false})//turn off error 
  
}


}



const AddProductSpecification = () =>{
  var Title = ProductSpecificationTitle
  var Value = ProductSpecificationValue
  var Added = {}
  if(Title.length > 1 && Value.length > 1){
    Added["title"] = Title
    Added["value"] = Value
    ProductForm.product_specifications.splice(ProductForm.product_specifications.lastIndexOf,0,Added)  
    setProductForm({...ProductForm})
  
  
  }
  
   
  }


  const RemoveproductProductSpecification = (index) =>{
    
    var Deleted =   ProductForm.product_specifications.splice(index,1)
    
    if(typeof(Deleted)=='object'){
    
      setProductForm({...ProductForm})
    }
  
   
  }


  
// const OnchangeProductColors = (event) => {
//   var ColorLength = ObjectCounter(ProductForm.product_colors)


//   if(event.target.checked===true){
//   if(ColorLength <=3){
//   setProductForm({...ProductForm,product_colors:{...ProductForm.product_colors,[event.target.name]:event.target.checked}});
//   setProductFormError({...ProductFormError,ProductColorsError:false})
//   }else{

//     Notifications("You can only have 4 ,different product colors","warning",true,true)
//   }  

  

//   }else if(event.target.checked===false){

//   delete ProductForm.product_colors[event.target.name] 
//   setProductForm({...ProductForm})
// }

// if(JSON.stringify(ProductForm.product_colors).length === 2){
// setProductFormError({...ProductFormError,ProductColorsError:true}) 
// }

// console.log(event.target.checked)
// console.log(event.target.value)

// };



        const Make = ''

const RemoveColorFunction =(key)=>{

  console.log(key)

  delete ProductForm.color_variation[key]
  setProductForm({...ProductForm,color_variation:ProductForm.color_variation})
}


const RemoveSizeVariation =(key)=>{
  delete ProductForm.size_variation[key]
  setProductForm({...ProductForm})
}



const ProductSpecificationLoop = ProductForm.product_specifications.map(function (object,index){
return(
<Fragment key={index}>
<Grid item xs={12}>   
 <Grid container spacing={0} direction='row' alignContent='flex-start'>

 <Grid item xs={2}><div className='custom-table-data'><span>{index+1} </span> <DeleteForever onClick={()=>(RemoveproductProductSpecification(index))} className="table-delete-icon"  /></div></Grid>
 
 <Grid item xs={4}><div className='custom-table-data'><span>{object.title} </span></div></Grid>
 <Grid item xs={6}><div className='custom-table-data'> <span>{object.value} </span> </div></Grid>
 
 </Grid>
 </Grid>
 </Fragment>
)})



const ColorVariationMap = Object.keys(ProductForm.color_variation).map(function(key,index){
 return(
    <React.Fragment key={index}>

     <Grid item xs={3}>
       <div className='color-variation-div' style={{backgroundColor:`${ProductForm.color_variation[key]}`}}><div onClick={()=>{RemoveColorFunction(key)}}><DeleteForever className='color-variation-delete'/></div>  </div>
       </Grid>

    </React.Fragment>
    )
  
  })




  const SizeVariationMap = Object.keys(ProductForm.size_variation).map(function(key,index){
    return(
       <React.Fragment key={index}>
   
        <Grid item xs={5} md={3}>
          <div className='color-variation-div'><div onClick={()=>{RemoveSizeVariation(key)}}><DeleteForever className='color-variation-delete'/><span>{key}</span> </div></div>
          </Grid>
   
       </React.Fragment>
       )
     
     })
       
  



const AddProductValidation = ()=>{

   console.log(ProductForm)

  if(ProductForm.special_offer_enddate.length===0 && ProductForm.on_special_offer===true){
    
    setProductFormError({...ProductFormError,SpecialDiscountEndDateError:true})

  }
  else if(ProductForm.product_name.length <= 2){
    setProductFormError({...ProductFormError,ProductNameError:true})
  
  
  }
  else if(ProductForm.brand_name.length<= 2){
    setProductFormError({...ProductFormError,BrandNameError:true})
  
  
  }
  else if(ProductForm.made_from.length<= 1){
    setProductFormError({...ProductFormError,MadeFromError:true})
  
  
  }
  else if(ProductForm.price===0 || ProductForm.price < 0 ){
    setProductFormError({...ProductFormError,PriceError:true})
   
  
  }
  else if(ProductForm.quantity_in_store===0 || ProductForm.price < 0 ){
    setProductFormError({...ProductFormError,ProductQuantityError:true})
   
  
  }
  else if(ProductForm.percentage_reward <= 0 ){
    setProductFormError({...ProductFormError,PercentageRewardError:true})
   
  
  }
  else if(ProductForm.category ==="0" ){
    setProductFormError({...ProductFormError,ProductCategoryError:true})
   
   
  }
  else if(ProductForm.sub_category ==="0" ){
    setProductFormError({...ProductFormError,ProductsSubCategoryError:true})
   
  
  }
  else if(ProductForm.inner_category==="0" && ProductForm.category==='fashion+apparel'){

    setProductFormError({...ProductFormError,InnerCategoryError:true})
   
  
  }
  else if(ProductForm.innermost_category ==="0" && ProductForm.category==='fashion+apparel'){

    setProductFormError({...ProductFormError,InnerMostCategoryError:true})
   
  
  }
  else if(ImageCounterFunction() < 4 || ProductForm.product_main_image.length < 100){
    console.log(ImageCounterFunction())
    console.log("MAIN_IMAGE_EMPTY"+ProductForm.product_main_image.length < 100)


    setProductFormError({...ProductFormError,ProductImagesError:true})
    //Notifications("You have to upload at least 4 images (main image is required)","warning",true,true)
    alert('You have to upload at least 4 images (main image is required)')
  
  
  }
  else if(Object.keys(ProductForm.color_variation).length <=0){

    setProductFormError({...ProductFormError,ColorVariationError:true})
   
  
  }
  else if(Object.keys(ProductForm.size_variation).length <=0){

    setProductFormError({...ProductFormError,SizeVariationError:true})
   
  
  }

 

  else if(ProductForm.product_status ==="0" ){
    setProductFormError({...ProductFormError,ProductTypeError:true})
   
  
  }
  else{ //--------------PROCCESS SUCCESS VALID FORM -->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    
   
  var UpdatedForm = ProductForm

  if(EdditProduct===true){ //PROCESS EDDITED FORM


   
    UpdatedForm.credit_rewards = (ProductForm.percentage_reward /100) *ProductForm.price
    setEdditProductLoading(true)

    HttpUpdatedProductFunction(UpdatedForm).then(function(status){
      if(status===200){
      // setProductForm(ResetForm)  //RESET FORM AFTER POST

       setEdditProductLoading(false)
      }
    })

   

  }else if(EdditProduct==false){
    UpdatedForm.credit_rewards = (ProductForm.percentage_reward /100) *ProductForm.price
    UpdatedForm.date_posted = new Date()

    console.log(UpdatedForm)

    setAddProductLoading(true)
    HttpPostProductFunction(UpdatedForm).then(function(status){
      if(status===200){
          //setProductForm(ResetForm) //RESET FORM AFTER POST
          setAddProductLoading(false)
      }
      

    })


  }

  

  
  
  }
}





useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------


 




if(ProductEdditSelected !==undefined && JSON.stringify(ProductEdditSelected).length > 10){

 
  setProductForm(ProductEdditSelected)
  
 

}






return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------







 

}
},[ProductEdditSelected])







const GetColorVariation = (colorlist)=>{

  setProductForm({...ProductForm,color_variation:colorlist});

  if(Object.keys(ProductForm.color_variation).length > 0){
    setProductFormError({...ProductFormError,ColorVariationError:false})
  }

  
   
 }

 const OnchangeSpecialOfferEndDate =(event)=>{

 

  setProductForm({ ...ProductForm, [event.target.name]: event.target.value});



    setProductFormError({...ProductFormError,SpecialDiscountEndDateError:false})



  

 }





const PreviewProductFunction =(object)=>{
  
  // TriggerProductPreviewModal(ProductForm)
}

const CategoryWithInnerCategory =['fashion+apparel']






const [open, setOpen] = React.useState(false);


const handleClose = () => {
  setOpen(false);
};



const OnchangeTrandingSales = (event) => {

  

  setProductForm({ ...ProductForm, on_tranding_sales: event.target.checked})
  
};

const OnchangeSpecialOffer = (event) => {

  

  if(event.target.checked===true){
    setProductForm({ ...ProductForm, on_special_offer: event.target.checked})
    setOpen(true)
  }else{
    setProductForm({ ...ProductForm, on_special_offer: event.target.checked,special_offer_enddate:''})
  
  }
  
};



return (   //--------RETURN HTML---------------------------
<Fragment>

<div>

<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
<DialogTitle id="form-dialog-title">special offer End date</DialogTitle>
<DialogContent>
<TextField
  autoFocus
  margin='normal'
  name="special_offer_enddate"
  label="offer end date"
  type="date"
  variant='outlined'
  fullWidth
  value={ProductForm.special_offer_enddate}
  onChange={OnchangeSpecialOfferEndDate}
/>
</DialogContent>
<DialogActions>
<Button onClick={handleClose} color="primary">
  Cancel
</Button>
<Button onClick={handleClose} color="primary">
  Save
</Button>
</DialogActions>
</Dialog>


</div>
        
   
     
        <Grid item xs={12} md={6}> {/*------------ FORMS SECTION ROW ------------------*/}
        <form noValidate autoComplete="off">

          <Card elevation={5}>
          <CardContent>
          
           <Typography variant='caption'com>All the fields marked with (<b className="required-field">*</b>) must be filled</Typography>
          
           <div className='tranding-sales-card'>
          


          <FormGroup row>
           <FormControlLabel  control={<Checkbox checked={ProductForm.on_tranding_sales} color='primary' onChange={OnchangeTrandingSales} name='on_tranding_sales' />}
            label="Mark as on tranding sales (hot sales)"
            
          />
          </FormGroup>
          </div>

            <br />  
            <div className='tranding-sales-card'>
            <FormGroup row>
           <FormControlLabel  control={<Checkbox color='primary' checked={ProductForm.on_special_offer} onChange={OnchangeSpecialOffer} name="on_special_offer" />}
            label="Mark as on special discount offer"
            color='secondary'
          />
          </FormGroup>
          {ProductFormError.SpecialDiscountEndDateError? <p>select a valid future date . </p>:null}
         
            </div>

            
            <br />
            
            <div className="form-field-container">
            <span className="form-required-field">*</span>
            <TextField id="product_name_field" 
            label="Product Name" 
            variant="outlined" 
            helperText={ProductFormError.ProductNameError?'This field must be filled':null}
            color={ProductFormError.ProductNameError?'secondary':'primary'}
            onChange={OnchangeProductName}
            value={ProductForm.product_name}
            style={{width:'95%'}}
            size='small'
            />
            {ProductForm.product_name.length > 1?<Done className="success-form-icon"/>:null}
            {ProductFormError.ProductNameError?<Cancel className="error-form-icon"/>:null}
            </div>
     

            <br />
            <div className="form-field-container">
            <span className="form-required-field">*</span>
            <TextField id="brand_name_field" 
            label="BrandName" 
            variant="outlined" 
            helperText={ProductFormError.BrandNameError?'This field must be filled':null}
            color={ProductFormError.BrandNameError?'secondary':'primary'}
            onChange={OnchangeBrandName}
            value={ProductForm.brand_name}
            style={{width:'95%'}}
            size='small'
            />
            {ProductForm.brand_name.length > 1?<Done className="success-form-icon"/>:null}
            {ProductFormError.BrandNameError?<Cancel className="error-form-icon"/>:null}
            </div>

            <br />
            <div className="form-field-container">
            <span className="form-required-field">*</span>
            <TextField id="madefrom_field" 
            label="Made From" 
            variant="outlined" 
            helperText="The Country where this product were made .eg Tanzania"
            color={ProductFormError.MadeFromError?'secondary':'primary'}
            onChange={OnchangeMadeFrom}
            value={ProductForm.made_from}
            style={{width:'95%'}}
            size='small'
            />
            {ProductForm.made_from.length > 1?<Done className="success-form-icon"/>:null}
            {ProductFormError.MadeFromError?<Cancel className="error-form-icon"/>:null}
            </div>
          
         
          

            <br />
            <div className="form-field-container">
            <span className="form-required-field">*</span>
            <TextField id="price_field" 
            label="Price" 
            type="number" 
            variant="outlined" 
            helperText=""
            color={ProductForm.price < 0?'secondary':'primary'}
            onChange={OnchangeProductPrice}
            value={ProductForm.price}
            style={{width:'95%'}}
            size='small'
            />
            {ProductForm.price> 0?<Done className="success-form-icon"/>:null}
            {ProductFormError.PriceError?<Cancel className="error-form-icon"/>:null}
            </div>

            <br />
            <div className="form-field-container">
            <span className="form-required-field">*</span>
            <TextField id="discount_off_percent _field" 
            label="discount_off_percent " 
            type="number" 
            variant="outlined" 
            helperText="Percent discount off from .eg 50% off"
            color={ProductForm.discount_off_percent  < 0?'secondary':'primary'}
            onChange={OnchangeProductDiscountPrice}
            value={ProductForm.discount_off_percent }
            style={{width:'95%'}}
            size='small'
            />
            {ProductForm.discount_off_percent  > 0?<Done className="success-form-icon"/>:null}
            {ProductFormError.DiscountPriceError?<Cancel className="error-form-icon"/>:null}
            </div>

            <br />
            <div className="form-field-container">
            <TextField id="quantity_in_store_field" 
            label="quantity_in_store" 
            type="number" 
            variant="outlined" 
            helperText="The quantity of the product you have in store"
            color={ProductForm.quantity_in_store < 0?'secondary':'primary'}
            onChange={OnchangeProductQuantity}
            value={ProductForm.quantity_in_store}
            style={{width:'95%'}}
            size='small'
            
            />
            {ProductForm.quantity_in_store > 0?<Done className="success-form-icon"/>:null}
            {ProductFormError.ProductQuantityError?<Cancel className="error-form-icon"/>:null}
            </div>

            <br />
            <br />

            <div className="form-field-container">
            <TextField id="percentage_reward_field" 
            label="percentage_reward" 
            type="number" 
            variant="outlined" 
            helperText="percent bonus prize for customers"
            color={ProductForm.percentage_reward < 0?'secondary':'primary'}
            onChange={OnchangePercentageReward}
            value={ProductForm.percentage_reward}
            style={{width:'95%'}}
            size='small'
            
            />
            {ProductForm.percentage_reward > 0?<Done className="success-form-icon"/>:null}
            {ProductFormError.PercentageRewardError?<Cancel className="error-form-icon"/>:null}
            </div>

            <br />
            <div className="form-field-container">
            <span className="form-required-field">*</span>
        <FormControl size='small' variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">Category</InputLabel>
        <Select
          native
          value={ProductForm.category}
          onChange={OnchangeProductCategory}
          label="Category"
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
            
          }}
         >
          <option  aria-label="None" value="" />
          <option  style={{fontSize: 15}} value="0" > Seletct category </option>
           <ProductCategoryComponent />
        </Select>
      </FormControl>
      {ProductForm.category !== "0"?<Done className="success-form-icon-category"/>:null}
      {ProductFormError.ProductCategoryError?<Cancel className="error-form-icon-category"/>:null}
      </div>

    
      
     
      {ProductForm.category==="0"?null:
      <Fragment> 
      <br />
      <div className="form-field-container">
      
       <span className="form-required-field">*</span>
      <FormControl size='small' variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="outlined-age-native-simple">SubCategory</InputLabel>
      <Select
        native
        value={ProductForm.sub_category}
        onChange={OnchangeProductSubCategory}
        label="SubCategory"
        inputProps={{
       name: 'age',
       id: 'outlined-age-native-simple',
       }}
       >

     <option style={{fontSize: 15}} value="0" > Seletct sub category </option>
     <ProductSubCategory category={ProductForm.category}/>
        </Select>
      </FormControl>
      {ProductForm.sub_category !== "0"?<Done className="success-form-icon-category"/>:null}
      {ProductFormError.ProductsSubCategoryError?<Cancel className="error-form-icon-category"/>:null}
       </div>
       </Fragment>
       }
     
      
      {CategoryWithInnerCategory.includes(`${ProductForm.category}`)?
      <Fragment>
       <br />
       <div className="form-field-container">
       <span className="form-required-field">*</span>
      <FormControl size='small' variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="outlined-age-native-simple">inner category</InputLabel>
      <Select
        native
        value={ProductForm.inner_category}
        onChange={OnchangeInnerCategory}
        label="inner category"
        >
         
     <option style={{fontSize: 15}} value="0" > select inner category </option>
     <InnerCategory sub_category={ProductForm.sub_category} />
        </Select>
      </FormControl>
      {ProductForm.inner_category !== "0"?<Done className="success-form-icon-category"/>:null}
      {ProductFormError.InnerCategoryError?<Cancel className="error-form-icon-category"/>:null}
     
       </div>
      </Fragment>
       :null}


      {CategoryWithInnerCategory.includes(`${ProductForm.category}`)?
      <Fragment>
       <br />
       <div className="form-field-container">
       <span className="form-required-field">*</span>
      <FormControl size='small' variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="outlined-age-native-simple">inner most category</InputLabel>
      <Select
        native
        value={ProductForm.innermost_category}
        onChange={OnchangeInnerMostCategory}
        label="innermost category"
        >
         
      <option  style={{fontSize: 15}} value="0" > select inner category </option>
     <InnerMostCategory inner_category={ProductForm.inner_category} />
        </Select>
      </FormControl>
      {ProductForm.innermost_category !=='0'?<Done className="success-form-icon-category"/>:null}
      {ProductFormError.InnerMostCategoryError?<Cancel className="error-form-icon-category"/>:null}
     
       </div>
      </Fragment>
      :null}
       
       <br />
  
      
       <div className="form-field-container">
       <span className="form-required-field">*</span>
      <FormControl size='small' variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="outlined-age-native-simple">product status</InputLabel>
      <Select
        native
        value={ProductForm.product_status}
        onChange={OnchangeProductStatus}
        label="product status"
        >
         
     <option style={{fontSize: 15}} value="0" > select inner category </option>
     <option style={{fontSize: 15}} value="new_item" > brand new </option>
     <option style={{fontSize: 15}} value="used_item" >used item </option>

        </Select>
      </FormControl>
      {ProductForm.product_status !== "0"?<Done className="success-form-icon-category"/>:null}
      {ProductFormError.InnerCategoryError?<Cancel className="error-form-icon-category"/>:null}
     
       </div>
  

           <br />
            <div className="form-field-container">
            {/* <span className="form-required-field">*</span> */}
            <TextField id="outlined-search" 
            label="Product/Size" 
            type="text" 
            variant="outlined" 
            helperText="Write the size of the product eg 12*12, XXL, 7 etc"
            color={ProductFormError.MadeFromError?'secondary':'primary'}
            onChange={OnchangeProductSize}
            value={ProductForm.product_size}
            style={{width:'95%'}}
            size='small'
            />
            {ProductForm.product_size.length > 1?<Done className="success-form-icon"/>:null}
            {/* {ProductFormError.ProductSizeError?<Cancel className="error-form-icon"/>:null} */}
            </div>


            <br />
            <br />
            <div className="form-field-container">
            {/* <span className="form-required-field">*</span> */}
            <TextField id="outlined-search" 
            label="Product Material" 
            type="text" 
            variant="outlined" 
            helperText="Materials that the product were made of. eg cotton, metal ,leather"
            color={ProductFormError.MadeFromError?'secondary':'primary'}
            onChange={OnchangeProductMaterial}
            value={ProductForm.product_material}
            style={{width:'95%'}}
            size='small'
            />
            {ProductForm.product_material.length > 1?<Done className="success-form-icon"/>:null}
            </div>





            <br />
            <div className="image-selection-button">
            <Accordion>
            <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header">

            <Typography variant='subtitle1'><span className="form-required-field-image-selection">*</span>Uploads Image of the product(Maximum 6 images) 

            {(ProductForm.product_main_image.length > 100) && ProductForm.product_image1.length > 100 && ProductForm.product_image2.length > 100 && ProductForm.product_image3.length > 100?<Done className="success-image-selection-button"/>:null}   
            {ProductFormError.ProductImagesError?<Cancel className="error-image-selection-button"/> :null}
           
            </Typography>

            </AccordionSummary>
             <AccordionDetails>
             

      <ButtonGroup
        orientation="vertical"
        color="primary"
        aria-label="vertical outlined primary button group"
        fullWidth>
        
        <Button onClick={()=>{ButtonsImagesUploads("product-main-image")}} id='main-product-image'><AddAPhotoOutlined /><span>Main Image (required)</span>{ProductForm.product_main_image.length> 50?<CheckCircle id="image-done-icon"/>:null}</Button>
        <Button onClick={()=>{ButtonsImagesUploads("product-image1")}} startIcon={<AddAPhotoOutlined />}> <b>Image View 1 (required)</b> {ProductForm.product_image1.length> 50?<CheckCircle id="image-done-icon"/>:null} </Button>
        <Button onClick={()=>{ButtonsImagesUploads("product-image2")}} startIcon={<AddAPhotoOutlined />}> <b>Image View 2 (required)</b>{ProductForm.product_image2.length> 50?<CheckCircle id="image-done-icon"/>:null} </Button>
        <Button onClick={()=>{ButtonsImagesUploads("product-image3")}} startIcon={<AddAPhotoOutlined />}><b>Image View 3 (required)</b>{ProductForm.product_image3.length> 50?<CheckCircle id="image-done-icon"/>:null}</Button>
        <Button onClick={()=>{ButtonsImagesUploads("product-image4")}} startIcon={<AddAPhotoOutlined />}><b>Image View 4 </b>{ProductForm.product_image4.length> 50?<CheckCircle id="image-done-icon"/>:null} </Button> 
        <Button onClick={()=>{ButtonsImagesUploads("product-image5")}} startIcon={<AddAPhotoOutlined />}><b>Image View 5</b>{ProductForm.product_image5.length> 50?<CheckCircle id="image-done-icon"/>:null} </Button> 
        <Button onClick={()=>{ButtonsImagesUploads("product-image6")}} startIcon={<AddAPhotoOutlined />}><b>Image View 6</b> {ProductForm.product_image6.length> 50?<CheckCircle id="image-done-icon"/>:null}</Button> 
     </ButtonGroup>
              
      <div style={{display:'none'}}>
      <input type="file"  id="main-image-input" onChange={OnchangeImagesUpload}/>
      <input type="file"  id="image1-input" onChange={OnchangeImagesUpload}/>
      <input type="file"  id="image2-input" onChange={OnchangeImagesUpload}/>
      <input type="file"  id="image3-input" onChange={OnchangeImagesUpload}/>
      <input type="file"  id="image4-input" onChange={OnchangeImagesUpload}/>
      <input type="file"  id="image5-input" onChange={OnchangeImagesUpload}/>
      <input type="file"  id="image6-input" onChange={OnchangeImagesUpload}/>
      </div>
           
             </AccordionDetails>
             </Accordion>
            </div>




            <br />
            <div className="image-selection-button">
            <Accordion>
            <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header">   
           
            <Typography variant='subtitle1' component='div'><span className="form-color-variation">*</span>
             
            <Grid container spacing={1} direction='row' alignContent='flex-start'>

              <Grid item xs={12}> <Typography variant='subtitle1'> Add color variation </Typography> <Divider /> </Grid>
              
              {ColorVariationMap}

              <Grid item xs={12}><Typography variant='caption'>these colors represent the variation color of your product </Typography> <Divider /> </Grid>
            </Grid>

            {Object.keys(ProductForm.color_variation).length >= 1?<Done className="success-image-selection-button"/>:null}

            {ProductFormError.ColorVariationError?<Cancel className="error-image-selection-button"/> :null}
           
            </Typography>

            </AccordionSummary>
             <AccordionDetails>

                 <ColorListComponent DefaultColorList={{red:'red'}} GetSelecteColorFunction={GetColorVariation}/>   
             </AccordionDetails>
             </Accordion>
            </div>


         
             <br />
             {/*------------ product sizes row -------------*/}
            <div className="image-selection-button">
             <Accordion>
            <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header">
            <Grid container spacing={1} direction='row' alignContent='flex-start'>

            <Grid item xs={12}><Typography variant='subtitle1'>Add product sizes</Typography> <Divider /> </Grid>
          
            
            {SizeVariationMap}
            <Grid item xs={12}><Typography variant='caption'>add different sizes available for your item</Typography> <Divider /> </Grid>


            </Grid>
             
          
            </AccordionSummary>

             <AccordionDetails>
             <Grid container spacing={1}> 

            <Grid item xs={12} md={5}>
            <div className="form-field-weight-container">
            <span className="form-required-field">*</span>
            <TextField id="outlined-search" 
            label="Weight/Size" 
            type="number" 
            variant="outlined" 
            helperText=""
            color={ProductFormError.ProductWeightValueError?'secondary':'primary'}
            onChange={OnchangeSizeVariationValue}
            value={SizeVariation.value}
            style={{width:'100%'}}
            size='small'
            />
            </div>
            </Grid>




            <Grid item xs={12} md={5}>
            <div className="form-field-unit-container">
            <span className="form-required-field">*</span>
            <FormControl size='small' variant="outlined" className={classes.formControl}>
             <InputLabel htmlFor="outlined-age-native-simple">Units</InputLabel>
             <Select
              native
              value={SizeVariation.unit}
              onChange={OnchangeSizeVariationUnit}
              label="SubCategory"
              >
               <option style={{fontSize: 15}} value="0" > Seletct unit(amount in unit) </option>
               <option style={{fontSize: 20}} value="foot,foots">foot,foots</option>
               <option style={{fontSize: 20}} value="inch,inches">inch,inches</option>
               <option style={{fontSize: 20}} value="kilogram,kilograms">kilogram,kilograms</option>
               <option style={{fontSize: 20}} value="liter,liters">liter,liters</option>
               <option style={{fontSize: 20}} value="meter,meters">meter,meters</option>
               <option style={{fontSize: 20}} value="millimeter,millimeters">millimeter,millimeters</option>
              <option style={{fontSize: 20}} value="centimeter,centimeters">centimeter,centimeters</option>
              <option style={{fontSize: 20}} value="container,containers">container,containers</option>
              <option style={{fontSize: 20}} value="bag,bags">bag,bags</option>
              <option style={{fontSize: 20}} value="box,boxes">box,boxes</option>
              <option style={{fontSize: 20}} value="carton,cartons">carton,cartons</option>
              <option style={{fontSize: 20}} value="case,cases">case,cases</option>
              
               <option style={{fontSize: 20}} value="dozen,dozones">dozen/dezones</option>
               <option style={{fontSize: 20}} value="pair,pairs">pair,pairs</option>
              <option style={{fontSize: 20}} value="tray,trays">tray,trays</option>
              <option style={{fontSize: 20}} value="pack,packs">pack,packs</option>
               <option style={{fontSize: 20}} value="sets">Sets</option>
               <option style={{fontSize: 20}} value="squarefoot">square,foot</option>
               <option style={{fontSize: 20}} value="squaremeter">square,meter</option>
              <option style={{fontSize: 20}} value="square,inch">Square,inch</option>
              <option style={{fontSize: 20}} value="gallon,gallons">gallon,gallons</option>
              <option style={{fontSize: 20}} value="grain">Grain</option>
              <option style={{fontSize: 20}} value="stone,stones">stone,stones</option>
              <option style={{fontSize: 20}} value="chain,chains">chain,chains</option>
              <option style={{fontSize: 20}} value="quarter,quarters">quarter,quarters</option>
               <option style={{fontSize: 20}} value="ream,reams">ream,reams</option>
              <option style={{fontSize: 20}} value="rod,rods">rod,rods</option>
               <option style={{fontSize: 20}} value="rolls">rolls</option>
              <option style={{fontSize: 20}} value="sheet,sheets">sheet,sheets</option>
              <option style={{fontSize: 20}} value="square,yard">square,yard</option>
              <option style={{fontSize: 20}} value="ton,tons">ton,tons</option>
              <option style={{fontSize: 20}} value="unit,units">unit,units</option>
               <option style={{fontSize: 20}} value="yard,yards">yard,yards</option>

          </Select>
          </FormControl>
            </div>
            </Grid>
         


               <Grid item xs={12} md={2}>
               <Button onClick={AddSizeVariation} variant='contained' style={{width:'100%'}} startIcon={<AddIcon />}>Add</Button> 
               </Grid>

              


             </Grid>
             </AccordionDetails>
             <Grid item xs={12}>
               
               {Object.keys(ProductForm.size_variation).length>0?<Done className="success-icon-sizevariation"/>:null}
               {ProductFormError.SizeVariationError?<Cancel className="error-image-selection-button"/> :null}
              
               </Grid>
        
             </Accordion>  
           
            </div>


            <br />




            <br />
            <div className="image-selection-button">
            <Accordion>
            <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header">

            <Typography variant='subtitle2'>
            Add products specifications(Optional)

             {ProductForm.product_specifications.length > 0?<Done className="success-image-selection-button"/>:null}   
            </Typography>
            </AccordionSummary>
             <AccordionDetails>
             <Typography>
              
             <Grid container spacing={2}> 

             <Grid item xs={12} md={5}>
             <div className="form-field-weight-container">
               <TextField id="outlined-search" 
                label="Title" 
                type="text" 
                variant="outlined" 
                helperText=""
                color={ProductFormError.ProductWeightValueError?'secondary':'primary'}
                onChange={OnchangeProductSpecificationTitle}
                value={ProductSpecificationTitle}
                style={{width:'100%'}}
                size='small'
                />
          </div>
          </Grid>


            <Grid item xs={12} md={5}>
            <div className="form-field-weight-container">
            <TextField id="outlined-search" 
              label="Specification"  
              type="text" 
              variant="outlined" 
              helperText=""
              color={ProductFormError.ProductWeightValueError?'secondary':'primary'}
              onChange={OnchangeProductSpecificationValue}
              value={ProductSpecificationValue}
              style={{width:'100%'}}
              size='small'
             />
          </div>
        </Grid>

        <Grid item xs={12} md={2}>
        <Fab variant="extended" onClick={AddProductSpecification}>
         <AddIcon className={classes.extendedIcon} />
           Add
         </Fab>
      </Grid>

        {/*------- table row container------------- */}
        <Grid item xs={12}> 


        <Grid container spacing={0} direction='row' alignContent='flex-start'>

        <Grid item xs={12}>   
        <Grid container spacing={0} direction='row' alignContent='flex-start'>

        <Grid item xs={2}><div className='custom-table-head'><span>#</span> </div></Grid>
        <Grid item xs={4}><div className='custom-table-head'><span>Title</span></div></Grid>
        <Grid item xs={6}><div className='custom-table-head'> <span>Description</span> </div></Grid>

        </Grid>
        </Grid>

        {/*--table row start here ------------- */}
               {ProductSpecificationLoop}
           
        {/*------- table row ---------*/}

    

        </Grid>

           
   
   
       </Grid>



     </Grid>


             </Typography>
             </AccordionDetails>
             </Accordion>
            </div>

            <br />



            <br />
            <div className="image-selection-button">
            <Accordion>
            <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header">

            <Typography>Describe more Features about your product(optional) 
            {ProductForm.product_feature.length > 5?<Done className="success-image-selection-button"/>:null}

            </Typography>
            </AccordionSummary>
             <AccordionDetails>
             <TextField
               id="outlined-multiline-static"
               label="Product Description"
                multiline
                rows={4}
                value={ProductForm.product_feature}
                variant="outlined"
                fullWidth={true}
                onChange={OnchangeProductFeature}
                size='small'
              />
               </AccordionDetails>
             </Accordion>
            </div>




      
      
      
        
            </CardContent>

            <CardActions>
              <ButtonGroup fullWidth={true} variant="contained" color="primary" className="product-submit-button">
              {EdditProduct===true?<Button endIcon={<Send />} onClick={AddProductValidation} disabled={EdditProductLoading===true?true:false}>Save  <div className="eddit-product-loading-spinner"><LoadingSpinner loading={EdditProductLoading}/>  </div> </Button>: <Button onClick={AddProductValidation}> Post <Send /> <div className="post-product-loading-spinner"><LoadingSpinner loading={AddProductLoading}/>  </div> </Button>} 
                      
             <Button onClick={PreviewProductFunction} endIcon={<LibraryAddCheck />}>Preview </Button>
            </ButtonGroup>

            </CardActions>
            
          </Card>
          
          </form>
        </Grid> {/*------------ END FORM FIELD SECTION ------------------*/}

        <Grid item xs={12} md={6}> {/*-----PRODUCT IMAGE DISPLAY ROW ------------------*/}
 

        <Card className={classes.root}>
          <CardContent className='product-main-image-container'>
           <img src={ProductForm.product_main_image} alt="" className={classes.image} />
            <div id="head-image-button-div">
               <b>Head Image</b> 
              {ProductForm.product_main_image.length >100?
              <div onClick={RemoveMainImage}>
               <DeleteForever className="delete-head-image-icon" fontSize="large" />
             </div>
          :null}

          </div>
        </CardContent>
 
        <CardActions className="product-image-collection">
        <Card style={{width:'100%'}}>
        <Grid container spacing={2}>

        {ProductForm.product_image1.length > 100?
        <Grid item xs={4} md={2}>
         <div onClick={()=>{setProductForm({...ProductForm,product_image1:""})}}> 
          <DeleteForever className="image-collection-delete-icon" fontSize="large" />
          </div>
          <img src={ProductForm.product_image1} alt=""/>
        </Grid>
        :null}

        {ProductForm.product_image2.length > 100?
        <Grid item xs={4} md={2}>
         <div onClick={()=>{setProductForm({...ProductForm,product_image2:""})}}> 
          <DeleteForever className="image-collection-delete-icon" fontSize="large" />
          </div>
          <img src={ProductForm.product_image2} alt=""/>
        </Grid>
        :null}

       {ProductForm.product_image3.length > 100?
        <Grid item xs={4} md={2}>
         <div onClick={()=>{setProductForm({...ProductForm,product_image3:""})}}> 
          <DeleteForever className="image-collection-delete-icon" fontSize="large" />
          </div>
          <img src={ProductForm.product_image3} alt=""/>
        </Grid>
        :null}

        {ProductForm.product_image4.length > 100?
        <Grid item xs={4} md={2}>
         <div onClick={()=>{setProductForm({...ProductForm,product_image4:""})}}> 
          <DeleteForever className="image-collection-delete-icon" fontSize="large" />
          </div>
          <img src={ProductForm.product_image4} alt=""/>
        </Grid>
        :null}

        {ProductForm.product_image5.length > 100?
        <Grid item xs={4} md={2}>
         <div onClick={()=>{setProductForm({...ProductForm,product_image5:""})}}> 
          <DeleteForever className="image-collection-delete-icon" fontSize="large" />
          </div>
          <img src={ProductForm.product_image5} alt=""/>
        </Grid>
        :null}

        {ProductForm.product_image6.length > 100?
        <Grid item xs={4} md={2}>
         <div onClick={()=>{setProductForm({...ProductForm,product_image6:""})}}> 
          <DeleteForever className="image-collection-delete-icon" fontSize="large" />
          </div>
          <img src={ProductForm.product_image6} alt=""/>
        </Grid>
        :null}
  
    

        </Grid>
        

       </Card>
      </CardActions>
    </Card>
         
         </Grid>

       
      

</Fragment>
 
    
);


// ProductFormComponent.propTypes = {}
// ProductFormComponent.defaultProps ={}


}
export default ProductFormComponent;

