import React ,{Fragment,useEffect}from 'react';
import PropTypes from 'prop-types';
import './UserInstanceCard.scss';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {AccountCircle,EventNote} from '@material-ui/icons';
// import {Link,useLocation,useHistory} from 'react-router-dom';
// import LinearProgress from '@material-ui/core/LinearProgress';


// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import {Link as UiLink}from '@material-ui/core';
// import HomeIcon from '@material-ui/icons/Home';
// import WhatshotIcon from '@material-ui/icons/Whatshot';
// import GrainIcon from '@material-ui/icons/Grain';







const UserInstanceCard = ({user_id,profile,current_grade_points,first_name,middle_name,last_name,total_community,themecolor1,NavigateProfilePreview}) => {

useEffect(()=>{
    
    






    
    
},[])    
    
return(
<Fragment>
        <div class="callout callout-success">
            <Grid container spacing={1}>
             <Grid item xs={12} sm={3} className='generation-wrapper'>
             <div onClick={()=>{NavigateProfilePreview(user_id)}} data-bs-toggle="modal" data-bs-target="#generation-preview-modal" className='generation-profile-card'><img src={profile} alt='profile 1 avatar'/><div className='from-generation-badge' style={{backgroundColor:`${themecolor1}`}}><span>{current_grade_points}</span></div> </div>
            </Grid>
  
            <Grid item xs={12} sm={3} className='generation-wrapper'>
              <span>{`${first_name} ${middle_name} ${last_name}`}</span>
            </Grid>
  
            <Grid item xs={12} sm={3} className='generation-wrapper'>
              <span>community<b style={{color:`${themecolor1}`}}> {total_community}</b></span>
            </Grid>
   
            <Grid item xs={12} sm={3} className='generation-wrapper'>

              <div className="dropdown">
               <Button variant='contained' className="btn btn-secondary dropdown-toggle" type="button" id={`dropdownMenuButton${user_id}`} data-bs-toggle="dropdown" aria-expanded="false">
                 action
               </Button>
               <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${user_id}`}>
                  
                  <li onClick={()=>{NavigateProfilePreview(user_id)}}><b className="dropdown-item"><AccountCircle />Profile preview</b></li>
                   <div className='user-card-divider'></div>
                  <li><b className="dropdown-item"><EventNote /> Eddit Profile</b></li>
               </ul>
              </div>


            </Grid>
           </Grid>
            </div>
</Fragment>
);

    
    
UserInstanceCard.propTypes = {
current_grade_points:PropTypes.number.isRequired,      
profile:PropTypes.string.isRequired,    
user_id:PropTypes.string.isRequired,    
first_name:PropTypes.string.isRequired,
middle_name:PropTypes.string.isRequired,
last_name:PropTypes.string.isRequired,
themecolor1:PropTypes.string.isRequired,
total_community:PropTypes.number.isRequired,
NavigateProfilePreview:PropTypes.func.isRequired,

}
UserInstanceCard.defaultProps ={ }
        
    
}
    
    




export{
UserInstanceCard
}


