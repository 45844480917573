import React ,{Fragment,useEffect,useContext}from 'react';
import './Dashboard.scss';
import Grid from '@material-ui/core/Grid';
//import { makeStyles } from '@material-ui/core/styles';
//import {Money,History,LocalAtm,AccountBalance} from '@material-ui/icons';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
//import transaction_withdraw from "./transaction_withdraw.svg";
import balance_icon from './balance_icon.svg';
import clc_points_icon from './clc_points_icon.svg';
import cpv_points_icon from "./cpv_points_icon.svg";
import network_icon from "./network_icon.svg";
//import {ArrowRightAlt} from '@material-ui/icons';
import {DashboardCard,DashboardCardCircle,DashboardCardLogo,DashboardCardText} from "../StyledComponents/styledcomponents";

//import {MoreVert} from '@material-ui/icons';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
// }));



    
const Dashboard = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User} = Appstate
  const {CurrencyFormat} = Functions
   const {themecolor1,themecolor2} = Appsettings

  //const classes = useStyles();


const [DashboardInfor, setDashboardInfor] = React.useState({
  title:'',
  description:'',
  icon:'',
});

const {balance,clc,community,cpv} = User





useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  



//0683300300 msangwa herbal kona ya nyamhongoro



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])




const ActivateModalInfor = (dashboard)=>{

  if(dashboard==='cpv'){
  setDashboardInfor({
      title:'MY CPV POINTS',
      description:'commision points value (cpv) these are the points earned per each products purchases , these points enable you uprade to upper grade when they reach 3 or more points',
      icon:cpv_points_icon
    })
 
  
  }else if(dashboard==='balance'){
    setDashboardInfor({
      title:'MY BALANCE',
      description:'Your account balance has a monetary value , you can withdraw and get paid by cash,your account balance can be recharged when you purchased a product',
      icon:balance_icon
    })
  
  }
  else if(dashboard==='clc'){
    setDashboardInfor({
      title:'MY CLC POINTS',
      description:<p>These are the points earned when you and your networks(downliner) purchased a products<br/>when your clc points reach 1000+ your qualified to apply for a loan.</p>,
      icon:clc_points_icon
    })
  
  }else if(dashboard==='network'){
    setDashboardInfor({
      title:'MY NETWORK',
      description:<p>Each individual who has joined Femsworldtz through your links or your membership ID are pressumed as your network<br/>You get a commission whenever you invite a new member to join.</p>,
      icon:network_icon
    })
  
  }
  
  
  
  }
  
  





// const WithdrawReportMap = [0,1,2,3,4,5].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// })  



// const PendingTransactionMap= [0].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// }) 



return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="transaction-wrapper">


    
<Grid container spacing={1}>
{/* 
<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={balance_icon} alt='account balance' className='dashboard-image'/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>ACCOUNT BALANCE</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{balance}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{ActivateModalInfor('balance')}} data-bs-toggle="modal" data-bs-target="#cpv-points-modal">Moreinfor</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={cpv_points_icon} alt='cpv account' className='dashboard-image'/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>CPV</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{cpv}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{ActivateModalInfor('cpv')}} data-bs-toggle="modal" data-bs-target="#cpv-points-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={clc_points_icon} alt='clc account' className='dashboard-image'/> </DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>CLC POINTS</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{clc}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{ActivateModalInfor('clc')}} data-bs-toggle="modal" data-bs-target="#cpv-points-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>



<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={network_icon} alt='my network' className='dashboard-image'/> </DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>MY COMMUNITY</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{community} community</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{ActivateModalInfor('network')}} data-bs-toggle="modal" data-bs-target="#cpv-points-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>
 */}







</Grid>


{/* ----------MODAL------------- */}
<div>
  <div className="modal fade" id="cpv-points-modal" data-bs-backdrop='static' data-bs-keyboard="false" tabIndex={-1} aria-labelledby="loan-application-label" aria-hidden="true">
    <div className="modal-dialog" style={{border:`4px solid ${themecolor1}`}}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="loan-application-label">{DashboardInfor.title}</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className='modal-icon-holder' style={{backgroundColor:`${themecolor1}`}}>
            <div className='modal-circle-icon'><img src={DashboardInfor.icon} alt='infor' />  </div>
          </div>
        
           {DashboardInfor.description}

         </div>
       
         <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>
 



</div>
</Fragment>
 
    
);


// Dashboard.propTypes = {}
        
// Dashboard.defaultProps ={}


}
export {Dashboard};

