import React ,{Fragment,useEffect,useContext}from 'react';
import './VoucherGenerate.scss';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import {ScaleLoader} from "react-spinners";
import {ServerFirstIndexedb_VoucherGenerate,ServerFirstIndexedb_FetchVoucherList} from "../../HttpcallUserApp";
import {NotificationActivate} from "../NotificationApplication/NotificationApplication";

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import {Print} from '@material-ui/icons';




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));


    
const VoucherGenerate = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User,App} = Appstate
  const {CurrencyFormat} = Functions
   const {themecolor1,themecolor2} = Appsettings

   const {server_ip,authentication_token} = App

   const [currentTab, setcurrentTab] = React.useState('new');

   const [CurrentIndex, setCurrentIndex] = React.useState(null);

   const [GenerateCodeLoading, setGenerateCodeLoading] = React.useState(false);

   const [ApproveLoading, setApproveLoading] = React.useState(null);

   const [RequestInstanceList, setRequestInstanceList] = React.useState({
    new:[
        //  {
        //   generation_id: 'A16398-FMFP',
        //   approved: 1,
        //   bank_account_fullname: "William jacksonn",
        //   bank_name: "AXIS",
        //   bank_number: "0495745749",
        //   current_grade_points: 7,
        //   final_withdraw_amount:50000,
        //   first_name: "William",
        //   gv_tax: 0,
        //   id: 1,
        //   issuer_role: "accountant",
        //   issuer_username: "femsadmin@gmail.com",
        //   last_name: "Nicholous",
        //   loan_deduction: 0,
        //   middle_name: "Jackson",
        //   pending: 0,
        //   profile:"profile_avatar.png",
        //   rank: "SILVER",
        //   rejected: 0,
        //   total_community: 110,
        //   transaction_date: "",
        //   transaction_fee: 0,
        //   user_id: "",
        //   withdraw_amount: 450000
        //  },
        //  {
        //   approved: 0,
        //   generation_id: 'A16398-FMFP',
        //   bank_account_fullname: "Jackson jacksonn",
        //   bank_name: "AXIS",
        //   bank_number: "04957457409",
        //   current_grade_points: 7,
        //   final_withdraw_amount:50000,
        //   first_name: "Happiness",
        //   gv_tax: 0,
        //   id:2,
        //   issuer_role: "accountant",
        //   issuer_username: "femsadmin@gmail.com",
        //   last_name: "Nicholous",
        //   loan_deduction: 0,
        //   middle_name: "Michale",
        //   pending: 1,
        //   profile:"profile_avatar.png",
        //   rank: "silver",
        //   rejected: 0,
        //   total_community: 110,
        //   transaction_date: "",
        //   transaction_fee: 0,
        //   user_id: "",
        //   withdraw_amount: 456666
        //  }
    ],
    confirmed:[],
    rejected:[]



   }
    );


  const [CurrentProfile, setCurrentProfile] = React.useState({
      approved: 0,
      bank_account_fullname: "William jacksonn",
      bank_name: "AXIS",
      bank_number: "0495745749",
      current_grade_points: 7,
      final_withdraw_amount:50000,
      first_name: "William",
      gv_tax: 0,
      id: 1,
      issuer_role: "accountant",
      issuer_first_name: "Erradius",
      issuer_middle_name: "Michael",
      issuer_last_name: "Patrick",
      issuer_username: "femsadmin@gmail.com",
      last_name: "Nicholous",
      loan_deduction: 0,
      middle_name: "Jackson",
      pending: 1,
      profile:"profile_avatar.png",
      rank: "SILVER",
      rejected: 0,
      total_community: 110,
      transaction_date: "",
      transaction_fee: 0,
      user_id: "",
      withdraw_amount: 0,
      generation_id:''
    });

  
  const [RadioState, setRadioState] = React.useState({approved:false,rejected:false});

  const [ServerResponse, setServerResponse] = React.useState(null);

  const [ServerMessage, setServerMessage] = React.useState(null);


  const classes = useStyles();
  const [value, setValue] = React.useState(0);

const [VoucherFormInfo, setVoucherFormInfo] = React.useState({voucher_price:170000,voucher_range:10});

const [PaginationStart, setPaginationStart] = React.useState(0);

const [PaginationEnd, setPaginationEnd] = React.useState(20);

const [PaginationInterval, setPaginationInterval] = React.useState(50);


const [VoucherList, setVoucherList] = React.useState({
  new_voucher:[
  // {
  // date_created: "2021-06-28T02:56:04.093",
  // date_used: null,
  // id: 312,
  // new_voucher: 1,
  // used_by: null,
  // user_id: 3,
  // voucher_price: 170000,
  // voucher_pin: "FMTAE9E7EF3740B",
  // }
]
  
,used_voucher:[
  //  {
  //   current_grade_points: 0,
  //   date_created: "2021-06-28T06:54:02.984",
  //   date_used: "2021-06-03T00:00:00",
  //   first_name: "Petterson",
  //   generation_id: "A43934-FMFP",
  //   id: 537,
  //   last_name: "james",
  //   middle_name: "pattrick",
  //   new_voucher: 0,
  //   profile: "profile_avatar.png",
  //   rank: "distributor",
  //   total_community: 0,
  //   used_by: "Japhtejackson7@gmail.com",
  //   user_id: 3,
  //   voucher_pin: "FMT6A3DF3349089",
  //   voucher_price: 170000
  // }


]});





useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  

  // NotificationActivate(`(application error) could not contact server!`,'information',true,false,10*1000)




return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])






const handleChange = (event, newValue) => {
  setValue(newValue);

  if(newValue===1){//FETCH_NEW_VOUCHER
    setcurrentTab('new_voucher')
    setServerResponse(null)

    if(VoucherList.new_voucher.length===0){

      ServerFirstIndexedb_FetchVoucherList(server_ip,PaginationStart,PaginationInterval,'new_voucher',authentication_token).then(function(JsonResponse){
        const {status} = JsonResponse
        setServerResponse(status)
        console.log(JsonResponse)
        if(status===200){
         const {Jsondata} = JsonResponse
  
          setVoucherList({...VoucherList,new_voucher:Jsondata})
        
        }else if(status > 200){
  
          NotificationActivate(`(server error: ${status}) could not fetch vouchers!`,'information',true,false,10*1000)
        }
  
    
      }).catch(function(ErrorResponse){
  
        setServerResponse(null)
  
        NotificationActivate(`(application error): internet connection error`,'information',true,false,10*1000)
  
           
      })
    }


 



  }
  else if(newValue===2){//CONFIRMED REQUEST
    setcurrentTab('used_voucher')
    setServerResponse(null)
    
    if(VoucherList.used_voucher.length===0){
      ServerFirstIndexedb_FetchVoucherList(server_ip,PaginationStart,PaginationInterval,'used_voucher',authentication_token).then(function(JsonResponse){
        const {status} = JsonResponse
        setServerResponse(status)
        if(status===200){
  
          const {Jsondata} = JsonResponse

          console.log(Jsondata)
  
          setVoucherList({...VoucherList,used_voucher:Jsondata})
  
  
  
        }else if(status > 200){
  
          NotificationActivate(`(server error: ${status}) could not fetch vouchers!`,'information',true,false,10*1000)
        }
  
    
      }).catch(function(ErrorResponse){
  
        setServerResponse(null)
  
        NotificationActivate(`(application error): internet connection error`,'information',true,false,10*1000)
  
           
      })
    }



  }


  
};





const TestFetch =()=>{

 


 }

 const ActivateCurrentProfile =(profile,index)=>{


  // setCurrentProfile(profile)
  // setCurrentIndex(index)

  // setApprovalError(null) 

  // setRadioState({approved:false,rejected:false})
 }







const OnchangeApproveFunction =(event)=>{
    const {checked} = event.target
    if(checked===true){

      setCurrentProfile({...CurrentProfile,approved:1,pending:0,rejected:0})

      setRadioState({...RadioState,approved:true,rejected:false})

      // setApprovalError(null)
    }
    
} 



const OnchangeRejectFunction =(event)=>{
 const {checked} = event.target

   if(checked===true){

      setCurrentProfile({...CurrentProfile,rejected:1,pending:0,approved:0})

      setRadioState({...RadioState,rejected:true,approved:false})
  }
  if(RequestInstanceList[currentTab][CurrentIndex].approved===1 && RadioState.rejected===true){
  
    //setApprovalError('YOU CANNOT UNDO THE APPROVED TRANSACTION')
  

   }
  
  


} 



const ConfirmActionFunction =()=>{





}


const UserRequestSkeletonMap = [0,1,2,3,4].map(function(object,index){
  return(
     <React.Fragment key={index}>
         <div className='generation-card'>
          <Grid container spacing={1}>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
              <div className='generation-skeleton-circle'>
              <Skeleton variant='circle' animation='pulse' style={{width:80,height:80}} /> 
              </div>
           </Grid>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
          </Grid>

          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"70%",height:30}} /> 
          </Grid>
 
          <Grid item xs={12} sm={3} className='generation-wrapper'>
          <Skeleton variant='text' animation="wave" style={{width:"10%",height:30}} /> 
          </Grid>
         </Grid>
        </div>
        <br />
     </React.Fragment>
     )
})  

 

const EmptyGenerationMessage = ({GenerationLevel})=>{
  return(
    <React.Fragment>
           <div className='empty-generation-message'>
            <h6>EMPTY {GenerationLevel} </h6>
          </div>
  </React.Fragment>
  )
}


const OnchangeVoucherPrice =(event)=>{

  const {value} = event.target
   
  setVoucherFormInfo({...VoucherFormInfo,voucher_price:value})

} 


const OnchangeVoucherRange =(event)=>{

  const {value} = event.target

 
  setVoucherFormInfo({...VoucherFormInfo,voucher_range:value})

} 




const GenerateVoucherFunction =()=>{
  setGenerateCodeLoading(true)
  setServerMessage(null)
  ServerFirstIndexedb_VoucherGenerate(server_ip,VoucherFormInfo,authentication_token).then(function(JsonResponse){
   setGenerateCodeLoading(false)
    const {status} = JsonResponse

    console.log(JsonResponse)

    if(status===200){
      
      const {Jsondata} = JsonResponse
      const {message} = Jsondata

      setServerMessage(message)

    



    }else if(status > 200){


      NotificationActivate(`(server_error: ${status}) could not generates voucher !`,'information',true,false,10*1000)
    }

  }).catch(function(ErrorResponse){

    setGenerateCodeLoading(false)




  })


}
 
 


 
const NewVoucherListMap = VoucherList['new_voucher'].map(function(object,index){
  return(
      <React.Fragment>
           <tr key={index}>
           <td>{index+1}</td>
            <td><div className='voucher-table-data'>{object.voucher_pin}</div></td>
            <td><div className='voucher-table-data'>{object.voucher_price}  TSH</div></td>
            <td>
            <div className='voucher-table-data'> 

                 {`${new Date(object.date_created).getDate()}/${new Date(object.date_created).getMonth()+1}/${new Date(object.date_created).getFullYear()}`}
            
             </div>
            </td>

            <td><div className='voucher-table-data'> 
            {object.new_voucher===1?
            <span  className="badge bg-success">new</span>  
            :<span className="badge bg-danger">used</span>}
            
            </div>
            </td>
          </tr>
       </React.Fragment>
      )
 })




 const  EmptyVoucherList =  [0].map(function(object,index){
  return(
      <React.Fragment>
           <tr key={index} >
           <td rowSpan={4}><h3>NO VOUCHER GENERATED YET .</h3> </td>
          </tr>
       </React.Fragment>
      )
 })
 
 

 
 const UsedVoucherListMap = VoucherList['used_voucher'].map(function(object,index){
  return(
      <React.Fragment>
           <tr key={index}>
           <td>{index+1}</td>
            <td><div className='voucher-table-data'>{object.voucher_pin}</div></td>
            <td><div className='voucher-table-data'>{object.voucher_price}  TSH</div></td>
            <td>
            <div className='voucher-table-data'> 

                 {`${new Date(object.date_created).getDate()}/${new Date(object.date_created).getMonth()+1}/${new Date(object.date_created).getFullYear()}`}
            
             </div>
            </td>

            <td>
            <div className='voucher-table-data'> 

                 {`${new Date(object.date_used).getDate()}/${new Date(object.date_used).getMonth()+1}/${new Date(object.date_used).getFullYear()}`}
            
             </div>
            </td>
            <td className='usedby-table-data'>
            <div className='voucher-table-data'> 

              <span>{`${object.first_name} ${object.middle_name} ${object.last_name}`}</span> 
                <br />
              <span>USER ID : {object.generation_id}</span>
            
             </div>
            </td>
            

            <td><div className='voucher-table-data'> 
            {object.new_voucher===1?
            <span  className="badge bg-success">new</span>  
            :<span className="badge bg-danger">used</span>}
            
            </div>
            </td>
          </tr>
       </React.Fragment>
      )
 })  



 const VoucherListSkeleton = [0,1,2,3,4,5].map(function(object,index){
  return(
      <React.Fragment>
           <tr key={index}>
           <td>{index+1}</td>
            <td><div className='voucher-table-data'><Skeleton variant='text' animation='pulse' style={{width:100,height:40}}  />  </div></td>
            <td><div className='voucher-table-data'><Skeleton variant='text' animation='pulse' style={{width:100,height:40}}  /> </div></td>
            <td>
            <div className='voucher-table-data'> 

            <Skeleton variant='text' animation='pulse' style={{width:100,height:40}} />  
             </div>
            </td>

            <td>
            <div className='voucher-table-data'> 
            <Skeleton variant='text' animation='pulse' style={{width:100,height:40}}  />
             </div>
            </td>
            <td className='usedby-table-data'>
            <div className='voucher-table-data'> 
            <Skeleton variant='text' animation='pulse' style={{width:100,height:40}}  />
            
             </div>
            </td>
            

            <td><div className='voucher-table-data'> 
            <Skeleton variant='text' animation='pulse' style={{width:100,height:40}}  />
            </div>
            </td>
          </tr>
       </React.Fragment>
      )
 })  






const FetchVoucherTest =()=>{ 

  ServerFirstIndexedb_FetchVoucherList(server_ip,0,50,'used_voucher',authentication_token).then(function(JsonResponse){
   
    console.log(JsonResponse)

  })
}




return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="payment-request-wrapper">
<div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="GENERATE VOUCHER" {...a11yProps(0)} />
          <Tab label="NEW VOUCHER" {...a11yProps(1)} />
          <Tab label="USED VOUCHER" {...a11yProps(2)} />
          {/* <Tab label="DEACTIVATED" {...a11yProps(3)} /> */}

          {/* <Tab label="Item Five" {...a11yProps(4)} />
          <Tab label="Item Six" {...a11yProps(5)} />
          <Tab label="Item Seven" {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
          <TextField
           label="VOUCHER PRICE"
           variant="filled"
           fullWidth={true}
           
           InputLabelProps={{
             shrink: true,
            }}
            size='small'
            type='number'
            value={VoucherFormInfo.voucher_price}
            onChange={OnchangeVoucherPrice}
          />
 
         <div className='voucher-generates-divider'></div>
         <FormControl style={{width:'100%'}}>
          <InputLabel htmlFor="age-native-simple">VOUCHER RANGE</InputLabel>
           <Select
            native
            value={VoucherFormInfo.voucher_range}
             inputProps={{
             name: 'age',
             id: 'age-native-simple',
            }}
             onChange={OnchangeVoucherRange}
            >

          <option value={10}>10 VOUCHERS</option>
          <option value={50}>50 VOUCHERS</option>
          <option value={100}>100 VOUCHERS</option>
          <option value={200}>200 VOUCHERS</option>
          <option value={300}>300 VOUCHERS</option>
          <option value={400}>400 VOUCHERS</option>
          <option value={500}>500 VOUCHERS</option>
          </Select>
         </FormControl>

         <div className='voucher-generates-divider'></div>

         <div className='update-profile-loader-wrapper'>
              <ScaleLoader speedMultiplier={2} loading={GenerateCodeLoading} height={50} width={10} color={themecolor1} />
          </div>

                {ServerMessage !==null?
               <div className="alert alert-success alert-dismissible">
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className="icon fas fa-check" /> SUCCESS</h5>
                 {ServerMessage}
              </div>
               :null}
                

         <ButtonGroup fullWidth={true} variant='outlined' size='large' aria-label="small outlined button group">
            <Button disabled={GenerateCodeLoading===true?true:false} onClick={GenerateVoucherFunction} type="button"  color='primary'>GENERATE VOUCHERS</Button>
          </ButtonGroup>


            {/* <Button  onClick={FetchVoucherTest} type="button"  color='primary'>FETCH VOUCHER</Button> */}
          


        
       
      </TabPanel>

      <TabPanel value={value} index={1}>

      <div className='voucher-table'>
      <div className="table-responsive p-0" style={{height: 500}}>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>VOUCHER CODE</th>
            <th>VOUCHER PRICE</th>
            <th>DATE CREATED</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>

           {NewVoucherListMap}

     
           {VoucherList.new_voucher.length===0 && ServerResponse===null?
            VoucherListSkeleton
           :null}

           {VoucherList.new_voucher.length===0 &&ServerResponse===200?
            <tr>
             <td colSpan={4}><h6>NO NEW VOUCHER GENERATED YET .</h6></td>
           </tr>
            
           :null}

       
        </tbody>
      </table>
       </div>
      
      </div>

   
    

        <div className='print-divider'></div>
       <ButtonGroup variant='outlined' size='large' aria-label="small outlined button group">
          <Button disabled={VoucherList.new_voucher.length===0?true:false} onClick={()=>{window.print()}}  startIcon={<Print style={{fontSize:'30px'}}/>} type="button"  color='primary'>PRINT VOUCHER</Button>
        </ButtonGroup>

      </TabPanel>

      <TabPanel value={value} index={2}>
      <div className='voucher-table'>
      <div className="table-responsive p-0" style={{height: 500}}>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>VOUCHER CODE</th>
            <th>VOUCHER PRICE</th>
            <th>DATE CREATED</th>
            <th>DATE USED</th>
            <th>USED BY</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>

           {UsedVoucherListMap}

           {VoucherList.used_voucher.length===0 && ServerResponse===null?
            VoucherListSkeleton
           :null}

          {VoucherList.used_voucher.length===0 &&ServerResponse===200?
            <tr>
             <td colSpan={4}><h6>NO USED VOUCHER GENERATED YET .</h6></td>
           </tr>
            
           :null}

       
        </tbody>
      </table>
       </div>
      
      </div>
              
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </div>
    {/*------------------------------ MODAL START--------------------------- */}

<div>
  {/* Button trigger modal */}
  {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
    Launch static backdrop modal
  </button> */}
  {/* Modal */}
  <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">PAYMENTS WITHDRAW REQUEST</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className='modal-payment-request' style={{backgroundColor:`${themecolor1}`}}>

               <div className='modal-request-profile-circle' style={{backgroundImage:`url(${CurrentProfile.profile})`}}></div>
               <Divider />
               <h6>Total community({CurrentProfile.total_community})</h6>
               <Divider />
               <h6>{CurrentProfile.first_name} {CurrentProfile.middle_name} {CurrentProfile.last_name} </h6>
               <Divider />
               <h6>{CurrentProfile.rank.toUpperCase()}</h6>

          </div>
            <ul className="list-group"> 
                <li className="list-group-item"><b>REQUESTED AMOUNT</b>: {CurrentProfile.withdraw_amount} </li>
                <li className="list-group-item"><b>GV.TAX</b>: {CurrentProfile.gv_tax}</li>
                <li className="list-group-item"><b>TRANSACTION FEE</b>:{CurrentProfile.transaction_fee} </li>
                <li className="list-group-item"><b>LOAN DEDUCTION</b>: {CurrentProfile.loan_deduction} </li>
                <li className="list-group-item"><b>BANK NAME</b>: {CurrentProfile.bank_name} </li>
                <li className="list-group-item"><b>BANK NUMBER</b>: {CurrentProfile.bank_number} </li>
                <li className="list-group-item"><b>BANK ACCOUNT NAME</b>: {CurrentProfile.bank_account_fullname} </li>
                <li className="list-group-item"><b>FINAL WITHDRAW</b>: {CurrentProfile.final_withdraw_amount} </li>

                <li className="list-group-item"><b>REQUEST STATUS</b>:

                     {CurrentProfile.approved===1?
                      <span className="badge bg-success">approved</span>
                      :null}
                       {CurrentProfile.rejected===1?
                      <span className="badge bg-danger">rejected</span>
                      :null}
                       {CurrentProfile.pending===1?
                      <span className="badge bg-primary">pending</span>
                      :null}
                
                 </li>

                 <li className="list-group-item"><b>ISSUER</b>: {CurrentProfile.issuer_role}  </li>
                 <li className="list-group-item"><b>ISSUER FULL NAME</b>: {CurrentProfile.issuer_first_name} {CurrentProfile.issuer_middle_name} {CurrentProfile.issuer_last_name}  </li>

    
    
            </ul>
               
               <ButtonGroup fullWidth={true} variant='outlined' size="small" aria-label="small outlined button group">
                 <Button   htmlFor="radioSuccess2" type="button"   color='primary'>APPROVE
                   
                    <div className="icheck-success d-inline">
                      <input onClick={OnchangeApproveFunction} checked={CurrentProfile.approved ===1?true:false}   type="radio" name="confirm-request" id="radioSuccess2" />
                      <label htmlFor="radioSuccess2">
                     </label>
                   </div>

                  </Button>

                 <Button htmlFor="radioDanger3" type="button"  color='primary'>REJECT
                    <div className="icheck-danger d-inline">
                      <input onClick={OnchangeRejectFunction}  checked={CurrentProfile.rejected===1?true:false}  type="radio" name="confirm-request"  id="radioDanger3" />
                      <label htmlFor="radioDanger3">
                     </label>
                      </div>

                  </Button>
               </ButtonGroup>

                <div className='transaction-modal-divider'></div>
                 
             
              
               <div className='update-profile-loader-wrapper'>
                <ScaleLoader speedMultiplier={2} loading={ApproveLoading} height={50} width={10} color={themecolor1} />
                </div>
                

                <div className="alert alert-success alert-dismissible">
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className="icon fas fa-check" /> SUCCESS</h5>
                 Success alert preview. This alert is dismissable.
              </div>
 


               <ButtonGroup fullWidth={true} variant='outlined' size="small" aria-label="small outlined button group">
                 <Button onClick={ConfirmActionFunction}  type="button"  color='primary'>CONFIRM ACTION</Button>
               </ButtonGroup>
            
            

          
        </div>
             <div className="modal-footer">
               <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>



</div>
</Fragment>
 
    
);


// VoucherGenerate.propTypes = {}
        
// VoucherGenerate.defaultProps ={}


}
export {VoucherGenerate};

